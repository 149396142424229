<div class="form-field" [formGroup]="formGroup">
  <label [for]="id">
    {{ label }}
  </label>

  <select [formControlName]="controlName" [id]="id">
    <option [ngValue]="null">-- Please Select --</option>
    <option *ngFor="let option of options"
            [value]="option.ID"
            [selected]="option.ID === formGroup.get(controlName)?.value"
    >
      {{ option.Name }}
    </option>
  </select>
</div>
