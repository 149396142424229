/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class ViewUserSurveyLicenses
 */
export interface ViewUserSurveyLicenses { 
    LicenseID?: number;
    LicenseCode?: string;
    SurveyID?: number;
    Total?: number;
    UserID?: number;
    PurchasePurpose?: string;
    TransactionType?: string;
    License?: any | null;
}

