/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Localization } from './localization';


/**
 * Class LookupDto
 */
export interface LookupDto { 
    /**
     * The Read-Only ID of the Lookup
     */
    ID: number;
    /**
     * The Name for this Lookup
     */
    Name: string;
    /**
     * The Localizations for this Lookup
     */
    Localizations: Set<Localization>;
}

