import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslatePipe } from "@ngx-translate/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BaseComponent } from "../../components/base/base.component";
import { AdminService, ComplimentaryLicenseSearch, JsonResponse, LookupService, UserDto, UserSearch, UserStatusDto, UserStatusModify } from "../../core/api";
import { UserStatusEnum } from "../../enums/user-status.enum";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-complimentary-licenses',
  templateUrl: './complimentary-licenses.component.html',
  styleUrls: ['./complimentary-licenses.component.scss']
})
export class ComplimentaryLicensesComponent extends BaseComponent implements OnInit {
  //#region variables

  private _dtoSearch!: ComplimentaryLicenseSearch
  public assessmentStatuses!: { ID: number, Name: string }[]
  public currentPage: number = 1
  public filterFormGroup!: FormGroup
  public totalItems: number = 0
  private usersPerPage: number = 20

  //#endregion

  //#region properties

  get complimentaryLicenseSearch(): ComplimentaryLicenseSearch {
    return this._dtoSearch;
  }

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private lookupService: LookupService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickArchiveUser(user: UserDto) {
    this.confirmationService.confirm({
      accept: () => {
        this.archiveUser(user)
      },
      header: this.translate.transform('ADMIN.USER_DETAIL.ARCHIVE_USER'),
      message: this.translate.transform('ADMIN.USER_DETAIL.ARCHIVE_USER_CONFIRM_MESSAGE', { email: user.Email })
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['complimentaryLicenses', 'assessmentStatuses'])

    this._dtoSearch = {
      perPage: this.usersPerPage,
      page: 1,
      totalItems: 0,
      items: undefined
    }

    this.initializeForm()
    this.loadUsers();
    this.loadAssessmentStatuses();
  }

  public onGoTo(page: number): void {
    this.submitSearchForm(page)
  }

  //#endregion

  //#region business logic

  private archiveUser(user: UserDto) {
    let userStatusModify: UserStatusModify = {
      ID: user.ID!,
      UserStatus: {
        ID: UserStatusEnum.ARCHIVED,
        Name: ''
      }
    }

    this.processManagerService.resetProcess('complimentaryLicenses')
    this.adminService.modifyUserStatus(userStatusModify).subscribe({
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('ADMIN.USER_DETAIL.USER_ARCHIVED', { email: user.Email }) })

        this.loadUsers()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private initializeForm() {
    this.filterFormGroup = this.formBuilder.group({
      AssessmentStatusID: [this._dtoSearch.AssessmentStatusID],
      SearchTerm: [this._dtoSearch.SearchTerm],
    })

    this.filterFormGroup.controls['AssessmentStatusID'].setValue(-1)
  }

  private loadUsers() {
    const criteria: ComplimentaryLicenseSearch = {
      AssessmentStatusID: this.filterFormGroup.controls['AssessmentStatusID'].value == -1 ? null : this.filterFormGroup.controls['AssessmentStatusID'].value,
      SearchTerm: this.filterFormGroup.controls['SearchTerm'].value,
      UserID: null,
      perPage: this.usersPerPage,
      page: this.currentPage,
    }

    this.adminService.searchComplimentaryLicenses(criteria).subscribe({
      complete: () => this.processManagerService.notify('complimentaryLicenses'),
      next: (response: JsonResponse) => {
        this._dtoSearch = response.data
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private loadAssessmentStatuses() {
    this.lookupService.listAssessmentStatuses().subscribe({
      complete: () => this.processManagerService.notify('assessmentStatuses'),
      next: (response: JsonResponse) => {
        this.assessmentStatuses = Object.keys(response.data).map(key => {
          /** @todo replace UserStatusDto with generic Lookup */
          let entity: UserStatusDto = response.data[key]
          return {ID: entity.ID ?? 0, Name: entity.Name};
        });
        this.assessmentStatuses.unshift({ID: -1, Name: 'All'})
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public submitSearchForm(page: number = 1) {
    this.currentPage = page

    this.processManagerService.resetProcess('complimentaryLicenses')

    this.loadUsers()
  }

  //#endregion
}
