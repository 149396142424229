export class EditorDisplayOptions {
  // constructor

  constructor(init?: Partial<EditorDisplayOptions>) {
    Object.assign(this, init)
  }

  // business logic

  public static returnConfiguration(): any {
    let removePlugins: string[] = []
    let standardToolbarOptions: string[] = [
      'heading',
      'fontFamily',
      'fontSize',
      '|',
      'undo',
      'redo',
      'removeFormat',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'subscript',
      'superscript',
      '|',
      'fontColor',
      'fontBackgroundColor',
      '-',
      'bulletedList',
      'numberedList',
      'alignment',
      'outdent',
      'indent',
      '|',
      'link',
      'insertTable',
      'blockQuote']
    let toolbarOptions: string[] = []
    let variableOptions: string[] = []

    if (toolbarOptions.length > 0) {
      standardToolbarOptions.push('|')
      for (var i: number = 0; i < toolbarOptions.length; i++) {
        standardToolbarOptions.push(toolbarOptions[i])
      }
    }

    let configuration: any = {
      removePlugins: removePlugins,
      toolbar: {
        items: standardToolbarOptions,
        shouldNotGroupWhenFull: true
      },
      variables: {
        options: variableOptions
      }
    }

    return configuration
  }
}
