/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Localization } from './localization';


/**
 * Class DimensionAnswerSimpleDto
 */
export interface DimensionAnswerSimple { 
    /**
     * The Read-Only ID
     */
    readonly ID?: number;
    /**
     * The sort order for this Dimension Answer
     */
    readonly Sequence?: number;
    /**
     * *_/
     */
    Localizations?: Array<Localization>;
}

