export * from './admin.service';
import { AdminService } from './admin.service';
export * from './distributor.service';
import { DistributorService } from './distributor.service';
export * from './guest.service';
import { GuestService } from './guest.service';
export * from './lookup.service';
import { LookupService } from './lookup.service';
export * from './participant.service';
import { ParticipantService } from './participant.service';
export * from './wordPress.service';
import { WordPressService } from './wordPress.service';
export const APIS = [AdminService, DistributorService, GuestService, LookupService, ParticipantService, WordPressService];
