import { Component, Input } from '@angular/core'
import { LanguagesService } from '../../../service/languages.service'
import { LocalizationInputBaseComponent } from '../localization-input-base/localization-input-base.component'

@Component({
  providers: [{ provide: LocalizationInputBaseComponent, useExisting: LocalizationTextareaComponent }],
  selector: 'app-localization-textarea',
  templateUrl: './localization-textarea.component.html'
})
export class LocalizationTextareaComponent extends LocalizationInputBaseComponent {
  // variables

  public maxLengthErrorMessage: string = ''

  // properties

  @Input() rows: number = 2!

  // constructors

  constructor(protected override languagesService: LanguagesService) {
    super(languagesService)
  }

  // event handlers

  override ngOnInit() {
    super.ngOnInit()
    if (this.maxLength) {
      this.maxLengthErrorMessage = 'The ' + this.displayName.toLowerCase() + ' cannot be greater than ' + this.maxLength + ' characters'
    }
  }
}
