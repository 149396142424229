import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit} from '@angular/core';
import {JsonResponse} from "../../core/api";
import {ProcessManagerService} from "../../service/process-manager.service";

@Component({
  selector: 'app-base',
  template: '',
})
export class BaseComponent implements OnInit {

  //#region properties
  public static exceptionToShow: string

  //#endregion

  //#region constructor
  constructor(protected processManagerService: ProcessManagerService) {
  }

  ngOnInit(): void {
  }

  //#endregion

  //#region error handling
  /**
   * The API *Should* always return a JsonResponse object every time
   * When this is handles by the AI code we get a JsonResponse object as the 'HttpErrorResponse.error' property
   * If something goes horiibly wrong, we will just get a plain vanilla HttpErrorResponse
   * @param error
   * @protected
   */
  protected handleApiException(error: HttpErrorResponse) {
    if (error.error) {
      let jsonResponse: JsonResponse = error.error
      this.handleException(jsonResponse.message)
      return
    }
    this.handleException(error.message)
    this.processManagerService.unforce()
  }

  protected handleException(message: string) {
    BaseComponent.exceptionToShow = message
    this.processManagerService.unforce()
  }

  //#endregion

}
