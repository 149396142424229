/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DistributionSearchAllOf } from './distributionSearchAllOf';
import { ViewDistributionListDto } from './viewDistributionListDto';
import { Search } from './search';


/**
 * Class DistributionSearchDto
 */
export interface DistributionSearch { 
    /**
     * Number of items per page requested/returned
     */
    perPage?: number;
    /**
     * Page number requested/returned
     */
    page?: number;
    /**
     * The total number of items in all pages
     */
    totalItems?: number;
    /**
     * The total number of pages
     */
    pageCount?: number;
    /**
     * The sort order for the results - used/implemented internally only
     */
    sort?: string;
    /**
     * ID of the Distributor (User) to filter by (used internally for the detail page)
     */
    DistributorID?: number | null;
    /**
     * $items *_/
     */
    items?: Set<ViewDistributionListDto>;
}

