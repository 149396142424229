import { DimensionSimpleDto, QuestionSimple } from "../core/api";
import { UserQuestionAnswer } from "./user-question-answer.model";

export class UserQuestion {
  public AssessmentID: number = null!
  public Dimension: DimensionSimpleDto = null!
  public Index: number = null!
  public Question: QuestionSimple = null!
  public Total: number = null!
  public UserQuestionAnswers: UserQuestionAnswer[] = []
}
