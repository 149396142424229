/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmailMessageSimple } from './emailMessageSimple';
import { EmailBlock } from './emailBlock';


/**
 * Class EmailEditDto
 */
export interface EmailEdit { 
    Message: EmailMessageSimple;
    /**
     * The Blocks in this email
     */
    Blocks: Set<EmailBlock>;
}

