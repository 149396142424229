import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../components/base/base.component";
import {ProcessManagerService} from "../../service/process-manager.service";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  // styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent extends BaseComponent implements OnInit {

  constructor(
    processManagerService: ProcessManagerService,
  ) {
    super(processManagerService)
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

}
