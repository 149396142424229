/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Identity } from './identity';


/**
 * Dto Request for resending invitations
 */
export interface DistributionResend { 
    /**
     * The ID of the Distribution
     */
    ID: number;
    /**
     * Assessments: List of Identity objects representing Assessment IDs to resend
     */
    Assessments: Array<Identity>;
    /**
     * Sent: List of Identity objects representing Assessment IDs that were sent
     */
    Sent?: Array<Identity>;
    /**
     * Any Errors encountered when attempting to resent invitations
     */
    errors?: string;
}

