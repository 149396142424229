/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserRoleDto } from './userRoleDto';
import { UserMinimalDto } from './userMinimalDto';
import { UserDto } from './userDto';
import { UserStatusDto } from './userStatusDto';
import { UserCreateDtoAllOf } from './userCreateDtoAllOf';


/**
 * Class UserCreateDto
 */
export interface UserCreateDto { 
    /**
     * The Read-Only ID of the User
     */
    ID?: number | null;
    /**
     * First Name
     */
    FirstName: string | null;
    /**
     * Last Name
     */
    LastName?: string | null;
    /**
     * Email
     */
    Email: string;
    UserStatus?: UserStatusDto;
    UserRole?: UserRoleDto;
    Language?: string;
    LoginDate?: string | null;
    CreatedBy?: UserMinimalDto | null;
    CreatedDate?: string | null;
    UpdatedBy?: UserMinimalDto | null;
    UpdatedDate?: string | null;
    Password?: string | null;
}

