<!-- Page Content -->
<div class="layout-content-inner gradient">
  <div class="wrapper">
    <form *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="submit()" class="login-form dark-theme transparent">
      <p class="form-heading" [innerHTML]="'GUEST.LOGIN.INSTRUCTIONS_LOGIN'|translate"></p>
      <div class="p-fluid">
        <div class="grid">
          <div class="form-field col-12">
            <label for="inputEmail" class="sr-only">{{'GUEST.LOGIN.EMAIL'|translate}}</label>
            <input type="text" pInputText id="inputEmail" formControlName="email" placeholder="{{'GUEST.LOGIN.EMAIL'|translate}}" required autofocus>
            <app-validation-error fieldName="email" [formGroup]="loginForm" validatorName="required">{{'GUEST.LOGIN.ERROR_EMAIL'|translate}}</app-validation-error>
            <app-validation-error fieldName="email" [formGroup]="loginForm" validatorName="email">{{'GUEST.LOGIN.ERROR_EMAIL_FORMAT'|translate}}</app-validation-error>
          </div>
        </div>
        <div class="grid">
          <div class="form-field col-12">
            <label for="inputPassword" class="sr-only">{{'GUEST.LOGIN.PASSWORD'|translate}}</label>
            <!--<input      formControlName="password" id="inputPassword" pInputText placeholder="{{'GUEST.LOGIN.PASSWORD'|translate}}" required type="password"/>-->
            <p-password [feedback]="false" [toggleMask]="true" formControlName="password" id="inputPassword" placeholder="{{'GUEST.LOGIN.PASSWORD'|translate}}" required type="password"></p-password>
            <a [href]="configuration.wpUrl + '/my-account/reset-password/'">{{'GUEST.LOGIN.FORGOT_PASSWORD'|translate}}</a>
            <app-validation-error fieldName="password" [formGroup]="loginForm" validatorName="required">{{'GUEST.LOGIN.ERROR_PASSWORD'|translate}}</app-validation-error>
          </div>
        </div>
        <div class="grid">
          <div class="form-field col-12">
            <p-button type="submit" label="{{'GUEST.LOGIN.SIGN_IN'|translate}}"></p-button>
          </div>
        </div>
      </div>
    </form>
    <a class="button-style" routerLink="/recover-assessment-link">{{'GUEST.LOGIN.RECOVER_ASSESSMENT_LINK'|translate}}</a>
  </div>
</div>
