import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnChanges {

  @Input() perPage: number = 5
  @Input() totalItems: number = 0
  @Input() currentPage: number = 1
  @Input() range: number = 3

  @Output() goTo: EventEmitter<number> = new EventEmitter<number>()

  public pages: number[] = []

  public onGoTo(page: number): void {
    this.goTo.emit(page)
  }

  ngOnChanges(changes: SimpleChanges): void {

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'currentPage':
          case 'totalItems':
            this.pages = this.getPages(this.currentPage, this.totalItems)
        }
      }
    }
  }

  private getPages(currentPage: number, totalItems: number): number[] {
    const pages: number[] = []
    for (
      let i = Math.max(1, currentPage - this.range);
      i <= Math.min(currentPage + this.range, Math.ceil(totalItems / this.perPage));
      ++i
    ) {
      pages.push(i)
    }
    return pages
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
