import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslatePipe } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { BaseComponent } from "../../components/base/base.component";
import { GuestService, JsonResponse, ParticipantDto } from "../../core/api";
import { AuthService } from "../../service/auth.service";
import { FormValidationService } from "../../service/form-validation.service";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-recover-assessment-link',
  templateUrl: './recover-assessment-link.component.html',
  //styleUrls: ['./recover-assessment-link.component.scss']
})
export class RecoverAssessmentLinkComponent extends BaseComponent implements OnInit {
  //#region variables

  public recoverAssessmentLinkForm!: FormGroup;

  //#endregion

  //#region constructor

  constructor(
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private guestService: GuestService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['recoverAssessmentLink'])
    this.processManagerService.unforce()

    this.recoverAssessmentLinkForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    })

    this.route.queryParams.subscribe({
      next: (query: Params) => this.recoverAssessmentLinkForm.get('email')?.setValue(query['email'])
    })
  }

  public submit() {
    this.submitRecoverAssessmentLink()
  }

  //#endregion

  //#region business logic

  private submitRecoverAssessmentLink() {
    if (this.recoverAssessmentLinkForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.recoverAssessmentLinkForm)
      return
    }

    const dto: ParticipantDto = {
      Email: this.recoverAssessmentLinkForm.get('email')?.value
    }

    this.processManagerService.force()
    this.guestService.recoverAssessmentLink(dto).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        if (200 === response.code) {
          this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('GUEST.LOGIN.RECOVER_ASSESSMENT_LINK_SUCCESS') })
        }
        else {
          this.handleException(this.translate.transform('GUEST.LOGIN.RECOVER_ASSESSMENT_LINK_FAIL'))
        }
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  //#endregion

}
