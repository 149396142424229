<form class="light-theme questionnaire-form" [formGroup]="answerForm" (ngSubmit)="submit()">
  <ng-container *ngIf="!displaySubmitConfirmation">
    <p class="description">
      <span [innerHTML]="'PARTICIPANT.WELCOME.ASSESSMENT_INSTRUCTIONS'|translate"></span>
    </p>
    <div class="step" *ngIf="questionIndex && questionTotal">
      {{'PARTICIPANT.ASSESSMENT_QUESTION.PROGRESS'|translate:{index: questionIndex, total: questionTotal} }}
    </div>
    <fieldset>
      <div class="p-fluid">
        <div class="grid">
          <div class="col-12">
            <ng-container *ngIf="showDimensionAndQuestionText">
              <div class="dimension-text" *ngIf="userQuestion.Dimension">
                <span [innerHTML]="userQuestion.Dimension|appTranslate:'Text'"></span>
              </div>
              <div class="question-text">
                <span [innerHTML]="userQuestion.Question|appTranslate:'Text'"></span>
              </div>
            </ng-container>
            <div class="answers" *ngFor="let userQuestionAnswer of userQuestion.UserQuestionAnswers">
              <p-radioButton formControlName="questionAnswerID" [value]="userQuestionAnswer.ID" [label]="userQuestionAnswer|appTranslate:'Text'"></p-radioButton>
            </div>
            <app-validation-error fieldName="questionAnswerID" [formGroup]="answerForm" validatorName="required">{{'PARTICIPANT.ASSESSMENT_QUESTION.ANSWER_REQUIRED'|translate}}</app-validation-error>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="nav-buttons">
      <!--Previous-->
      <button pButton type="button" class="left secondary" [label]="'PARTICIPANT.ASSESSMENT_QUESTION.BUTTON_PREVIOUS'|translate" (click)="clickPrevious()" *ngIf="displayPrevious"></button>
      <!--Next (Submit) -->
      <button pButton type="button" class="left" [label]="'PARTICIPANT.ASSESSMENT_QUESTION.BUTTON_NEXT'|translate" (click)="clickNext()" *ngIf="displayNext"></button>
      <!--Save-Return-->
      <button pButton type="button" class="right secondary" [label]="'PARTICIPANT.ASSESSMENT_QUESTION.BUTTON_SAVE_RETURN'|translate" (click)="clickSaveReturn()" *ngIf="displaySaveAndReturn"></button>
      <!--Complete (requestConfirmation)-->
      <button pButton type="button" class="right secondary" [label]="'PARTICIPANT.ASSESSMENT_QUESTION.BUTTON_SUBMIT'|translate" (click)="requestConfirmation()" *ngIf="displayComplete"></button>
    </div>

  </ng-container>

  <!--SubmitConfirmation-->
  <div *ngIf="displaySubmitConfirmation" class="flex">
    <div class="p-fluid">
      <div class="grid">
        <div class="col-12">
          <p>{{'PARTICIPANT.ASSESSMENT_QUESTION.ASSESSMENT_SUBMIT_CONFIRM_MESSAGE'|translate}}</p>
        </div>
        <div class="col-12 md:col-4">
          <!--Complete (Finish) -->
          <button pButton type="button" class="right secondary" [label]="'PARTICIPANT.ASSESSMENT_QUESTION.BUTTON_SUBMIT_CANCEL'|translate" (click)="revokeConfirmation()"></button>
        </div>
        <div class="col-12 md:col-4">
          <!--Complete (Finish) -->
          <button pButton class="right secondary" [label]="'PARTICIPANT.ASSESSMENT_QUESTION.BUTTON_SUBMIT_CONFIRM'|translate"></button>
        </div>
      </div>
    </div>
  </div>

</form>
