<div>
  <h1>
    {{'ADMIN.DIMENSION_DETAIL.HEADING'|translate}}
  </h1>
  <p [innerHTML]="'ADMIN.DIMENSION_DETAIL.INSTRUCTIONS'|translate"></p>
  <section class="dark-theme">
    <app-localization-toggle (onToggled)="onLanguageToggled($event)"></app-localization-toggle>
    <form class="dimension-details-form" *ngIf="dimensionForm" (submit)="submitDimension()" [formGroup]="dimensionForm" autocomplete="off" novalidate>
      <fieldset>
        <div class="p-fluid">
          <div class="grid">
            <div class="col-6">
              <div class="form-field">
                <app-localization-input-text controlName="name"
                                             displayName="{{'ADMIN.DIMENSION_DETAIL.DIMENSION_NAME'|translate}}"
                                             [formGroup]="dimensionForm"
                                             [maxLength]="maxLengthDimensionName"
                                             [required]="true">
                </app-localization-input-text>
              </div>
              <div class="form-field">
                <app-localization-textarea controlName="text"
                                           displayName="{{'ADMIN.DIMENSION_DETAIL.DIMENSION_TEXT'|translate}}"
                                           [formGroup]="dimensionForm"
                                           [maxLength]="maxLengthDimensionText"
                                           [required]="true"
                                           [rows]="4">
                </app-localization-textarea>
              </div>
            </div>
            <!--
            The Answers Show Conditionally
            For the 100 Question survey where the 5 answers belong to a dimension they can be edited here
            For the 40 Question survey where the 20 answers belong to a dimension they can be too unweildy here and are better being edited in the questions interface
            -->
            <div *ngIf="!isFortyQuestionSurvey" class="col-6" formArrayName="dimensionAnswers">
              <div class="form-field" *ngFor="let formGroup of formArrayDimensionAnswers.controls; let $index = index" [formGroupName]="$index">
                <app-localization-input-text controlName="text"
                                             displayName="{{'ADMIN.DIMENSION_DETAIL.ANSWER_TEXT'|translate}}"
                                             [formGroup]="returnFormGroup(formGroup)"
                                             [maxLength]="maxLengthAnswerText"
                                             [required]="true">
                </app-localization-input-text>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="buttons">
        <p-button type="submit" label="{{'COMMON.UI.SAVE'|translate}}"></p-button>
        <p-button type="button" label="{{'COMMON.UI.CANCEL'|translate}}" (click)="clickCancel()"></p-button>
      </div>
    </form>
  </section>
</div>
