<!--<pre *ngIf="filteredParticipantsFormArray">-->
<!--  allHaveEmails: {{canBeSent}}-->
<!--</pre>-->
<!--<pre>-->
<!--  distributionStatus: {{distributionStatus}}-->
<!--  distribution.DistributionStatus: {{distribution?.DistributionStatus?.Name}}-->
<!--   isDistributionPending: {{isDistributionPending}}-->
<!--  isDistributionIncomplete: {{isDistributionIncomplete}}-->
<!--  isDistributionComplete: {{isDistributionComplete}}-->
<!--</pre>-->
<div *ngIf="distributionForm" class="accordion-tabs">
  <div class="accordion-tab">
    <input autocomplete="off" [checked]="false" type="radio" id="step-modify-distribution" name="edit-distribution-step">
    <label (click)="onAccordionClick()" class="accordion-tab-label" for="step-modify-distribution">
      {{'DISTRIBUTOR.DISTRIBUTION_FORM.EDIT_DISTRIBUTION' |translate }}
      -
      {{'DISTRIBUTOR.DISTRIBUTION_FORM.TOTAL_LICENSES_AVAILABLE'|translate}}: {{licensesAvailable}}
    </label>
    <section class="accordion-tab-content distribution-form">

      <form class="dark-theme" *ngIf="distributionForm" (submit)="submitDistributionForm()" [formGroup]="distributionForm" autocomplete="off" novalidate>
        <fieldset>
          <div class="p-fluid">
            <div class="grid">

              <div class="col-12 md:col form-field">
                <label for="Name">{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_NAME'|translate}}</label>
                <input pInputText maxlength="250" type="text" formControlName="Name" id="Name"/>
              </div>

              <div class="col-12 md:col-3 form-field">
                <label for="ParticipantCount">{{'DISTRIBUTOR.DISTRIBUTION_FORM.PARTICIPANT_COUNT'|translate}}</label>
                <input pInputText type="number" [min]="minParticipantsEntry" [max]="maxParticipantsEntry" formControlName="ParticipantCount" id="ParticipantCount"/>
              </div>

              <div class="col-12 md:col-9 form-field has-app-notice custom-checkbox">
                <p-checkbox [binary]="true" formControlName="CanViewResults" inputId="CanViewResults" label="{{'DISTRIBUTOR.DISTRIBUTION_FORM.CAN_VIEW_RESULTS'|translate}}"></p-checkbox>
              </div>

              <div class="col-12 md:col-3 form-field">
                <p-button type="submit" label="{{'DISTRIBUTOR.DISTRIBUTION_DETAIL.SAVE_DISTRIBUTION'|translate}}"></p-button>
              </div>

            </div>

            <div *ngIf="distribution" class="grid">

              <div class="col-12">
                <ul class="info-boxes">
                  <li>
                    <span class="heading">{{'DISTRIBUTOR.DISTRIBUTION_FORM.DATE_CREATED'|translate}}</span>
                    {{distribution.CreatedDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a'}}
                  </li>
                  <li>
                    <span class="heading">{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_SENT'|translate}}</span>
                    {{distribution.SentDate ? (distribution.SentDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a') : 'COMMON.UI.NO'|translate}}
                  </li>
                  <li>
                    <span class="heading">{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_STATUS'|translate}}</span>
                    {{distribution.DistributionStatus?.Name}}
                  </li>
                </ul>
              </div>

            </div>

          </div>
        </fieldset>
      </form>
    </section>
  </div>

  <div class="accordion-tab">
<!--    <input autocomplete="off" [checked]="!isDistributionPending" type="radio" id="step-modify-participants" name="edit-distribution-step">-->
    <input autocomplete="off" [checked]="isDistributionIncomplete" type="radio" id="step-modify-participants" name="edit-distribution-step">
    <label (click)="onAccordionClick()" class="accordion-tab-label" for="step-modify-participants">{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.EDIT_PARTICIPANTS'|translate}}</label>
    <section class="accordion-tab-content distribution-participant-list" *ngIf="distributionParticipantsForm && filterFormGroup">
      <div class="dark-theme">
        <fieldset>

          <form *ngIf="filterFormGroup" [formGroup]="filterFormGroup" autocomplete="off" novalidate>
            <fieldset>
              <div class="col-12 md:col-6 form-field" [formGroup]="filterFormGroup">
                <input placeholder="{{'COMMON.UI.SEARCH'|translate}}" type="text" pInputText formControlName="SearchTerm" id="SearchTerm"/>
              </div>
            </fieldset>
          </form>

          <form class="dark-theme" *ngIf="distributionParticipantsForm" (submit)="submitDistributionParticipantsForm()" [formGroup]="distributionParticipantsForm" autocomplete="off" novalidate>
            <div *ngIf="filteredParticipantsFormArray">
              <table class="tbl-distribution-participant-list" formArrayName="faParticipants">
                <thead>
                <tr>
                  <th>#</th>
                  <th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.FIRST_NAME'|translate}}</th>
                  <th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.LAST_NAME'|translate}}</th>
                  <th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.EMAIL'|translate}}</th>
<!--                  <th *ngIf="!isDistributionComplete"></th>-->
                  <th>&nbsp;</th>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let formGroup of filteredParticipantsFormArray.controls; let $index = index" [formGroupName]="$index">
                  <td [attr.data-heading-1]="'#'">
                    {{$index + 1}}
                  </td>

                  <td [attr.data-heading-2]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.FIRST_NAME'|translate">
                    <div class="form-field">
                      <input placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.FIRST_NAME'|translate}}" type="text" pInputText formControlName="FirstName"/>
                    </div>
                  </td>

                  <td [attr.data-heading-3]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.LAST_NAME'|translate">
                    <div class="form-field">
                      <input placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.LAST_NAME'|translate}}" type="text" pInputText formControlName="LastName"/>
                    </div>
                  </td>

                  <td [attr.data-heading-4]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.EMAIL'|translate">
                    <div class="form-field">
                      <input placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.EMAIL'|translate}}" type="email" pInputText formControlName="Email"/>
                      <app-validation-error fieldName="Email" [formGroup]="returnFormGroup(formGroup)" validatorName="email">{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.ERROR_EMAIL_FORMAT'|translate}}</app-validation-error>
                    </div>
                  </td>

<!--                  <td *ngIf="!isDistributionComplete">-->
                  <td>
                    <p-button
                      (click)="removeParticipant($index)"
                      type="button"
                      label="{{'COMMON.UI.REMOVE'|translate}}"
                      [disabled]="removeParticipantDisabled(formGroup)"
                      class="btn-txt btn-remove p-button-danger"></p-button>
                  </td>

                </tr>
                </tbody>

              </table>
            </div>

            <p-button
              type="submit"
              [disabled]="!distributionParticipantsForm.valid"
              label="{{'DISTRIBUTOR.DISTRIBUTION_DETAIL.SAVE_DISTRIBUTION_PARTICIPANTS'|translate}}">

            </p-button>
          </form>

        </fieldset>
      </div>
    </section>
  </div>

  <div *ngIf="canBeSent" class="accordion-tab">
    <input autocomplete="off" [checked]="isDistributionPending" type="radio" id="step-send-distribution" name="edit-distribution-step">
    <label (click)="onAccordionClick()" class="accordion-tab-label" for="step-send-distribution">
      {{'DISTRIBUTOR.DISTRIBUTION_DETAIL.SEND_ASSESSMENTS'|translate}}
    </label>
    <section class="accordion-tab-content distribution-form" id="section-send-assessments">
      <div class="dark-theme">
        <fieldset>

          <!-- @todo use enums -->
          <div class="pnl-send-assessments">

            <div class="grid form-field">
              <div class="col-12">
                <p>{{'DISTRIBUTOR.DISTRIBUTION_DETAIL.REVIEW_BEFORE_SEND_PROMPT'|translate}}</p>

                <div class="buttons">

                  <p-button (click)="toggleBeforeSendAssessments(false)"
                            type="button"
                            [styleClass]="(showReview?'btn-review-inactive':'btn-review-active')"
                            label="{{'COMMON.UI.YES'|translate}}"
                            routerLink="/distributor/content/invitation" [queryParams]="{distribution:distribution.ID}"
                            routerLinkActive="activebutton">
                  </p-button>
                  <p-button (click)="toggleBeforeSendAssessments(true)"
                            type="button"
                            [styleClass]="(showReview?'btn-review-active':'')"
                            label="{{'COMMON.UI.NO'|translate}}">
                  </p-button>

                </div>
              </div>
              <div class="col-12" *ngIf="showReview">
                <span [innerHTML]="'DISTRIBUTOR.DISTRIBUTION_DETAIL.REVIEW_BEFORE_SEND_MESSAGE'|translate"></span>
                <ul class="distribution-review">
                  <li>
                    <span class="heading">{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_NAME'|translate}}</span>
                    {{distribution.Name}}
                  </li>
                  <li>
                    <span class="heading">{{'DISTRIBUTOR.DISTRIBUTION_FORM.DATE_CREATED'|translate}}</span>
                    {{distribution.CreatedDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a'}}
                  </li>
                  <li>
                    <span class="heading">{{'DISTRIBUTOR.DISTRIBUTION_FORM.PARTICIPANT_COUNT'|translate}}</span>
                    {{distribution.ParticipantCount}}
                  </li>
                  <li>
                    <span class="heading">{{'DISTRIBUTOR.DISTRIBUTION_FORM.VIEW_RESULTS_OPTION'|translate}}</span>
                    {{(distribution.CanViewResults ? 'COMMON.UI.YES' : 'COMMON.UI.NO')|translate }}
                  </li>
                </ul>
                <p-button type="button" label="{{'DISTRIBUTOR.DISTRIBUTION_DETAIL.SEND_ASSESSMENTS'|translate}}" (click)="sendDistribution()"></p-button>
              </div>
            </div>
          </div>

        </fieldset>

      </div>
    </section>
  </div>

</div>
