/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class ComplimentaryLicenseDto
 */
export interface ComplimentaryLicense { 
    LicenseID: number;
    LicenseCode: string;
    DatePurchased: string;
    /**
     * The ID of the Assessment Response that used the license - NULL if the license is not yet in a distribution
     */
    AssessmentResponseID?: number | null;
    /**
     * The UserID of the participant that used the license - NULL if the license is not yet in a distribution
     */
    UserID?: number | null;
    /**
     * The Email of the participant that used the license - NULL if the license is not yet in a distribution
     */
    Email?: string | null;
    /**
     * The FirstName of the participant that used the license - NULL if the license is not yet in a distribution
     */
    FirstName?: string | null;
    /**
     * The LastName of the participant that used the license - NULL if the license is not yet in a distribution
     */
    LastName?: string | null;
    /**
     * The AssessmentStatusID of the assessment that used the license - NULL if the license is not yet in a distribution
     */
    AssessmentStatusID?: number | null;
    /**
     * The AssessmentStatus of the assessment that used the license - NULL if the license is not yet in a distribution
     */
    AssessmentStatus?: string | null;
    /**
     * The AnswerCount of the assessment that used the license - NULL if the license is not yet in a distribution
     */
    AnswerCount?: number;
    /**
     * The QuestionCount of the assessment that used the license - NULL if the license is not yet in a distribution
     */
    QuestionCount?: number;
    /**
     * The UserID of the Owner of the license - NULL if the license is not yet in a distribution
     */
    OwnerID: number;
    /**
     * The Email of the Owner of the license - NULL if the license is not yet in a distribution
     */
    OwnerEmail: string;
    /**
     * The FirstName of the Owner of the license - NULL if the license is not yet in a distribution
     */
    OwnerFirstName?: string | null;
    /**
     * The LastName of the Owner of the license - NULL if the license is not yet in a distribution
     */
    OwnerLastName?: string | null;
}

