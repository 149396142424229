import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html'
})
export class ValidationErrorComponent {
  // properties

  @Input() fieldName?: string
  @Input() fieldNames?: string
  @Input() formGroup?: FormGroup
  @Input() isStandalone?: boolean
  @Input() validatorName?: string

  // constructor

  constructor() {
  }

  // event handlers

  public ngOnInit() {
  }

  // business logic

  public displayError(): boolean {
    if (!this.formGroup) {
      return false
    }

    if (this.fieldName != null) {
      let formField: FormGroup = this.formGroup.get(this.fieldName!) as FormGroup

      if (formField) {
        if (formField.dirty && formField.hasError(this.validatorName!)) {
          return true
        }
      }
    }
    else if (this.fieldNames != null) {
      let fieldNames: string[] = this.fieldNames.split(',')

      if (fieldNames.length) {
        for (var i: number = 0; i < fieldNames.length; i++) {
          let formField: FormGroup = this.formGroup.get(fieldNames[i].trim()) as FormGroup

          if (formField) {
            if (formField.dirty && formField.hasError(this.validatorName!)) {
              return true
            }
          }
        }
      }
    }
    else if (this.formGroup.dirty && this.formGroup.hasError(this.validatorName!)) {
      return true
    }

    return false
  }
}
