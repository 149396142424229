import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslatePipe } from "@ngx-translate/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BaseComponent } from "../../components/base/base.component";
import { AdminService, JsonResponse, UserDto, UserRoleDto, UserSearch, UserStatusDto, UserStatusModify } from "../../core/api";
import { UserStatusEnum } from "../../enums/user-status.enum";
import { AuthService } from "../../service/auth.service";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends BaseComponent implements OnInit {
  //#region variables

  private _dtoSearch!: UserSearch
  public currentPage: number = 1
  public filterFormGroup!: FormGroup
  public totalItems: number = 0
  public userRoles!: { ID: number, Name: string }[]
  private usersPerPage: number = 20
  public userStatusEnum: any = UserStatusEnum
  public userStatuses!: { ID: number, Name: string }[]

  //#endregion

  //#region properties

  get userSearch(): UserSearch {
    return this._dtoSearch;
  }

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickArchiveUser(user: UserDto) {
    this.confirmationService.confirm({
      accept: () => {
        this.archiveUser(user)
      },
      header: this.translate.transform('ADMIN.USER_DETAIL.ARCHIVE_USER'),
      message: this.translate.transform('ADMIN.USER_DETAIL.ARCHIVE_USER_CONFIRM_MESSAGE', { email: user.Email })
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['userRoles', 'users', 'userStatuses'])

    this._dtoSearch = {
      perPage: this.usersPerPage,
      page: 1,
      totalItems: 0,
      items: undefined
    }

    this.initializeForm()
    this.loadUsers();
    this.loadUserRoles();
    this.loadUserStatuses();
  }

  public onGoTo(page: number): void {
    this.submitSearchForm(page)
  }

  //#endregion

  //#region business logic

  private archiveUser(user: UserDto) {
    let userStatusModify: UserStatusModify = {
      ID: user.ID!,
      UserStatus: {
        ID: UserStatusEnum.ARCHIVED,
        Name: ''
      }
    }

    this.processManagerService.resetProcess('users')
    this.adminService.modifyUserStatus(userStatusModify).subscribe({
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('ADMIN.USER_DETAIL.USER_ARCHIVED', { email: user.Email }) })

        this.loadUsers()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private initializeForm() {
    this.filterFormGroup = this.formBuilder.group({
      UserRole: [this._dtoSearch.UserRole],
      UserStatus: [this._dtoSearch.UserStatus],
      SearchTerm: [this._dtoSearch.SearchTerm],
    })

    this.filterFormGroup.controls['UserStatus'].setValue(1)
  }

  private loadUserRoles() {
    this.adminService.listUserRoles().subscribe({
      complete: () => this.processManagerService.notify('userRoles'),
      next: (response: JsonResponse) => {
        this.userRoles = Object.keys(response.data).map(key => {
          let entity: UserRoleDto = response.data[key]
          return {ID: entity.ID ?? 0, Name: entity.Name};
        });
        this.userRoles.unshift({ID: -1, Name: 'All'})
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  private loadUsers() {
    const criteria: UserSearch = {
      UserRole: this.filterFormGroup.controls['UserRole'].value == -1 ? null : this.filterFormGroup.controls['UserRole'].value,
      UserStatus: this.filterFormGroup.controls['UserStatus'].value == -1 ? null : this.filterFormGroup.controls['UserStatus'].value,
      SearchTerm: this.filterFormGroup.controls['SearchTerm'].value,
      perPage: this.usersPerPage,
      page: this.currentPage,
    }

    this.adminService.searchUsers(criteria).subscribe({
      complete: () => this.processManagerService.notify('users'),
      next: (response: JsonResponse) => {
        this._dtoSearch = response.data
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private loadUserStatuses() {
    this.adminService.listUserStatus().subscribe({
      complete: () => this.processManagerService.notify('userStatuses'),
      next: (response: JsonResponse) => {
        this.userStatuses = Object.keys(response.data).map(key => {
          /** @todo replace UserStatusDto with generic Lookup */
          let entity: UserStatusDto = response.data[key]
          return {ID: entity.ID ?? 0, Name: entity.Name};
        });
        this.userStatuses.unshift({ID: -1, Name: 'All'})
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public submitSearchForm(page: number = 1) {
    this.currentPage = page

    this.processManagerService.resetProcess('users')

    this.loadUsers()
  }

  //#endregion
}
