/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SurveyDto } from './surveyDto';
import { ThemeSimple } from './themeSimple';
import { Localization } from './localization';
import { QuestionAnswer } from './questionAnswer';
import { DimensionSimpleDto } from './dimensionSimpleDto';


/**
 * Class QuestionEditDto
 */
export interface QuestionEdit { 
    /**
     * The Read-Only ID of the Question
     */
    ID: number;
    /**
     * The Scoring Parameter
     */
    ScoringParameter: number;
    /**
     * The sequence for this question
     */
    SortOrder: number;
    Survey?: SurveyDto | null;
    Dimension: DimensionSimpleDto;
    /**
     * Theme
     */
    Theme?: ThemeSimple | null;
    /**
     * $QuestionAnswer *_/
     */
    QuestionAnswer: Set<QuestionAnswer>;
    /**
     * $Localizations
     */
    Localizations: Array<Localization>;
}

