<div>
  <h1>
    {{'ADMIN.CONTENT_BLOCK_DETAIL.HEADING'|translate}}
  </h1>
  <p [innerHTML]="'ADMIN.CONTENT_BLOCK_DETAIL.INSTRUCTIONS'|translate"></p>
  <section class="dark-theme">
    <app-localization-toggle (onToggled)="onLanguageToggled($event)"></app-localization-toggle>
    <form [formGroup]="contentBlockForm" (submit)="submitContentBlock()" autocomplete="off" novalidate *ngIf="contentBlockForm">
      <fieldset>
        <div class="p-fluid">
          <div class="grid">
            <div class="field col-9">
              {{'ADMIN.CONTENT_BLOCK_DETAIL.BLOCK_NAME'|translate}}: {{contentBlock.Name}}
            </div>
          </div>
          <div class="grid">
            <div class="field col-9">
              <app-localization-editor [configuration]="editorConfiguration"
                                       controlName="content"
                                       displayName="{{'ADMIN.CONTENT_BLOCK_DETAIL.BLOCK_CONTENT'|translate}}"
                                       [formGroup]="contentBlockForm"
                                       [height]="500"
                                       [maxLength]="5000"
                                       [required]="true">
              </app-localization-editor>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="buttons">
        <p-button type="submit" label="{{'COMMON.UI.SAVE'|translate}}"></p-button>
        <p-button type="button" label="{{'COMMON.UI.CANCEL'|translate}}" (click)="clickCancel()"></p-button>
      </div>
    </form>
  </section>
</div>
