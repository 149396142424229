<table *ngIf="participants" class="list-license-user-detail-participants">
    <thead>
        <tr>
            <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.FIRST_NAME'|translate}}</th>
            <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.LAST_NAME'|translate}}</th>
            <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.EMAIL'|translate}}</th>
            <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.LICENSE_CODE'|translate}}</th>
            <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.ASSESSMENT_STATUS'|translate}}</th>
            <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.ASSESSMENT_QUESTIONS_ANSWERED'|translate}}</th>
        </tr>
    </thead>
    <tbody *ngIf="0 < participants.length">
        <tr *ngFor="let p of participants;">
            <td [attr.data-heading-1]="'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.FIRST_NAME'|translate">{{p.ParticipantFirstName}}</td>
            <td [attr.data-heading-2]="'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.LAST_NAME'|translate">{{p.ParticipantLastName}}</td>
            <td [attr.data-heading-3]="'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.EMAIL'|translate">{{p.ParticipantEmail}}</td>
            <td [attr.data-heading-4]="'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.LICENSE_CODE'|translate">{{p.LicenseCode}}</td>
            <td [attr.data-heading-5]="'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.ASSESSMENT_STATUS'|translate">{{p.AssessmentStatus}}</td>
            <td [attr.data-heading-6]="'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.ASSESSMENT_QUESTIONS_ANSWERED'|translate">{{p.QuestionsAnswered}}</td>
        </tr>
    </tbody>
    <tbody *ngIf="0 == participants.length">
        <tr>
          <td colspan="6">{{'ADMIN.USER_LICENSE_LIST_DETAIL_PARTICIPANTS.ERROR_NO_PARTICIPANTS'|translate}}</td>
        </tr>
    </tbody>
</table>
