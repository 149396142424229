<form class="dark-theme user-details-form" *ngIf="userLicenseForm" (submit)="submit()" [formGroup]="userLicenseForm" autocomplete="off" novalidate>
  <fieldset>
    <legend>{{'ADMIN.LICENSE_DETAIL.LEGEND'|translate}}</legend>
    <div class="grid">
      <div class="col-12 md:col-4 form-field">
        <label for="email">{{'ADMIN.LICENSE_DETAIL.AVAILABLE_COUNT'|translate}}</label>
        <div>
          {{userLicenseSummary.Available}}
        </div>
      </div>
      <div class="col-12 md:col-4 form-field">
        <label for="email">{{'ADMIN.LICENSE_DETAIL.ISSUED_COUNT'|translate}}</label>
        <div>
          {{issuedCount}}
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 md:col-4 form-field">
        <label for="password">{{'ADMIN.LICENSE_DETAIL.NEW_COUNT'|translate}}</label>
        <div>
          <p-inputNumber formControlName="purchasedCount" [min]="issuedCount" [minFractionDigits]="0" mode="decimal" [showButtons]="true" [step]="1"></p-inputNumber>
        </div>
        <app-validation-error fieldName="purchasedCount" [formGroup]="userLicenseForm" validatorName="required">{{'ADMIN.LICENSE_DETAIL.ERROR_NEW_COUNT'|translate}}</app-validation-error>
        <app-validation-error fieldName="purchasedCount" [formGroup]="userLicenseForm" validatorName="min">{{'ADMIN.LICENSE_DETAIL.ERROR_NEW_COUNT_MINIMUM'|translate:{ issuedCount: issuedCount } }}</app-validation-error>
      </div>
    </div>
    <div class="buttons">
      <p-button type="submit" label="{{'COMMON.UI.SAVE'|translate}}"></p-button>
      <p-button type="button" label="{{'COMMON.UI.CANCEL'|translate}}" (click)="clickCancel()"></p-button>
    </div>
  </fieldset>
</form>
