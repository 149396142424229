/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DistributionSummary } from './distributionSummary';
import { SurveyDto } from './surveyDto';
import { LookupDto } from './lookupDto';
import { AssessmentResponseForDistribution } from './assessmentResponseForDistribution';


/**
 * Class DistributionViewDto
 */
export interface DistributionView { 
    /**
     * The ID of the Distribution
     */
    ID: number;
    /**
     * The ID of the Survey for this distribution
     */
    SurveyID: number;
    Survey: SurveyDto;
    /**
     * The name for the distribution
     */
    Name: string;
    /**
     * The number of participants to add to the new distribution
     */
    ParticipantCount: number;
    /**
     * Indicates if Global Admin users can View Participant Results
     */
    CanViewResults: boolean;
    DistributionStatus: LookupDto;
    ParticipantSummary?: DistributionSummary;
    /**
     * the assessment responses in this distribution
     */
    AssessmentResponse?: Set<AssessmentResponseForDistribution>;
    /**
     * Date the distribution was created
     */
    CreatedDate?: string;
    /**
     * Date the distribution was sent
     */
    SentDate?: string | null;
}

