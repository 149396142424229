import {Component, OnInit} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {BaseComponent} from "../components/base/base.component";
import {ProcessManagerService} from "../service/process-manager.service";
// import {InputTextareaModule} from 'primeng/inputtextarea';
// import {InputTextModule} from 'primeng/inputtext';

@Component({
  templateUrl: './admin.component.html',
  // styleUrls: ['./admin.component.css']
})
export class AdminComponent extends BaseComponent implements OnInit {
  public menuActive: boolean = false

  //#region constructor
  constructor(
    processManagerService: ProcessManagerService,
    router: Router,
  ) {
    super(processManagerService)

    router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.menuActive = false
        }
      }
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  //#endregion
  public toggleTabMenu() {
    this.menuActive = !this.menuActive
  }
}
