import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../../components/base/base.component";
import { AdminService, ContentBlock, JsonResponse } from '../../core/api';
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-content-blocks',
  templateUrl: './content-blocks.component.html'
})
export class ContentBlocksComponent extends BaseComponent implements OnInit {
  //#region variables

  public contentBlocks: ContentBlock[] = []

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    processManagerService: ProcessManagerService
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['contentBlocks'])
    this.processManagerService.unforce()

    this.loadContentBlocks()
  }

  //#endregion

  //#region business logic

  private loadContentBlocks() {
    this.processManagerService.force()
    this.adminService.listContentBlocks().subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.contentBlocks = Object.entries(response.data).map(o => o[1]) as ContentBlock[]
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        }
        else {
          this.handleApiException(error)
        }
      }
    })
  }

  //#endregion
}
