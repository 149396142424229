/**
 * Service is a class that can be injected that provides reusable functionality
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GuestService, UserDto, UserLoginResponseDto } from "../core/api";
import { LanguageEnum } from '../enums/language.enum';
import { Observable, Subject, Subscriber } from 'rxjs'
import { CacheService } from './cache.service';
import { UserAssessmentService } from './user-assessment.service';

export const TOKEN_NAME = 'jwt_token';
export const USER_LANGUAGE = 'user_language';
const USER_NAME = 'user';

@Injectable({ providedIn: 'root' })

@Injectable()
export class AuthService {
  //#region Variables
  public readonly userLoginChanges: Subject<UserDto | null> = new Subject<UserDto | null>()
  //#endregion

  //#region Constants
  /**
   * The JWT identifier for the account ID.
   */
  private static readonly ACCOUNT_ID: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
  /**
   * The JWT identifier for the account role ID.
   */
  private static readonly ACCOUNT_ROLE_ID: string = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
  /**
   * The JWT identifier for the email address.
   */
  private static readonly EMAIL_ADDRESS: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
  /**
   * The JWT identifier for the first name.
   */
  private static readonly FIRST_NAME: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
  /**
   * The JWT identifier for the last name.
   */
  private static readonly LAST_NAME: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
  /**
   * The JWT identifier for the language.
   */
  private static readonly LANGUAGE : string = 'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality'
  //#endregion
  
  //#region Properties
  /**
   * Determines if the current user is logged in.
   * @returns boolean True if the current user is logged in, else false.
   */
  public get isUserLoggedIn(): boolean {
    const token: string | undefined = this.getToken()

    if (!token) {
      return false
    }

    if (this.jwtHelper.isTokenExpired(token)) {
      this.unauthorize()
      return false
    }

    return true
  }

  public get languageID(): number {
    if (this.user == null) {
      return null!
    }

    if (this.user.Language == 'FR') {
      return LanguageEnum.FRENCH
    }

    return LanguageEnum.ENGLISH
  }

  public get languageCode(): string {
    return this.user.Language?.toLowerCase()!
      ? this.user.Language?.toLowerCase()!
      : 'en'
  }

  /**
   * Retrieves the current user object.
   * @returns UserDto The current user object.
   * @todo Modify this to fetch the user from a service if it hasn't been loaded (currently stored in localStorage).
   */
  public get user(): UserDto {
    const user : UserDto | null = this.getDecodedToken()

    if (!user) {
      this.router.navigate(['/login'])
      return {
        ID: 0,
        FirstName: '',
        Email: '',
        UserRole: {
          ID: 0,
          Name: ''
        }
      }
    }

    return user
  }

  /**
   * Retrieves the current user's role.
   * @returns string The current user's role.
   */
  public get userRole(): string {
    return this.user.UserRole?.Name!
  }
  //#endregion

  //#region Constants
  /**
   * How long cookies should be stored for.
   */
  private static COOKIE_DURATION : number = 1000 * 60 * 60 * 24 * 30 // 30 days
  //#endregion

  //#region Constructors
  constructor(
    private assessmentService: UserAssessmentService,
    private cacheService: CacheService,
    public jwtHelper: JwtHelperService,
    private guestService: GuestService,
    private router: Router,
  ) {
  }
  //#endregion

  //#region Business Logic

  public authorize(response: UserLoginResponseDto) {
    this.authorizeParticipant(response.Token)
    this.userLoginChanges.next(response.User)
  }

  public getToken(): string | undefined {
    if (!this.cacheService.shortTerm.get(TOKEN_NAME) && this.cacheService.domainInvariant.get(TOKEN_NAME)) {
      this.authorizeParticipant(this.cacheService.domainInvariant.get(TOKEN_NAME))
    } else if (this.cacheService.shortTerm.get(TOKEN_NAME) && !this.cacheService.domainInvariant.get(TOKEN_NAME)) {
      this.authorizeParticipant(this.cacheService.shortTerm.get(TOKEN_NAME))
    }

    return this.cacheService.shortTerm.get(TOKEN_NAME);
  }

  public login(userName: string, password: string): Observable<any> {
    this.unauthorize()

    return new Observable<any>((subscriber: Subscriber<any>) => {
      const onResponse = (response: any) => {
        if (response && 'success' === response.status) {
          this.authorize(response.data as UserLoginResponseDto)
        } else {
          subscriber.error('API.ERROR.UNAUTHORIZED_INVALID_LOGIN_CREDENTIALS')
        }

        subscriber.next(this.isUserLoggedIn)
        subscriber.complete()
      }
      
      this.guestService.login(userName, password).subscribe({
        next: onResponse,
        error: onResponse
      })
    })

  }

  /**
   * Logs a user out of the website.
   */
  public logout(): void {
    this.assessmentService.reset()
    this.unauthorize()
    this.userLoginChanges.next(null)
  }

  public unauthorize() : void {
    this.cacheService.domainInvariant.delete(TOKEN_NAME)
    this.cacheService.shortTerm.delete(TOKEN_NAME)
    this.cacheService.shortTerm.delete(USER_NAME)
    this.userLoginChanges.next(null)
  }

  //#endregion

  //#region internals

  private authorizeParticipant(token: string) : void {
    this.cacheService.shortTerm.set(TOKEN_NAME, token, AuthService.COOKIE_DURATION)
    this.cacheService.domainInvariant.set(TOKEN_NAME, token, AuthService.COOKIE_DURATION)
  }

  /**
   * Retrieves the value of the decoded token.
   * @returns {UserDto|null} The value of the decoded token, or null if the token is invalid or non-existant.
   */
  private getDecodedToken() : UserDto | null {
    if (!this.isUserLoggedIn) {
      return null
    }

    const decodedToken : any = this.jwtHelper.decodeToken(this.getToken()!)
    const user: UserDto = {
      ID: 0,
      FirstName: '',
      Email: '',
      UserRole: {
        ID: 0,
        Name: ''
      }
    }

    for (let key in decodedToken) {
      switch (key) {
        case AuthService.ACCOUNT_ID:
          user.ID = parseInt(decodedToken[key])
          break
        case AuthService.ACCOUNT_ROLE_ID:
          user.UserRole!.Name = decodedToken[key]
          break
        case AuthService.EMAIL_ADDRESS:
          user.Email = decodedToken[key]
          break
        case AuthService.FIRST_NAME:
          user.FirstName = decodedToken[key]
          break
        case AuthService.LAST_NAME:
          user.LastName = decodedToken[key]
          break
        case AuthService.LANGUAGE:
          user.Language = decodedToken[key]
          break
      }
    }

    if (!user.ID || !user.Email || !user.UserRole?.Name) {
      this.unauthorize()
      return null
    }

    return user
  }
  //#endregion

}
