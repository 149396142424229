<div *ngIf="filterFormGroup">
  <section class="dark-theme search-filters">
    <form *ngIf="filterFormGroup" (submit)="submitSearchForm()" [formGroup]="filterFormGroup" autocomplete="off" novalidate>
      <fieldset>
        <legend>{{'ADMIN.COMPLIMENTARY_LICENSES.FILTER_COMPLIMENTARY_LICENSES'|translate}}</legend>
        <div class="p-fluid">
          <div class="grid filter-wrap">
            <div class="col-12 md:col-4 form-field" [formGroup]="filterFormGroup">
              <label for="AssessmentStatusID">{{'ADMIN.COMPLIMENTARY_LICENSES.ASSESSMENT_STATUS'|translate}}</label>
              <p-dropdown appendTo="body"
                          id="UserRole"
                          [formGroup]="filterFormGroup"
                          [options]="assessmentStatuses"
                          optionLabel="Name"
                          optionValue="ID"
                          formControlName="AssessmentStatusID">
              </p-dropdown>
            </div>
            <div class="col-12 md:col-4 form-field">
              <label for="SearchTerm">{{'COMMON.UI.SEARCH'|translate}}</label>
              <input type="text" pInputText formControlName="SearchTerm" id="SearchTerm" />
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col-2 button-wrap">
            <p-button type="submit" label="{{'COMMON.UI.FILTER'|translate}}"></p-button>
          </div>
        </div>
      </fieldset>
    </form>
  </section>

  <a class="form-button button-style alternate create-licence" [routerLink]="['/admin', 'licenses', 'complimentary', 'issue']">{{'ADMIN.COMPLIMENTARY_LICENSES.CREATE_COMPLIMENTARY_LICENSE'|translate}}</a>

  <section class="container">
    <app-pagination [currentPage]="complimentaryLicenseSearch.page ?? 1" [perPage]="complimentaryLicenseSearch.perPage ?? 20" [totalItems]="complimentaryLicenseSearch.totalItems ?? 0" (goTo)="onGoTo($event)"></app-pagination>
  </section>

  <section class="container">
    <table class="tbl-list-complimentary-license">
      <thead>
        <tr>
          <th>{{'ADMIN.COMPLIMENTARY_LICENSES.FIRST_NAME'|translate}}</th>
          <th>{{'ADMIN.COMPLIMENTARY_LICENSES.EMAIL'|translate}}</th>
          <th>{{'ADMIN.COMPLIMENTARY_LICENSES.LICENCE_CODE'|translate}}</th>
          <th class="text-right">{{'ADMIN.COMPLIMENTARY_LICENSES.STATUS'|translate}}</th>
          <th class="text-right">{{'ADMIN.COMPLIMENTARY_LICENSES.PROGRESS'|translate}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let complimentaryLicense of this.complimentaryLicenseSearch.items; index as index">
          <td [attr.data-heading-1]="'ADMIN.COMPLIMENTARY_LICENSES.FIRST_NAME'|translate">{{complimentaryLicense.FirstName}}</td>
          <td [attr.data-heading-2]="'ADMIN.COMPLIMENTARY_LICENSES.EMAIL'|translate">
            <a *ngIf="complimentaryLicense.Email" href="mailto:{{complimentaryLicense.Email}}">{{complimentaryLicense.Email}}</a>
          </td>
          <td [attr.data-heading-3]="'ADMIN.COMPLIMENTARY_LICENSES.LICENCE_CODE'|translate">{{complimentaryLicense.LicenseCode}}</td>
          <td [attr.data-heading-4]="'ADMIN.COMPLIMENTARY_LICENSES.STATUS'|translate" class="text-right">{{complimentaryLicense.AssessmentStatus}}</td>
          <td [attr.data-heading-5]="'ADMIN.COMPLIMENTARY_LICENSES.PROGRESS'|translate" class="text-right">
            {{complimentaryLicense.AnswerCount}} / {{complimentaryLicense.QuestionCount}}
          </td>
        </tr>
      </tbody>
    </table>

  </section>
</div>
<!--<router-outlet></router-outlet>-->
