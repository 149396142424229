/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DistributionReminder } from './distributionReminder';


export interface ReminderSearchAllOf { 
    /**
     * ID of the Distributor (User) to filter by (used internally for the detail page)
     */
    DistributorID?: number | null;
    /**
     * $items *_/
     */
    items?: Set<DistributionReminder>;
}

