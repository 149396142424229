<div>
  <h1>
    {{'ADMIN.SCORING_MATRIX.HEADING'|translate}}
  </h1>
  <p [innerHTML]="'ADMIN.SCORING_MATRIX.INSTRUCTIONS'|translate"></p>
  <form class="dark-theme scoring-matrix-form" *ngIf="scoringMatrixForm" (submit)="submitScoringMatrix()" [formGroup]="scoringMatrixForm" autocomplete="off" novalidate>
    <fieldset>
      <div class="p-fluid">
        <div class="grid">
          <div class="col-6">
            <div class="form-field">
              <table class="tbl-list-survey" formArrayName="rows">
                <thead>
                  <tr>
                    <th class="score-name">{{'ADMIN.SCORING_MATRIX.SCORE_NAME'|translate}}</th>
                    <th class="positive-score">{{'ADMIN.SCORING_MATRIX.POSITIVE_SCORE'|translate}}</th>
                    <th class="negative-score">{{'ADMIN.SCORING_MATRIX.NEGATIVE_SCORE'|translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let formGroup of this.formArrayRows.controls; index as index">
                    <tr [formGroupName]="index">
                      <td [attr.data-heading-1]="'ADMIN.SCORING_MATRIX.SCORE_NAME'|translate" class="score-name">{{formGroup.get('name')?.value}}</td>
                      <td [attr.data-heading-2]="'ADMIN.SCORING_MATRIX.POSITIVE_SCORE'|translate" class="positive-score">
                        <p-inputNumber formControlName="positiveScore" [max]="10" [min]="0" [minFractionDigits]="2" mode="decimal" [showButtons]="true" [step]="0.5"></p-inputNumber>
                        <app-validation-error fieldName="positiveScore" [formGroup]="returnFormGroup(formGroup)" validatorName="required">{{'ADMIN.SCORING_MATRIX.ERROR_SCORE'|translate}}</app-validation-error>
                      </td>
                      <td [attr.data-heading-3]="'ADMIN.SCORING_MATRIX.NEGATIVE_SCORE'|translate" class="negative-score">
                        <p-inputNumber formControlName="negativeScore" [max]="10" [min]="0" [minFractionDigits]="2" mode="decimal" [showButtons]="true" [step]="0.5"></p-inputNumber>
                        <app-validation-error fieldName="negativeScore" [formGroup]="returnFormGroup(formGroup)" validatorName="required">{{'ADMIN.SCORING_MATRIX.ERROR_SCORE'|translate}}</app-validation-error>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="buttons">
      <p-button type="submit" label="{{'COMMON.UI.SAVE'|translate}}"></p-button>
      <p-button type="button" label="{{'COMMON.UI.CANCEL'|translate}}" (click)="clickCancel()"></p-button>
    </div>
  </form>
</div>
