/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Search } from './search';
import { ViewQuestionsDto } from './viewQuestionsDto';
import { QuestionSearchAllOf } from './questionSearchAllOf';


/**
 * Class QuestionSearchDto
 */
export interface QuestionSearch { 
    /**
     * Number of items per page requested/returned
     */
    perPage?: number;
    /**
     * Page number requested/returned
     */
    page?: number;
    /**
     * The total number of items in all pages
     */
    totalItems?: number;
    /**
     * The total number of pages
     */
    pageCount?: number;
    /**
     * The sort order for the results - used/implemented internally only
     */
    sort?: string;
    /**
     * ID of the Survey to filter by
     */
    SurveyID?: number | null;
    /**
     * Question Number to filter by
     */
    SortOrder?: number | null;
    /**
     * Dimension to filter by
     */
    DimensionID?: number | null;
    /**
     * Theme to filter by
     */
    ThemeID?: number | null;
    /**
     * Question ID to filter by
     */
    QuestionID?: number | null;
    /**
     * $items *_/
     */
    items?: Set<ViewQuestionsDto>;
}

