/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurchaseStats } from './purchaseStats';
import { DateRangeDto } from './dateRangeDto';


/**
 * Class DashboardAdminDto
 */
export interface DashboardAdminDto { 
    PurchaseStatsALL: PurchaseStats;
    PurchaseStatsYTD: PurchaseStats;
    PurchaseStatsMTD: PurchaseStats;
    /**
     * The range of year-month combinations valid for selection in the UI
     */
    PurchaseStatsMonthRange: Array<DateRangeDto>;
    /**
     * The range of years valid for selection in the UI
     */
    PurchaseStatsYearRange: Array<DateRangeDto>;
}

