import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppTranslatePipe } from './app-translate.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { AppMaskPipe } from './appmask.pipe';

@NgModule({
  declarations: [
    AppTranslatePipe,
    YesNoPipe,
    AppMaskPipe
  ],
  exports: [
    AppTranslatePipe,
    YesNoPipe,
    AppMaskPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    AppTranslatePipe,
    YesNoPipe
  ]
})
export class PipesModule {
}
