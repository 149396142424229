/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SurveyDto } from './surveyDto';
import { UserMinimalDto } from './userMinimalDto';
import { Theme } from './theme';
import { Dimension } from './dimension';
import { Localization } from './localization';


/**
 * Class QuestionDto
 */
export interface Question { 
    /**
     * The Read-Only ID of the Question
     */
    ID: number;
    /**
     * The Question Text
     */
    Text: string;
    /**
     * The Scoring Parameter
     */
    ScoringParameter: number;
    /**
     * The sequence for this question
     */
    SortOrder: number;
    Survey?: SurveyDto | null;
    /**
     * Dimension
     */
    Dimension?: Dimension | null;
    /**
     * Theme
     */
    Theme?: Theme | null;
    UpdatedBy?: UserMinimalDto | null;
    UpdatedDate?: string | null;
    /**
     * *_/
     */
    Localizations: Array<Localization>;
}

