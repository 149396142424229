import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslatePipe} from "@ngx-translate/core";
import {MessageService} from "primeng/api";
import {BaseComponent} from "../../components/base/base.component";
import {
  LocalizationInputBaseComponent
} from "../../components/controls/localization-input-base/localization-input-base.component";
import {
  AdminService,
  DimensionAnswer,
  DimensionAnswerSimple,
  DimensionEdit,
  JsonResponse,
  Language,
  Localization
} from "../../core/api";
import {AuthService} from "../../service/auth.service";
import {FormValidationService} from "../../service/form-validation.service";
import {LanguagesService} from "../../service/languages.service";
import {LocalizationService} from "../../service/localization.service";
import {ProcessManagerService} from "../../service/process-manager.service";

@Component({
  selector: 'app-dimension-detail',
  templateUrl: './dimension-detail.component.html'
})
export class DimensionDetailComponent extends BaseComponent implements OnInit {
  //#region variables

  private dimension!: DimensionEdit
  public dimensionForm: FormGroup = null!
  private dimensionID!: number
  public languages: Language[] = null!

  public maxLengthDimensionName = 50
  public maxLengthDimensionText = 255
  public maxLengthAnswerText = 255


  //#endregion

  //#region properties

  @ViewChildren(LocalizationInputBaseComponent) localizationInputElements: QueryList<LocalizationInputBaseComponent> = null!

  get formArrayDimensionAnswers(): FormArray {
    return this.dimensionForm.get('dimensionAnswers') as FormArray
  }

  public get isFortyQuestionSurvey(): boolean {
    return 'Sum' === this.dimension.Survey?.CalculationMethod
  }

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private languagesService: LanguagesService,
    private localizationService: LocalizationService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickCancel() {
    this.router.navigate(['/admin', 'assessments', 'dimensions'])
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['dimension'])
    this.processManagerService.unforce()

    this.languages = this.languagesService.returnLanguages()

    this.route.params.subscribe((params: Params) => {
      if (params['dimensionID']) {
        this.dimensionID = parseInt(params['dimensionID'])
      }

      if (null != this.dimensionID) {
        this.loadDimension()
      }
    })
  }

  public onLanguageToggled(languageID: number) {
    this.localizationInputElements.forEach(c => c.toggle(languageID))
  }

  public submitDimension() {
    this.saveDimension()
  }

  //#endregion

  //#region business logic

  private initializeForm() {
    let localizations: Localization[] = Object.entries(this.dimension.Localizations!).map(o => o[1]) as Localization[]

    this.dimensionForm = this.formBuilder.group({
      dimensionAnswers: this.formBuilder.array([]),
      name: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Name', true, this.maxLengthDimensionName, this.languages, localizations),
      text: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Text', true, this.maxLengthDimensionText, this.languages, localizations),
    })

    for (let dimensionAnswer of (this.dimension.DimensionAnswer as DimensionAnswer[])) {
      localizations = Object.entries(dimensionAnswer.Localizations!).map(o => o[1]) as Localization[]
      let formGroup: FormGroup = this.formBuilder.group({
        id: [dimensionAnswer.ID, []],
        text: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Text', true, this.maxLengthDimensionText, this.languages, localizations),
      });
      this.formArrayDimensionAnswers.push(formGroup)
    }
  }

  private loadDimension() {
    this.processManagerService.force()
    this.adminService.editDimension(this.dimensionID).subscribe({
      complete: () => this.processManagerService.notify('dimension'),
      next: (response: JsonResponse) => {
        this.dimension = response.data

        this.initializeForm()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  public saveDimension() {
    let i: number;
    this.localizationInputElements.forEach(c => c.update())

    if (this.dimensionForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.dimensionForm)
      return
    }

    let localizations: Localization[] = Object.entries(this.dimension.Localizations!).map(o => o[1]) as Localization[]
    for (i = 0; i < localizations.length; i++) {
      localizations[i].Name = this.localizationService.returnLocalizationValue(localizations[i].LanguageId!, 'name', this.dimensionForm)
      localizations[i].Text = this.localizationService.returnLocalizationValue(localizations[i].LanguageId!, 'text', this.dimensionForm)
    }
    for (i = 0; i < this.formArrayDimensionAnswers.controls.length; i++) {
      let formGroup: FormGroup = this.formArrayDimensionAnswers.controls[i] as FormGroup
      let id: number = formGroup.get('id')?.value
      let dimensionAnswer: DimensionAnswerSimple = this.dimension.DimensionAnswer.find(a => a.ID == id)!
      let localizations: Localization[] = Object.entries(dimensionAnswer.Localizations!).map(o => o[1]) as Localization[]
      for (let j: number = 0; j < localizations.length; j++) {
        localizations[j].Text = this.localizationService.returnLocalizationValue(localizations[j].LanguageId!, 'text', formGroup)
      }
    }

    this.processManagerService.force()
    this.adminService.modifyDimension(this.dimension).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (/*response: JsonResponse*/) => {
        this.messageService.add({
          life: 10000,
          severity: 'info',
          detail: this.translate.transform('ADMIN.DIMENSION_DETAIL.SAVED')
        })

        this.router.navigate(['/admin', 'assessments', 'dimensions'])
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public returnFormGroup(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup
  }

  //#endregion
}
