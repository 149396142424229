<div>
  <h1>
    {{'ADMIN.QUESTIONS.HEADING'|translate}}
  </h1>

  <p [innerHTML]="'ADMIN.QUESTIONS.INSTRUCTIONS'|translate"></p>

  <section class="dark-theme search-filters">
    <form *ngIf="filterFormGroup" (submit)="submitSearchForm()" [formGroup]="filterFormGroup" autocomplete="off" novalidate>
      <fieldset>
        <legend>{{'ADMIN.QUESTIONS.FILTER_QUESTIONS'|translate}}</legend>
        <div class="grid">
          <div class="col-12 md:col-6 form-field" [formGroup]="filterFormGroup">
            <label for="SurveyID">{{'ADMIN.QUESTIONS.SURVEY'|translate}}</label>
            <p-dropdown id="SurveyID"
                        [formGroup]="filterFormGroup"
                        [options]="luSurveys"
                        optionLabel="Name"
                        optionValue="ID"
                        formControlName="SurveyID"
                        appendTo="body"
                        placeholder="{{'ADMIN.QUESTIONS.SURVEY'|translate}}"
                        (onChange)="onFilterSurveyChanged()">
            </p-dropdown>
          </div>

          <div class="col-12 md:col-6 form-field">
            <label for="SortOrder">{{'ADMIN.QUESTIONS.QUESTION_NUMBER'|translate}}</label>
            <input type="number" pInputText formControlName="SortOrder" id="SortOrder" />
          </div>

          <div class="col-12 md:col-6 form-field" [formGroup]="filterFormGroup">
            <label for="DimensionID">{{'ADMIN.QUESTIONS.DIMENSION'|translate}}</label>
            <p-dropdown id="DimensionID"
                        [formGroup]="filterFormGroup"
                        [options]="luDimensions"
                        optionLabel="Name"
                        optionValue="ID"
                        formControlName="DimensionID"
                        appendTo="body"
                        [showClear]="true"
                        placeholder="{{'ADMIN.QUESTIONS.DIMENSION'|translate}}">
            </p-dropdown>
          </div>

          <div class="col-12 md:col-6 form-field" [formGroup]="filterFormGroup">
            <label for="ThemeID">{{'ADMIN.QUESTIONS.THEME'|translate}}</label>
            <p-dropdown id="ThemeID"
                        [formGroup]="filterFormGroup"
                        [options]="luThemes"
                        optionLabel="Name"
                        optionValue="ID"
                        formControlName="ThemeID"
                        appendTo="body"
                        [showClear]="true"
                        placeholder="{{'ADMIN.QUESTIONS.THEME'|translate}}">
            </p-dropdown>
          </div>
        </div>
        <p-button type="submit" label="{{'COMMON.UI.FILTER'|translate}}"></p-button>

      </fieldset>
    </form>
  </section>

  <section class="container" *ngIf="questionSearch.items">

    <table class="tbl-list-question">
      <thead>
        <tr>
          <th class="question-no">{{'ADMIN.QUESTIONS.QUESTION_NUMBER'|translate}}</th>
          <th class="dimension">{{'ADMIN.QUESTIONS.DIMENSION'|translate}}</th>
          <th class="question">{{'ADMIN.QUESTIONS.QUESTION'|translate}}</th>
          <th class="theme">{{'ADMIN.QUESTIONS.THEME'|translate}}</th>
          <th class="updated-by">{{'ADMIN.QUESTIONS.UPDATED_BY'|translate}}</th>
          <th class="update-date">{{'ADMIN.QUESTIONS.UPDATE_DATE'|translate}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let question of this.questionSearch.items; index as index">
          <td [attr.data-heading-1]="'ADMIN.QUESTIONS.QUESTION_NUMBER'|translate" class="question-no">{{question.SortOrder}}</td>
          <td [attr.data-heading-2]="'ADMIN.QUESTIONS.DIMENSION'|translate" class="dimension">{{question.DimensionName}}</td>
          <td [attr.data-heading-3]="'ADMIN.QUESTIONS.QUESTION'|translate" class="question">{{question.QuestionText}}</td>
          <td [attr.data-heading-4]="'ADMIN.QUESTIONS.THEME'|translate" class="theme">{{question.ThemeName1}}{{isFortyQuestionSurvey ? (' / ' + question.ThemeName2) : ''}}</td>
          <td [attr.data-heading-5]="'ADMIN.QUESTIONS.UPDATED_BY'|translate" class="updated-by">{{question.UpdatedBy?.FirstName + ' ' + question.UpdatedBy?.LastName}}</td>
          <td [attr.data-heading-6]="'ADMIN.QUESTIONS.UPDATE_DATE'|translate" class="update-date">{{question.UpdatedDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a'}}</td>
          <td>
            <a class="edit icon-edit" [routerLink]="['/admin', 'assessments', 'questions', 'edit', question.QuestionID]">{{'COMMON.UI.EDIT'|translate}}</a>
          </td>
        </tr>
      </tbody>
    </table>

  </section>
</div>
