/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Localization } from './localization';
import { ThemeGroup } from './themeGroup';


/**
 * Class ThemeDto
 */
export interface Theme { 
    /**
     * The Read-Only ID
     */
    ID: number;
    /**
     * The Question Theme Name
     */
    Name: string;
    ThemeGroup: ThemeGroup;
    /**
     * *_/
     */
    Localizations: Array<Localization>;
}

