/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserDto } from './userDto';


export interface UserSearchAllOf { 
    /**
     * ID of the UserRole to filter by
     */
    UserRole?: number | null;
    /**
     * ID of the User Status to filter by
     */
    UserStatus?: number | null;
    /**
     * Search terms
     */
    SearchTerm?: string | null;
    /**
     * $items *_/
     */
    items?: Set<UserDto>;
}

