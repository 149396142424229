import { Injectable } from "@angular/core";

/**
 * An interface representing cacheable data.
 */
export interface ICache {
    /**
     * Deletes a key from the cache.
     * @param {string} key The key to delete from the cache.
     */
    delete(key: string) : void;
    /**
     * Gets the value of a cached item.
     * @param {string} key The name of the cached data to retrieve.
     * @return {any|undefined} Undefined if the cache key is not set, else the value of the cached data. 
     */
    get(key: string) : any | undefined
    /**
     * Sets the value of a cache.
     * @param {string} key The key to save the cache data under.
     * @param {any} value The value to store in the cache.
     * @param {number} expiry The length of time, in seconds, to store the item in the cache.
     */
    set(key: string, value: any, expiry: number) : void;
}

/**
 * Handles caching items in cookies.
 */
class CookieStorage implements ICache {
    //#region Business Logic
    /**
     * {@inheritDoc}
     */
    public delete(key: string) : void {
        this.set(key, '', -1000)
    }

    /**
     * {@inheritDoc}
     */
    public get(key: string) : any | undefined {
        let result = document.cookie.split(';').map((part: string) => part.split('=').map((kv : string) => kv.trim())).find((cookie: string[]) => key === cookie[0])

        if (undefined !== result) {
            result = JSON.parse(result[1])
        }

        return result
    }

    /**
     * {@inheritDoc}
     */
    public set(key: string, value: any, expiry: number) : void {
        const now : Date = new Date()
        now.setTime(now.getTime() + expiry)
        value = JSON.stringify(value)
        let domain = '';
        if (-1 !== window.location.hostname.indexOf('conflictability')) {
            const parts = window.location.hostname.split('.')
            const hostnameDomain: string = parts.slice(parts.indexOf('conflictability')).join('.')
                + (window.location.port ? `:${window.location.port}` : '')
            domain = `domain=${hostnameDomain}; SameSite=None;`;
        }
        document.cookie = `${key}=${value}; path=/; expires=${now.toUTCString()}; ${domain}` + (-1 !== window.location.protocol.toLocaleLowerCase().indexOf('https') ? ' Secure' : '')
    }
    //#endregion
}

/**
 * Handles caching items in a storage interface.
 */
class StorageCache implements ICache {
    //#region Constants
    /**
     * The constant to use for expiring data.
     */
    private static EXPIRY_KEY : string = '-expiry'
    //#endregion

    //#region Constructors
    /**
     * Creates a new instance of the storage cache.
     * @param {Storage} storage An interface to store items in.
     */
    constructor(private storage: Storage) {}
    //#endregion

    //#region Business Logic
    /**
     * {@inheritDoc}
     */
    public delete(key: string) : void {
        this.storage.removeItem(key)
        this.storage.removeItem(`${key}-${StorageCache.EXPIRY_KEY}`)
    }

    /**
     * {@inheritDoc}
     */
    public get(key: string) : any | undefined {
        if (!this.isValidKey(key)) {
            return undefined
        }

        return JSON.parse(this.storage.getItem(key) as string)
    }

    /**
     * {@inheritDoc}
     */
    public set(key: string, value: any, expiry: number) : void {
        const now : Date = new Date()
        now.setTime(now.getTime() + expiry)
        this.storage.setItem(key, JSON.stringify(value))
        this.storage.setItem(`${key}-${StorageCache.EXPIRY_KEY}`, now.getTime().toString())
    }
    //#endregion

    //#region Internals
    /**
     * Determines if a key is valid.
     * @param {string} key The key to test.
     * @returns {boolean} True if the key is valid, else false.
     */
    private isValidKey(key : string) : boolean {
        if (null === this.storage.getItem(key)) {
            return false
        }

        const timestamp : number = parseInt(this.storage.getItem(`${key}-${StorageCache.EXPIRY_KEY}`) as string)

        return timestamp >= (new Date()).getTime()
    }
    //#endregion
}

/**
 * Handles caching things in a persistent setting.
 */
@Injectable()
export class CacheService {
    //#region Variables
    /**
     * Handles domain-invariant storage (which persists between subdomains).
     */
    public domainInvariant: ICache
    /**
     * Handles long-term storage (expires when the expiry occurs).
     */
    public longTerm: ICache
    /**
     * Handles short-term storage (expires at the end of the user session, or when the expiry occurs).
     */
    public shortTerm: ICache
    //#endregion
    
    //#region Constructors
    constructor() {
        if ("undefined" !== typeof(Storage)) {
            this.shortTerm = new StorageCache(sessionStorage)
            this.longTerm = new StorageCache(localStorage)
        } else {
            this.shortTerm = this.longTerm = new CookieStorage()
        }
        this.domainInvariant = new CookieStorage()
    }
    //#endregion
}