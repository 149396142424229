<nav *ngIf="1 < pages.length">
  <ul>
    <li>
      <p-button type="button"
                label="{{'COMMON.UI.PREV'|translate}}"
                aria-label="Go To Previous Page"
                [attr.aria-disabled]="1 === currentPage"
                [disabled]="1 === currentPage"
                (onClick)="onGoTo(currentPage - 1)">
      </p-button>
    </li>
    <li *ngFor="let page of pages">
      <p-button type="button"
                label="{{page}}"
                [attr.aria-current]="page === currentPage ? 'page' : null"
                [attr.aria-label]="page === currentPage ? 'Current Page, Page ' + page : 'Go to Page ' + page"
                [class.current]="page === currentPage"
                tabindex="0"
                (onClick)="onGoTo(page)"
                (keyup.enter)="onGoTo(page)">
      </p-button>
    </li>
    <li>
      <p-button type="button"
                label="{{'COMMON.UI.NEXT'|translate}}"
                aria-label="Go To Next Page"
                [attr.aria-disabled]="currentPage === pages.length"
                [disabled]="currentPage === pages.length"
                (onClick)="onGoTo(currentPage + 1)">
      </p-button>
    </li>
  </ul>
</nav>
