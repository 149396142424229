/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class JsonResponse
 */
export interface JsonResponse { 
    /**
     * The http response code
     */
    code: number;
    /**
     * The http response message
     */
    message: string;
    /**
     * The consumable response data
     */
    data?: any | null;
}

