<div>
  <h1>
    {{'ADMIN.THEMES.HEADING'|translate}}
  </h1>

  <p [innerHTML]="'ADMIN.THEMES.INSTRUCTIONS'|translate"></p>

  <table class="tbl-list-theme" *ngIf="themes">
    <thead>
      <tr>
        <th class="group">{{'ADMIN.THEMES.THEME_GROUP'|translate}}</th>
        <th class="theme-name">{{'ADMIN.THEMES.THEME_NAME'|translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let theme of this.themes; index as index">
        <td [attr.data-heading-1]="'ADMIN.THEMES.THEME_GROUP'|translate" class="group">{{theme.ThemeGroup|appTranslate:'Name'}}</td>
        <td [attr.data-heading-2]="'ADMIN.THEMES.THEME_NAME'|translate" class="theme-name">{{theme|appTranslate:'Name'}}</td>
      </tr>
    </tbody>
  </table>
</div>
