import {Injectable} from "@angular/core";
import {Observable, Subscriber} from "rxjs";
import {AdminService, JsonResponse} from "../core/api";

// providedIn: Means that this is available everywhere in the application. Does not need to be declared as a provider in a module.
// if is specific to a module, you declare it as a provider for that module, and modify the signature to be @Injectable()
@Injectable({
  providedIn: 'root'
})
export class ParticipantsService {
  private static cached: any = {}

  public constructor(
    private adminService: AdminService
  ) {
  }

  public listDistributionParticipants(distributionId: number): Observable<JsonResponse> {
    return new Observable<JsonResponse>((subscriber: Subscriber<JsonResponse>) => {
      if ("undefined" !== typeof (ParticipantsService.cached[distributionId])) {
        subscriber.next(ParticipantsService.cached[distributionId] /*as JsonResponse*/)
        // return
      } else {
        this.adminService.listDistributionParticipants(distributionId).subscribe({
          next: (response: JsonResponse) => {
            ParticipantsService.cached[distributionId] = response.data.items
            subscriber.next(ParticipantsService.cached[distributionId])
            // return
          }
        })
      }

    })
  }
}
