<div>
  <h1>
    {{'ADMIN.SCORING.HEADING'|translate}}
  </h1>

  <p [innerHTML]="'ADMIN.SCORING.INSTRUCTIONS'|translate"></p>

  <table class="tbl-list-survey" *ngIf="surveys">
    <thead>
      <tr>
        <th class="question-no">{{'ADMIN.SCORING.NAME'|translate}}</th>
        <th class="dimension">{{'ADMIN.SCORING.QUESTION_COUNT'|translate}}</th>
        <th class="question">{{'ADMIN.SCORING.CALCULATION_METHOD'|translate}}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let survey of this.surveys; index as index">
        <td [attr.data-heading-1]="'ADMIN.SCORING.NAME'|translate" class="name">{{survey|appTranslate:'Name'}}</td>
        <td [attr.data-heading-2]="'ADMIN.SCORING.QUESTION_COUNT'|translate" class="question-count">{{survey.QuestionCount}}</td>
        <td [attr.data-heading-3]="'ADMIN.SCORING.CALCULATION_METHOD'|translate" class="calculation-method">{{survey.CalculationMethod}}</td>
        <td>
          <a *ngIf="'Average' === survey.CalculationMethod" class="edit icon-edit" [routerLink]="['/admin', 'assessments', 'scoring', 'edit', survey.ID]">{{'COMMON.UI.EDIT'|translate}}</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
