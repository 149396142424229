import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../pipes/pipes.module";
import { PrimeNgModule } from "../prime-ng-module";
import { ConfirmationEmailDetailComponent } from "./confirmation-email-detail/confirmation-email-detail.component";
import { DropdownComponent } from "./controls/dropdown/dropdown.component";
import { InputTextComponent } from "./controls/input-text/input-text.component";
import { LocalizationEditorComponent } from "./controls/localization-editor/localization-editor.component";
import { LocalizationInputBaseComponent } from "./controls/localization-input-base/localization-input-base.component";
import { LocalizationInputTextComponent } from "./controls/localization-input-text/localization-input-text.component";
import { LocalizationTextareaComponent } from "./controls/localization-textarea/localization-textarea.component";
import { LocalizationToggleComponent } from "./controls/localization-toggle/localization-toggle.component";
import { PaginationComponent } from './controls/pagination/pagination.component';
import { ValidationErrorComponent } from "./controls/validation-error/validation-error.component";
import { DynamicStyledOutputComponent } from "./dynamic-styled-output/dynamic-styled-output.component";
import { InvitationEmailDetailComponent } from "./invitation-email-detail/invitation-email-detail.component";
import { AppLogoLoaderComponent } from "./logo-loader/logo-loader.component";
import { ParticipantList } from "./participant-list/participant-list.component";
import { ReminderEmailDetailComponent } from "./reminder-email-detail/reminder-email-detail.component";

@NgModule({
  declarations: [
    AppLogoLoaderComponent,
    ConfirmationEmailDetailComponent,
    DropdownComponent,
    DynamicStyledOutputComponent,
    InputTextComponent,
    InvitationEmailDetailComponent,
    LocalizationEditorComponent,
    LocalizationInputBaseComponent,
    LocalizationInputTextComponent,
    LocalizationTextareaComponent,
    LocalizationToggleComponent,
    PaginationComponent,
    ParticipantList,
    ReminderEmailDetailComponent,
    ValidationErrorComponent,
  ],
  exports: [
    AppLogoLoaderComponent,
    DropdownComponent,
    DynamicStyledOutputComponent,
    InputTextComponent,
    LocalizationEditorComponent,
    LocalizationInputBaseComponent,
    LocalizationInputTextComponent,
    LocalizationTextareaComponent,
    LocalizationToggleComponent,
    PaginationComponent,
    ParticipantList,
    ValidationErrorComponent,
  ],
  imports: [
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    PrimeNgModule,
    TranslateModule.forChild(),
    PipesModule,
  ]
})
export class AppComponentsModule {
}
