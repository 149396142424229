/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurchaseItem } from './purchaseItem';


/**
 * Class PurchaseDto
 */
export interface Purchase { 
    /**
     * The Transaction Type (Purchase, Complimentary, or Removal)
     */
    TransactionType?: string;
    /**
     * The user\'s role (Participant or Admin)
     */
    UserRole?: string;
    /**
     * The user\'s first name
     */
    FirstName?: string | null;
    /**
     * The user\'s last name
     */
    LastName?: string | null;
    /**
     * The user\'s email address: System-Unique
     */
    Email?: string;
    /**
     * The wordpress Purchase Reference: System-Unique
     */
    PurchaseReference?: string;
    /**
     * The wordpress Purchase Date: UTC DateTime (MySql DateTime format) - if omitted, the current date-time will be used
     */
    PurchaseDate?: string | null;
    /**
     * *_/
     */
    PurchaseItems?: Set<PurchaseItem>;
    /**
     * 2 Letter country code \"EN\", \"FR\" etc.
     */
    Language?: string;
}

