<div>
  <h1>
    {{'DISTRIBUTOR.REMINDER_DETAIL.HEADING'|translate}}
  </h1>
  <p [innerHTML]="instructionsMessage|translate"></p>
  <section class="dark-theme">
    <form class="reminder-details-form" *ngIf="distributionReminderForm" (submit)="submitReminder()" [formGroup]="distributionReminderForm" autocomplete="off" novalidate>
      <fieldset>
        <div class="p-fluid">
          <div class="grid">
            <div class="col-12 md:col-4 form-field">
              <label for="SurveyID">{{'DISTRIBUTOR.REMINDER_DETAIL.DATE'|translate}}</label>
              <p-calendar formControlName="date" appendTo="body" [minDate]="minimumDate" [showTime]="true" hourFormat="12"></p-calendar>
              <app-validation-error fieldName="date" [formGroup]="distributionReminderForm" validatorName="required">{{'DISTRIBUTOR.REMINDER_DETAIL.ERROR_DATE'|translate}}</app-validation-error>
            </div>
            <div class="col-12 md:col-4 form-field">
              <label for="SurveyID">{{'DISTRIBUTOR.REMINDER_DETAIL.SENT'|translate}}</label>
              <input pInputText readonly type="text" [value]="distributionReminder.ReminderSent|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a'" class="read-only" *ngIf="distributionReminder.ReminderSent" />
              <input pInputText readonly type="text" value="{{'DISTRIBUTOR.REMINDER_DETAIL.NOT_SENT'|translate}}" class="read-only" *ngIf="!distributionReminder.ReminderSent" />
            </div>
          </div>
        </div>
      </fieldset>
      <div class="buttons">
        <p-button type="submit" label="{{'COMMON.UI.SAVE'|translate}}" *ngIf="reminderID == null"></p-button>
        <p-button type="button" label="{{'COMMON.UI.CANCEL'|translate}}" (click)="clickCancel()"></p-button>
      </div>
    </form>
  </section>
</div>
