/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ThemeGroupSimple } from './themeGroupSimple';
import { Localization } from './localization';


/**
 * Class DimensionSimpleDto
 */
export interface DimensionSimpleDto { 
    /**
     * The Read-Only ID of the Dimension
     */
    ID: number;
    ThemeGroup?: ThemeGroupSimple;
    /**
     * The number of possible answers for this dimension (2-point or 5-point scale)
     */
    AnswerCount: number;
    /**
     * *_/
     */
    Localizations: Array<Localization>;
}

