<!-- Toolbar -->
<div>
  <div class="dashboard-navigation-wrapper">
    <div class="wrapper">
      <div class="menu-toggle" [ngClass]="{'tab-menu-active': this.menuActive}">
        <button class="mobile-nav-toggle" (click)="toggleTabMenu()">
          {{'LAYOUT.NAV.MENU'|translate}}
          <span>X</span>
        </button>
      </div>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" routerLink="dashboard" routerLinkActive="activebutton">{{'LAYOUT.NAV.DASHBOARD'|translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="users" routerLinkActive="activebutton">{{'LAYOUT.NAV.USERS'|translate}}</a>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [attr.data-back-label]="'LAYOUT.NAV.BACK' | translate">
            {{'LAYOUT.NAV.ASSESSMENT_MANAGEMENT'|translate}}
          </a>
          <ul class="dropdown-menu">
            <li class="dropdown-item"><a class="nav-link" routerLink="assessments/dimensions" routerLinkActive="activebutton">{{'LAYOUT.NAV.DIMENSION_MANAGEMENT'|translate}}</a></li>
            <li class="dropdown-item"><a class="nav-link" routerLink="assessments/themes" routerLinkActive="activebutton">{{'LAYOUT.NAV.THEMES'|translate}}</a></li>
            <li class="dropdown-item"><a class="nav-link" routerLink="assessments/questions" routerLinkActive="activebutton">{{'LAYOUT.NAV.QUESTION_MANAGEMENT'|translate}}</a></li>
            <li class="dropdown-item"><a class="nav-link" routerLink="assessments/scoring" routerLinkActive="activebutton">{{'LAYOUT.NAV.SCORING_MANAGEMENT'|translate}}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle d-none d-md-block" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" routerLink="licenses" routerLinkActive="activebutton">
            {{'LAYOUT.NAV.LICENSE_MANAGEMENT'|translate}}
          </a>
          <a class="nav-link dropdown-toggle d-block d-md-none" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [attr.data-back-label]="'LAYOUT.NAV.BACK' | translate">
            {{'LAYOUT.NAV.LICENSE_MANAGEMENT'|translate}}
          </a>
          <ul class="dropdown-menu">
            <li class="dropdown-item d-block d-md-none"><a class="nav-link" routerLink="licenses/" routerLinkActive="activebutton">{{'LAYOUT.NAV.LICENSE_MANAGEMENT'|translate}}</a></li>
            <li class="dropdown-item"><a class="nav-link" routerLink="licenses/complimentary/issue" routerLinkActive="activebutton">{{'LAYOUT.NAV.ISSUE_COMPLIMENTARY_LICENSE'|translate}}</a></li>
            <li class="dropdown-item"><a class="nav-link" routerLink="licenses/complimentary" routerLinkActive="activebutton">{{'LAYOUT.NAV.COMPLIMENTARY_LICENSE_LIST'|translate}}</a></li>
          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle d-none d-md-block" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" routerLink="content" routerLinkActive="activebutton">
            {{'LAYOUT.NAV.MANAGE_CONTENT'|translate}}
          </a>
          <a class="nav-link dropdown-toggle d-block d-md-none" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [attr.data-back-label]="'LAYOUT.NAV.BACK' | translate">
            {{'LAYOUT.NAV.MANAGE_CONTENT'|translate}}
          </a>
          <ul class="dropdown-menu">
            <li class="dropdown-item d-block d-md-none"><a class="nav-link" routerLink="content" routerLinkActive="activebutton">{{'LAYOUT.NAV.MANAGE_CONTENT'|translate}}</a></li>
            <li class="dropdown-item"><a class="nav-link" routerLink="content/invitation" routerLinkActive="activebutton">{{'LAYOUT.NAV.MANAGE_INVITATION_EMAIL'|translate}}</a></li>
            <li class="dropdown-item"><a class="nav-link" routerLink="content/reminder" routerLinkActive="activebutton">{{'LAYOUT.NAV.MANAGE_REMINDER_EMAIL'|translate}}</a></li>
            <li class="dropdown-item"><a class="nav-link" routerLink="content/confirmation" routerLinkActive="activebutton">{{'LAYOUT.NAV.MANAGE_CONFIRMATION_EMAIL'|translate}}</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/logout']">{{'LAYOUT.NAV.LOG_OUT' | translate }}</a>
        </li>

      </ul>

    </div>
  </div>
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
