/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DistributionSummarySearch } from './distributionSummarySearch';
import { LicenseSearch } from './licenseSearch';


/**
 * Class UserLicenseDetailDto
 */
export interface UserLicenseDetail { 
    /**
     * ID of the User 
     */
    UserID: number;
    LicenseInformation?: LicenseSearch;
    DistributionList?: DistributionSummarySearch;
}

