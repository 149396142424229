import {Pipe, PipeTransform} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  constructor(
    private translatePipe: TranslatePipe
  ) {}

  public transform(value: any, yesText: string = 'COMMON.UI.YES', noText: string = 'COMMON.UI.NO'): string {
    const outText: string = value ? yesText : noText;
    if (outText.toLocaleUpperCase() === outText) {
      return this.translatePipe.transform(outText)
    }

    return outText
  }

}
