/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ViewDistributorParticipantsDto } from './viewDistributorParticipantsDto';


/**
 * Class DistributionSummaryDto
 */
export interface DistributionSummary { 
    ID: number;
    DistributorID: number;
    DistributionName: string;
    CreatedDate: string;
    SentDate?: string | null;
    SurveyID: number;
    SurveyName: string;
    QuestionCount: number;
    DistributionStatusID: number;
    DistributionStatus: string;
    IsSelfAssessment: boolean;
    ParticipantCount?: number;
    TotalAssessments: number;
    TotalSubmitted: number;
    TotalIncomplete: number;
    TotalNotStarted: number;
    Participants?: Array<ViewDistributorParticipantsDto>;
}

