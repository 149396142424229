/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmailType } from './emailType';
import { Localization } from './localization';


/**
 * Class EmailMessageSimpleDto
 */
export interface EmailMessageSimple { 
    ID?: number;
    Body?: string;
    SenderName?: string;
    SenderEmail?: string;
    EmailType?: EmailType;
    /**
     * The Localizations
     */
    Localizations?: Set<Localization>;
}

