<div>
  <section class="distribution-form">
    <form class="dark-theme" *ngIf="distributionForm" [formGroup]="distributionForm" autocomplete="off" novalidate>
      <fieldset>
        <legend>
          {{ 'DISTRIBUTOR.DISTRIBUTION_FORM.VIEW_DISTRIBUTION' |translate }}
        </legend>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="grid">
              <div class="col-12 form-field">
                <label for="Name">{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_NAME'|translate}}</label>
                <input pInputText maxlength="250" readonly type="text" formControlName="Name" id="Name"/>
              </div>
              <!-- @TODO - @note there are elements on this page which are only visible when all assessments have been completed -->
              <!-- @TODO - @note there are elements on this page which are only visible when all assessments have been completed -->
              <!-- @TODO - @note there are elements on this page which are only visible when all assessments have been completed -->
              <p-button type="button" *ngIf="isComplete" label="{{'COMMON.UI.CLOSE'|translate}}"></p-button>
            </div>
          </div>
          <div class="col-12 md:col-5 md:col-offset-1">
            <div class="box-wrap">
              <div class="form-field">
                <label>{{'DISTRIBUTOR.DISTRIBUTION_FORM.SURVEY'|translate}}</label>
                {{this.getSurveyName(this.distribution.Survey.ID)}}
              </div>
              <div class="form-field">
                <label>{{'DISTRIBUTOR.DISTRIBUTION_FORM.DATE_CREATED'|translate}}</label>
                {{this.distribution.CreatedDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a'}}
              </div>
              <div class="form-field">
                <label>{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_SENT'|translate}}</label>
                {{this.distribution.SentDate ? (this.distribution.SentDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a') : 'COMMON.UI.NO'|translate}}
              </div>
              <div class="form-field" style="margin-bottom: 0;">
                <label>{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_STATUS'|translate}}</label>
                {{this.distribution.DistributionStatus?.Name}}
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </section>

  <!-- Distribution Participant Summary -->
  <section class="distribution-participant-summary" *ngIf="distribution">
    <div class="stats">

      <!-- Number of Participant Invitations - Total number of participants in the assessment distribution -->
      <div class="total">
        <span class="label">{{'DISTRIBUTOR.DISTRIBUTION_DETAIL_PARTICIPANT_SUMMARY.STATISTICS_PARTICIPANT_COUNT'|translate}}</span>
        <span class="value">{{distribution.ParticipantSummary ? distribution.ParticipantSummary.ParticipantCount : 0}}</span>
      </div>

      <!-- Number of Completed assessments - Number of participants with completed assessments-->
      <div class="completed">
        <span class="label">{{'DISTRIBUTOR.DISTRIBUTION_DETAIL_PARTICIPANT_SUMMARY.STATISTICS_TOTAL_COMPLETED'|translate}}</span>
        <span class="value">{{distribution.ParticipantSummary ? distribution.ParticipantSummary.TotalSubmitted : 0}}</span>
      </div>

      <!-- Number of started but not completed assessments - Number of participants that have started but not submitted their assessment -->
      <div class="incomplete">
        <span class="label">{{'DISTRIBUTOR.DISTRIBUTION_DETAIL_PARTICIPANT_SUMMARY.STATISTICS_TOTAL_INCOMPLETE'|translate}}</span>
        <span class="value">{{distribution.ParticipantSummary ? distribution.ParticipantSummary?.TotalIncomplete : 0}}</span>
      </div>

      <!-- Number of not started assessments - Number of participants to not start their assessment -->
      <div class="not-started">
        <span class="label">{{'DISTRIBUTOR.DISTRIBUTION_DETAIL_PARTICIPANT_SUMMARY.STATISTICS_TOTAL_NOT_STARTED'|translate}}</span>
        <span class="value">{{distribution.ParticipantSummary ? distribution.ParticipantSummary.TotalNotStarted : 0}}</span>
      </div>

    </div>
  </section>

  <section class="distribution-participant-list">
    <div class="dark-theme">
      <fieldset>

        <legend>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.PARTICIPANTS'|translate}}</legend>

        <div *ngIf="filterFormGroup">
          <section class="search-filters">
            <form *ngIf="filterFormGroup" [formGroup]="filterFormGroup" autocomplete="off" novalidate>
              <fieldset>
                <!--                <legend>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.FILTER_PARTICIPANTS'|translate}}</legend>-->

                <div class="p-fluid">
                  <div class="grid">
                    <div class="col-12 md:col-6 form-field" [formGroup]="filterFormGroup">
                      <label for="AssessmentStatusID">{{'ADMIN.USER_DETAIL.USER_STATUS'|translate}}</label>
                      <p-dropdown id="AssessmentStatusID" [formGroup]="filterFormGroup" [options]="luAssessmentStatuses" optionLabel="Name"
                                  optionValue="ID" formControlName="AssessmentStatusID" appendTo="body"></p-dropdown>
                    </div>

                    <div class="col-12 md:col-6 form-field" [formGroup]="filterFormGroup">
                      <label for="SearchTerm">{{'COMMON.UI.SEARCH'|translate}}</label>
                      <input type="text" pInputText formControlName="SearchTerm" id="SearchTerm"/>
                    </div>
                  </div>
                </div>

                <!--                <p-button type="submit" label="{{'COMMON.UI.FILTER'|translate}}"></p-button>-->

              </fieldset>
            </form>
          </section>
        </div>

        <form class="dark-theme" *ngIf="distributionParticipantsForm" [formGroup]="distributionParticipantsForm" autocomplete="off" novalidate>

          <p-button *ngIf="showButtonResendInvitation && !isComplete" (onClick)="resendInvitations()">{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.RESEND_INVITATION'|translate}}</p-button>

          <div class="grid" *ngIf="filteredParticipantsFormArray">
            <div class="col-12">
              <table class="tbl-distribution-participant-list" formArrayName="faParticipants">
                <thead>
                <tr>
                  <th>#</th>
                  <th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.FIRST_NAME'|translate}}</th>
                  <th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.LAST_NAME'|translate}}</th>
                  <th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.EMAIL'|translate}}</th>
                  <!-- @note - LICENSE_CODE displays the AssessmentResponse's AccessToken -->
                  <!--<th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.LICENSE_CODE'|translate}}</th>-->
                  <th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.RECOVERY_LINK'|translate}}</th>
                  <th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.PARTICIPANT_STATUS_NAME'|translate}}</th>
                  <th>{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.QUESTIONS_ANSWERED'|translate}}</th>
                  <th *ngIf="!isComplete">{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.RESEND_INVITATION'|translate}}</th>
                  <th></th>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let formGroup of filteredParticipantsFormArray.controls; let $index = index" [formGroupName]="$index">
                  <td [attr.data-heading-1]="'#'">
                    {{$index + 1}}
                  </td>

                  <td [attr.data-heading-2]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.FIRST_NAME'|translate">
                    <div class="form-field">
                      <input readonly class='read-only' placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.FIRST_NAME'|translate}}" type="text" pInputText formControlName="FirstName"/>
                      <span class="display-txt">{{formGroup.get('FirstName')?.value}}</span>

                    </div>
                  </td>

                  <td [attr.data-heading-3]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.LAST_NAME'|translate">
                    <div class="form-field">
                      <input readonly class='read-only' placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.LAST_NAME'|translate}}" type="text" pInputText formControlName="LastName"/>
                      <span class="display-txt">{{formGroup.get('LastName')?.value}}</span>
                    </div>
                  </td>

                  <td [attr.data-heading-4]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.EMAIL'|translate">
                    <div class="form-field">
                      <input [ngClass]="{ 'read-only': isFieldDisabled(formGroup, 'Email') }" placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.EMAIL'|translate}}" type="email" pInputText formControlName="Email"
                             [disabled]="!!isFieldDisabled(formGroup, 'Email')"/>
                      <span class="display-txt">{{formGroup.get('Email')?.value}}</span>
                      <p-button class="btn-txt btn-cancel" *ngIf="!isFieldDisabled(formGroup, 'Email')" (onClick)="editParticipantCancel(formGroup)">Cancel</p-button>
                      <p-button class="btn-txt btn-save" *ngIf="!isFieldDisabled(formGroup, 'Email')" (onClick)="editParticipantSave(formGroup)">Save</p-button>
                      <p-button class="btn-txt btn-edit" *ngIf="isFieldDisabled(formGroup, 'Email')" (onClick)="editParticipant(formGroup)">{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.EDIT_EMAIL'|translate}}</p-button>
                    </div>
                  </td>

                  <!--<td [attr.data-heading-5]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.LICENSE_CODE'|translate">-->
                  <td [attr.data-heading-5]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.RECOVERY_LINK'|translate">
                    <div class="form-field">
                      <!--<input readonly class="read-only" placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.LICENSE_CODE'|translate}}" type="text" pInputText formControlName="AccessToken"/>-->
                      <input readonly class="read-only" placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.RECOVERY_LINK'|translate}}" type="text" pInputText formControlName="AccessToken"/>
                      <span class="display-txt">{{formGroup.get('AccessToken')?.value}}</span>
                      <p-button class="btn-txt btn-copy" (onClick)="copyAccessLinkToClipboard(formGroup)">{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.COPY_LINK'|translate}}</p-button>
                    </div>
                  </td>

                  <td [attr.data-heading-6]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.PARTICIPANT_STATUS_NAME'|translate">
                    <div class="form-field">
                      <input readonly class="read-only" placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.PARTICIPANT_STATUS_NAME'|translate}}" type="text" pInputText formControlName="AssessmentStatus"/>
                    </div>
                  </td>

                  <td [attr.data-heading-7]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.QUESTIONS_ANSWERED'|translate">
                    <div class="form-field">
                      <input readonly class="read-only" placeholder="{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.QUESTIONS_ANSWERED'|translate}}" type="text" pInputText formControlName="QuestionsAnswered"/>
                    </div>
                  </td>

                  <td [attr.data-heading-8]="'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.RESEND_INVITATION'|translate" *ngIf="!isComplete">
                    <p-checkbox [binary]=true (onChange)="toggleResendEmailButton()" formControlName="Resend" [value]="formGroup.get('ID')?.value"></p-checkbox>
                  </td>

                  <td>
                    <p-button *ngIf="distribution.CanViewResults && isComplete" routerLink="view-report/ASSESSMENT_ID">{{'DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.VIEW_REPORT'|translate}}</p-button>
                  </td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>

        </form>

      </fieldset>
    </div>

  </section>
</div>
