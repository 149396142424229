/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserLicenseSummary } from './userLicenseSummary';


export interface LicenseSearchAllOf { 
    /**
     * ID of the User Status to filter by
     */
    UserStatus?: number | null;
    /**
     * ID of the User to filter by (used internally for the detail page)
     */
    UserID?: number | null;
    /**
     * ID of the Survey to filter by (used internally altering license counts)
     */
    SurveyID?: number | null;
    /**
     * Allows filtering the list down to only rows with licenses available
     */
    AvailableOnly?: boolean;
    /**
     * Search terms
     */
    SearchTerm?: string | null;
    /**
     * $items *_/
     */
    items?: Set<UserLicenseSummary>;
}

