import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DistributionSummary, ViewDistributorParticipantsDto } from "src/app/core/api";
import { ParticipantsService } from "src/app/service/participants.service";

@Component({
  selector: 'app-participant-list',
  templateUrl: './participant-list.component.html'
})
export class ParticipantList implements OnInit {
  //#region variables

  public participants?: ViewDistributorParticipantsDto[]

  //#endregion

  //#region properties

  @Input() distribution!: DistributionSummary
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter()
  @Output() onLoaded: EventEmitter<void> = new EventEmitter()
  @Output() onLoading: EventEmitter<void> = new EventEmitter()

  //#endregion

  //#region constructor

  public constructor(
    private participantsService: ParticipantsService
  ) {
  }

  //#endregion

  //#region event handlers

  public ngOnInit(): void {
    this.loadParticipants()
  }

  //#endregion

  //#region business logic

  private loadParticipants() {
    this.onLoading.emit()
    this.participantsService.listDistributionParticipants(this.distribution.ID).subscribe({
      next: (participants: any) => {
        this.participants = participants
        this.onLoaded.emit()
      },
      error: (error: HttpErrorResponse) => {
        this.onError.emit(error)
      }
    })
  }

  //#endregion
}
