<div>
  <h1>
    {{'ADMIN.QUESTION_DETAIL.HEADING'|translate}}
  </h1>
  <p [innerHTML]="'ADMIN.QUESTION_DETAIL.INSTRUCTIONS'|translate"></p>
  <section class="dark-theme">
    <app-localization-toggle (onToggled)="onLanguageToggled($event)"></app-localization-toggle>
    <form *ngIf="questionForm" (submit)="submitQuestion()" [formGroup]="questionForm" autocomplete="off" novalidate>
      <fieldset>
        <div class="p-fluid">
          <div class="grid">
            <div class="col-6">

              <!-- Dimension -->
              <div class="form-field">
                <label for="DimensionID">{{'ADMIN.QUESTION_DETAIL.DIMENSION_TEXT'|translate}}</label>
                <p-dropdown id="DimensionID"
                            [formGroup]="questionForm"
                            [options]="luDimensions"
                            optionLabel="Name"
                            optionValue="ID"
                            formControlName="dimensionID"
                            placeholder="{{'ADMIN.QUESTION_DETAIL.DIMENSION_TEXT'|translate}}">
                </p-dropdown>
                <app-validation-error fieldName="themeID" [formGroup]="questionForm" validatorName="required">{{'ADMIN.QUESTION_DETAIL.ERROR_DIMENSION'|translate}}</app-validation-error>
              </div>

              <!-- Question Text -->
              <div class="form-field">
                <app-localization-input-text controlName="text"
                                             displayName="{{'ADMIN.QUESTION_DETAIL.QUESTION_TEXT'|translate}}"
                                             [formGroup]="questionForm"
                                             [maxLength]="255"
                                             [required]="true">
                </app-localization-input-text>
              </div>

              <!-- Theme -->
              <div *ngIf="!isFortyQuestionSurvey" class="form-field">
                <label for="ThemeID">{{'ADMIN.QUESTION_DETAIL.THEME'|translate}}</label>
                <p-dropdown id="ThemeID"
                            [formGroup]="questionForm"
                            [options]="luThemesFiltered"
                            optionLabel="Name"
                            optionValue="ID"
                            formControlName="themeID"
                            placeholder="{{'ADMIN.QUESTION_DETAIL.THEME'|translate}}">
                </p-dropdown>
                <app-validation-error fieldName="themeID" [formGroup]="questionForm" validatorName="required">{{'ADMIN.QUESTION_DETAIL.ERROR_THEME'|translate}}</app-validation-error>
              </div>

              <!-- Scoring Parameter -->
              <div *ngIf="!isFortyQuestionSurvey" class="form-field">
                <label for="scoringParameter">{{'ADMIN.QUESTION_DETAIL.SCORING_PARAMETER'|translate}}</label>
                <p-dropdown id="ThemeID"
                            [formGroup]="questionForm"
                            [options]="luScoringParameters"
                            [readonly]="isFortyQuestionSurvey"
                            optionLabel="Name"
                            optionValue="ID"
                            formControlName="scoringParameter"
                            placeholder="{{'ADMIN.QUESTION_DETAIL.SCORING_PARAMETER'|translate}}">
                </p-dropdown>
                <app-validation-error fieldName="scoringParameter" [formGroup]="questionForm" validatorName="required">{{'ADMIN.QUESTION_DETAIL.ERROR_SCORING_PARAMETER'|translate}}</app-validation-error>
              </div>
            </div>
            <!--
            Answer Themes only display for the 40 Question survey
            in fact for any Survey that uses 'Sum' as its Calculation method
            -->
            <div class="col-6" formArrayName="questionAnswers">
              <ng-container *ngFor="let formGroup of formArrayQuestionAnswers.controls; let $index = index" [formGroupName]="$index">

                <div class="form-field">
                  <app-localization-input-text controlName="text"
                                               displayName="{{'ADMIN.QUESTION_DETAIL.ANSWER_TEXT'|translate}}"
                                               [formGroup]="returnFormGroup(formGroup)"
                                               [maxLength]="255"
                                               [required]="true">
                  </app-localization-input-text>
                </div>

                <!-- Answer Theme - individually assigned on 40 question survey -->
                <div *ngIf="isFortyQuestionSurvey" class="form-field">
                  <label for="ThemeID">{{'ADMIN.QUESTION_DETAIL.THEME'|translate}}</label>
                  <p-dropdown id="ThemeID"
                              [options]="luThemesFiltered"
                              optionLabel="Name"
                              optionValue="ID"
                              formControlName="themeID"
                              placeholder="{{'ADMIN.QUESTION_DETAIL.THEME'|translate}}">
                  </p-dropdown>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </fieldset>
      <div class="buttons">
        <p-button type="submit" label="{{'COMMON.UI.SAVE'|translate}}"></p-button>
        <p-button type="button" label="{{'COMMON.UI.CANCEL'|translate}}" (click)="clickCancel()"></p-button>
      </div>
    </form>
  </section>
</div>
