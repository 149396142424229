/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserMinimalDto } from './userMinimalDto';


/**
 * Class ViewQuestionsDto
 */
export interface ViewQuestionsDto { 
    UID?: number;
    SurveyID?: number;
    LanguageID?: number;
    DimensionID?: number;
    DimensionName?: string;
    DimensionText?: string;
    QuestionID?: number;
    QuestionText?: string;
    ThemeID1?: number | null;
    ThemeID2?: number | null;
    ThemeName1?: string | null;
    ThemeName2?: string | null;
    ScoringParameter?: number;
    SortOrder?: number;
    UpdatedDate?: string;
    UpdatedBy?: UserMinimalDto;
}

