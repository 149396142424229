import { Injectable } from '@angular/core'
import { Language } from '../core/api'
import { LanguageEnum } from '../enums/language.enum'

@Injectable()
export class LanguagesService {
  // variables

  private languages: any[] = null!

  // constructors

  constructor() {
    this.languages = [
      { Code: 'EN', ID: LanguageEnum.ENGLISH, Name: 'English' },
      { Code: 'FR', ID: LanguageEnum.FRENCH, Name: 'French' }
    ]
  }

  // business logic

  public returnLanguages(): Language[] {
    return this.languages
  }
}
