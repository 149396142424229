import { Route } from "@angular/router";
import { ConfirmationEmailDetailComponent } from "../components/confirmation-email-detail/confirmation-email-detail.component";
import { InvitationEmailDetailComponent } from "../components/invitation-email-detail/invitation-email-detail.component";
import { ReminderEmailDetailComponent } from "../components/reminder-email-detail/reminder-email-detail.component";
import { EnumUserRoles } from "../core/api";
import { UserAuthGuard } from "../guards/user-auth.guard";
import { DistributorLayoutComponent } from "../layout/distributor-layout/distributor-layout.component";
import { DashboardDistComponent } from "./dashboard-dist/dashboard-dist.component";
import { DistributionDetailComponent } from "./distribution-detail/distribution-detail.component";
import { DistributionViewComponent } from "./distribution-view/distribution-view.component";
import { DistributorComponent } from "./distributor.component";
import { ReminderDetailComponent } from "./reminder-detail/reminder-detail.component";
import { RemindersComponent } from "./reminders/reminders.component";

export const appDistributorRoutes: Route[] = [
  {
    path: '',
    component: DistributorLayoutComponent,
    canActivate: [UserAuthGuard],
    data: {role: EnumUserRoles.Distributor},
    // Distributor child route paths
    children: [
      {
        path: '',
        component: DistributorComponent,
        // Distributor child route paths
        children: [
          {
            path: 'dashboard',
            component: DashboardDistComponent,
          },
          {
            path: 'distributions/:distributionID/reminders',
            component: RemindersComponent,
          },
          {
            path: 'distributions/:distributionID/reminders/create',
            component: ReminderDetailComponent,
          },
          {
            path: 'distributions/:distributionID/reminders/edit/:reminderID',
            component: ReminderDetailComponent,
          },
          {
            path: 'distributions/edit/:distributionID',
            component: DistributionDetailComponent,
          },
          /**
           * Assessment Distribution Details
           * Distributions with a status of Sent and Closed will have an option to view Distribution Details.
           * This option will allow the Admin to see general details as well as specific participant details.
           */
          {
            path: 'distributions/view/:distributionID',
            // component: DistributionDetailComponent,
            component: DistributionViewComponent,
          },
          {
            path: 'content/confirmation',
            component: ConfirmationEmailDetailComponent,
            data: { isAdmin: false }
          },
          {
            path: 'content/invitation',
            component: InvitationEmailDetailComponent,
            data: {isAdmin: false},
          },
          {
            path: 'content/reminder',
            component: ReminderEmailDetailComponent,
            data: { isAdmin: false }
          },
        ]
      },
    ]
  }
]
