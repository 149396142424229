import { Component, EventEmitter, Output } from '@angular/core'
import { Language } from '../../../core/api'
import { LanguagesService } from '../../../service/languages.service'

@Component({
  selector: 'app-localization-toggle',
  styleUrls: ['./localization-toggle.component.css'],
  templateUrl: './localization-toggle.component.html'
})
export class LocalizationToggleComponent {
  // variables

  public currentLanguageID: number = 1
  public languages: Language[] = null!

  // properties

  @Output() onToggled: EventEmitter<any> = new EventEmitter()

  get orderedLanguages(): Language[] {
    if (null == this.languages) {
      return []
    }

    if (1 == this.languages.length) {
      return []
    }

    return this.languages.sort((a, b) => a.Code < b.Code ? 1 : a.Code === b.Code ? 0 : -1)
  }

  // constructors

  constructor(private languagesService: LanguagesService) {
    this.languages = this.languagesService.returnLanguages()
  }

  // event handlers

  public toggleAllLanguages(languageID: number) {
    this.currentLanguageID = languageID

    this.onToggled.emit(languageID)
  }
}
