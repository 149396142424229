/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class ComplimentaryLicenseDto
 */
export interface ComplimentaryLicenseCreate { 
    /**
     * ID of the Survey for the complimentary license
     */
    SurveyID: number;
    /**
     * First name of the user for the complimentary license
     */
    FirstName: string;
    /**
     * Email of the user for the complimentary license
     */
    Email: string;
}

