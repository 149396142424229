import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { BaseComponent } from "../../components/base/base.component";
import { AdminService, DistributionSummary, JsonResponse, UserLicenseDetail } from "../../core/api";
import { AuthService } from "../../service/auth.service";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-license-detail',
  templateUrl: './license-detail.component.html',
})
export class LicenseDetailComponent extends BaseComponent implements OnInit {
  //#region variables

  private _userLicenseDetail!: UserLicenseDetail
  private expandedDistribution?: number
  private userID!: number;

  //#endregion

  //#region properties

  get userLicenseDetail(): UserLicenseDetail {
    return this._userLicenseDetail;
  }

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    public authService: AuthService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickExpandDistribution(d: DistributionSummary) {
    this.expandedDistribution = d.ID
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['licenseDetails'])
    this.processManagerService.unforce()

    this.route.params.subscribe((params: Params) => {
        this.userID = parseInt(params['userID'])
        this.loadUserLicenseDetail()
      }
    )
  }

  public onError($event: any) {
    this.handleApiException($event)
  }

  public onLoaded() {
    this.processManagerService.unforce()
  }

  public onLoading() {
    this.processManagerService.force()
  }

  //#endregion

  //#region business logic

  public isExpanded(d: DistributionSummary): boolean {
    return (!!this.expandedDistribution) && (this.expandedDistribution === d.ID)
  }

  private loadUserLicenseDetail() {
    this.processManagerService.force()
    this.adminService.listLicenseUserDetail(this.userID).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this._userLicenseDetail = response.data
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  //#endregion

}
