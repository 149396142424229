import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BaseComponent } from "../../components/base/base.component";
import { AdminService, JsonResponse, Score, ScoringMatrix } from '../../core/api';
import { AppTranslatePipe } from '../../pipes/app-translate.pipe';
import { FormValidationService } from '../../service/form-validation.service';
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-scoring-matrix',
  templateUrl: './scoring-matrix.component.html'
})
export class ScoringMatrixComponent extends BaseComponent implements OnInit {
  //#region variables

  private scoringMatrix: ScoringMatrix = null!
  public scoringMatrixForm: FormGroup = null!
  public surveyID: number = null!

  //#endregion

  //#region properties

  get formArrayRows(): FormArray {
    return this.scoringMatrixForm.get('rows') as FormArray
  }

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    private appTranslatePipe: AppTranslatePipe,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickCancel() {
    this.router.navigate(['/admin', 'assessments', 'scoring'])
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['scoringMatrix'])
    this.processManagerService.unforce()

    this.route.params.subscribe((params: Params) => {
      if (params['surveyID']) {
        this.surveyID = parseInt(params['surveyID'])
      }

      if (this.surveyID != null) {
        this.loadScoringMatrix()
      }
    })
  }

  public submitScoringMatrix() {
    this.saveScoringMatrix()
  }

  //#endregion

  //#region business logic

  private initializeForm() {
    this.scoringMatrixForm = this.formBuilder.group({
      rows: this.formBuilder.array([])
    })

    let negativeScores: Score[] = Array.from(this.scoringMatrix.NegativeScores!)
    let positiveScores: Score[] = Array.from(this.scoringMatrix.PositiveScores!)
    for (var i: number = 0; i < negativeScores.length; i++) {
      let negativeScore: Score = negativeScores[i]
      let positiveScore: Score = positiveScores[i]
      let formGroup: FormGroup = this.formBuilder.group({
        name: ['Score' + (i + 1), []],
        negativeScore: [negativeScore.Score, [Validators.required]],
        negativeScoreID: [negativeScore.ID, []],
        positiveScore: [positiveScore.Score, [Validators.required]],
        positiveScoreID: [positiveScore.ID, []],
      })
      this.formArrayRows.push(formGroup)
    }
  }

  private loadScoringMatrix() {
    this.adminService.editScoringMatrix(this.surveyID).subscribe({
      complete: () => this.processManagerService.notify('scoringMatrix'),
      next: (response: JsonResponse) => {
        this.scoringMatrix = response.data

        this.initializeForm()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public returnFormGroup(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup
  }

  private saveScoringMatrix() {
    if (this.scoringMatrixForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.scoringMatrixForm)
      return
    }

    let negativeScores: Score[] = Array.from(this.scoringMatrix.NegativeScores!)
    let positiveScores: Score[] = Array.from(this.scoringMatrix.PositiveScores!)
    for (var i: number = 0; i < this.formArrayRows.controls.length; i++) {
      let formGroup: FormGroup = this.formArrayRows.controls[i] as FormGroup
      let negativeScoreID: number = formGroup.get('negativeScoreID')?.value
      let positiveScoreID: number = formGroup.get('positiveScoreID')?.value
      let negativeScore: Score = negativeScores.find(a => a.ID == negativeScoreID)!
      let positiveScore: Score = positiveScores.find(a => a.ID == positiveScoreID)!
      negativeScore.Score = formGroup.get('negativeScore')?.value
      positiveScore.Score = formGroup.get('positiveScore')?.value
    }

    this.processManagerService.force()
    this.adminService.modifyScoringMatrix(this.scoringMatrix).subscribe({
      complete: () => this.processManagerService.notify('scoringMatrix'),
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('ADMIN.SCORING_MATRIX.SAVED') })

        this.router.navigate(['/admin', 'assessments', 'scoring'])
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  //#endregion
}
