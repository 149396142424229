import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslatePipe } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { BaseComponent } from "../components/base/base.component";
import { GuestService, JsonResponse, UserForgottenPasswordDto } from "../core/api";
import { FormValidationService } from "../service/form-validation.service";
import { ProcessManagerService } from "../service/process-manager.service";
import { PasswordMatchValidator } from "../validators/password-match.validator";
import { PasswordStrengthValidator } from "../validators/password-strength.validator";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  //#region Properties

  public forgotPasswordForm: FormGroup = null!;
  public passwordResetToken: string = null!;
  public resetPasswordForm: FormGroup = null!;

  //#endregion

  //#region constructor

  constructor(
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private guestService: GuestService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['password'])
    this.processManagerService.unforce()

    this.route.params.subscribe((params: Params) => {
      if ("undefined" !== typeof(params['token'])) {
        this.passwordResetToken = params['token']
      }

      if (this.passwordResetToken != null) {
        this.loadPasswordReset()
      }
      else {
        this.initializeForgotPasswordForm()
      }
    })
  }

  public submitForgotPassword() {
    this.forgotPassword()
  }

  public submitResetPassword() {
    this.resetPassword()
  }

  //#endregion

  //#region constructor

  private forgotPassword() {
    if (this.forgotPasswordForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.forgotPasswordForm)
      return
    }

    const userForgottenPasswordDto: UserForgottenPasswordDto = {
      Email: this.forgotPasswordForm.get('email')?.value
    }

    this.processManagerService.force()
    this.guestService.forgotPassword(userForgottenPasswordDto).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('GUEST.LOGIN.FORGOT_PASSWORD_EMAIL_SENT') })

        this.forgotPasswordForm.get('email')?.setValue(null)
        this.forgotPasswordForm.markAsPristine()
        this.forgotPasswordForm.markAsUntouched()
        this.forgotPasswordForm.updateValueAndValidity()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  private initializeForgotPasswordForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
    });
  }

  private initializeResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      password: [null, [Validators.required, PasswordStrengthValidator(8)]],
      passwordConfirm: [null, [Validators.required]],
    });

    this.resetPasswordForm.setValidators([PasswordMatchValidator])
  }

  private loadPasswordReset() {
    const userForgottenPasswordDto: UserForgottenPasswordDto = {
      Token: this.passwordResetToken
    }

    this.processManagerService.force()
    this.guestService.resetPassword(userForgottenPasswordDto).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.initializeResetPasswordForm()
      },
      error: (error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.initializeForgotPasswordForm()
        }

        this.handleApiException(error)
      }
    })
  }

  private resetPassword() {
    if (this.resetPasswordForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.resetPasswordForm)
      return
    }

    const userForgottenPasswordDto: UserForgottenPasswordDto = {
      Password: this.resetPasswordForm.get('password')?.value,
      Token: this.passwordResetToken
    }

    this.processManagerService.force()
    this.guestService.changePassword(userForgottenPasswordDto).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('GUEST.LOGIN.PASSWORD_RESET') })

        this.processManagerService.unforce()

        setTimeout(() => {
          this.router.navigate(['/login'])
        }, 250)
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  //#endregion
}
