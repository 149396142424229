/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SurveyDto } from './surveyDto';
import { UserMinimalDto } from './userMinimalDto';
import { ContentBlock } from './contentBlock';
import { AssessmentAnswer } from './assessmentAnswer';


/**
 * Class AssessmentResponseDto
 */
export interface AssessmentResponse { 
    /**
     * ID of the AssessmentResponse
     */
    ID?: number | null;
    CreatedBy?: UserMinimalDto | null;
    CreatedDate?: string | null;
    Survey?: SurveyDto | null;
    SubmittedDate?: string | null;
    /**
     * Assessment Answers
     */
    AssessmentAnswer?: Array<AssessmentAnswer>;
    /**
     * The confirmation text the user sees upon completion of their assessment. This comes from the database and is editable by admins
     */
    ConfirmationText?: ContentBlock | null;
}

