/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserSearchAllOf } from './userSearchAllOf';
import { Search } from './search';
import { UserDto } from './userDto';


/**
 * Class UserSearchDto
 */
export interface UserSearch { 
    /**
     * Number of items per page requested/returned
     */
    perPage?: number;
    /**
     * Page number requested/returned
     */
    page?: number;
    /**
     * The total number of items in all pages
     */
    totalItems?: number;
    /**
     * The total number of pages
     */
    pageCount?: number;
    /**
     * The sort order for the results - used/implemented internally only
     */
    sort?: string;
    /**
     * ID of the UserRole to filter by
     */
    UserRole?: number | null;
    /**
     * ID of the User Status to filter by
     */
    UserStatus?: number | null;
    /**
     * Search terms
     */
    SearchTerm?: string | null;
    /**
     * $items *_/
     */
    items?: Set<UserDto>;
}

