import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'
import { Language } from '../../../core/api'
import { LanguagesService } from '../../../service/languages.service'

@Component({
  selector: 'app-localization-input-base',
  template: ''
})
export class LocalizationInputBaseComponent {
  // variables

  public fieldName: string = ''
  public languages: Language[] = null!
  private randomID: number = 0
  public requiredErrorMessage: string = ''

  // properties

  @Input() controlName: string = null!
  @Input() displayName: string = null!
  @Input() formGroup: FormGroup = null!
  @Input() index: number = null!
  @Input() maxLength: number = null!
  @Input() required: boolean = false
  @Output() onUpdate: EventEmitter<void> = new EventEmitter()
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef = null!

  public get displayLanguageToggle(): boolean {
    if (null == this.languages) {
      return false
    }
    return this.languages.length > 1
  }

  public get itemFormGroup(): FormGroup {
    return this.formGroup.get(this.controlName) as FormGroup
  }

  public get itemLanguageID(): number {
    return this.itemFormGroup.get('languageID')?.value
  }

  public get itemLocalizations(): FormArray {
    return this.itemFormGroup.get('localizations') as FormArray
  }

  public get itemValue(): string {
    return this.itemFormGroup.get('value')?.value
  }

  public get orderedLanguages(): Language[] {
    if (null == this.languages) {
      return []
    }

    return this.languages.sort((a, b) => a.Code < b.Code ? 1 : a.Code === b.Code ? 0 : -1)
  }

  public get uniqueControlName(): string {
    return this.controlName + '-' + this.randomID.toString()
  }

  // constructors

  constructor(protected languagesService: LanguagesService) {
    this.languages = this.languagesService.returnLanguages()
    this.randomID = Math.floor(Math.random() * Math.floor(999999) + 1000000)
  }

  // event handlers

  public blurUpdateValue() {
    this.update()

    if (this.onUpdate) {
      this.onUpdate.emit()
    }
  }

  public clickToggleLanguage(languageID: number) {
    this.toggleLanguage(languageID, true)
  }

  public ngOnInit() {
    this.fieldName = this.controlName + '.value'
    this.requiredErrorMessage = 'Please enter the ' + this.displayName.toLowerCase()
  }

  // business logic

  public focus() {
    this.inputElement.nativeElement.focus()
  }

  public isCurrentLanguage(language: Language): boolean {
    return this.itemLanguageID == language.ID
  }

  public isDisabled(): boolean {
    return this.itemFormGroup.disabled
  }

  public returnCssClasses(language: Language): string {
    var cssClasses: string[] = []
    let currentLocalization: FormGroup = this.returnLocalization(language.ID)

    if ((undefined != currentLocalization) && (null != currentLocalization.get('value')?.value)) {
      cssClasses.push('has-value')
    }
    else {
      cssClasses.push('no-value')
    }

    if (currentLocalization.invalid && currentLocalization.dirty) {
      cssClasses.push('invalid')
    }

    return cssClasses.join(' ')
  }

  private returnLocalization(languageID: number): FormGroup {
    return this.itemLocalizations.controls.find(i => i.get('languageID')?.value == languageID) as FormGroup
  }

  public toggle(languageID: number) {
    this.toggleLanguage(languageID, false)
  }

  private toggleLanguage(languageID: number, setFocus: boolean) {
    if (this.itemLanguageID != languageID) {
      let currentValue: string = this.itemValue

      if ('' == currentValue) {
        currentValue = null!
      }

      let currentLocalization: FormGroup = this.returnLocalization(this.itemLanguageID)
      currentLocalization.get('value')?.setValue(currentValue)

      let newLocalization: FormGroup = this.returnLocalization(languageID)
      this.itemFormGroup.get('value')?.setValue(newLocalization.get('value')?.value)
      this.itemFormGroup.get('value')?.markAsPristine()
      this.itemFormGroup.get('value')?.markAsUntouched()
      this.itemFormGroup.get('languageID')?.setValue(languageID)
    }

    if (setFocus && this.inputElement) {
      this.inputElement.nativeElement.focus()
    }
  }

  public update() {
    let currentValue: string = this.itemValue
    let currentLocalization: FormGroup = this.returnLocalization(this.itemLanguageID)
    if ('' == currentValue) {
      currentValue = null!
    }
    currentLocalization.get('value')?.setValue(currentValue)
  }
}
