import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../components/base/base.component";
import { NavigationStart, Router } from '@angular/router';
import {ProcessManagerService} from "../service/process-manager.service";

@Component({
  selector: 'app-distributor',
  templateUrl: './distributor.component.html',
  // styleUrls: ['./distributor.component.css']
})
export class DistributorComponent extends BaseComponent implements OnInit {
  //#region Properties
  public menuActive: boolean = false
  //#endregion

  //#region constructor
  constructor(
    processManagerService: ProcessManagerService,
    router: Router
  ) {
    super(processManagerService)

    router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.menuActive = false
        }
      }
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  //#endregion

  //#region event handlers
  public toggleTabMenu() {
    this.menuActive = !this.menuActive
  }
  //#endregion

  //#region business logic
  //#endregion

  //#region internals
  //#endregion

}
