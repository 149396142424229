import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from "../app-routing.module";
import { AppComponentsModule } from "../components/app-components.module";
import { PipesModule } from '../pipes/pipes.module';
import { PrimeNgModule } from "../prime-ng-module";
import { DashboardDistComponent } from "./dashboard-dist/dashboard-dist.component";
import { DistributionDetailComponent } from "./distribution-detail/distribution-detail.component";
import { DistributionViewComponent } from "./distribution-view/distribution-view.component";
import { DistributorComponent } from "./distributor.component";
import { ReminderDetailComponent } from './reminder-detail/reminder-detail.component';
import { RemindersComponent } from './reminders/reminders.component';

@NgModule({
  declarations: [
    DashboardDistComponent,
    DistributionDetailComponent,
    DistributionViewComponent,
    DistributorComponent,
    ReminderDetailComponent,
    RemindersComponent,
  ],
  imports: [
    AppComponentsModule,
    AppRoutingModule,
    CommonModule,
    MomentModule,
    PipesModule,
    PrimeNgModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  exports: [
    DashboardDistComponent,
    DistributionDetailComponent,
    DistributionViewComponent,
    DistributorComponent,
    ReminderDetailComponent,
    RemindersComponent,
  ]
})
export class DistributorModule {
}
