/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LookupDto } from './lookupDto';


/**
 * Class LicenseSimpleDto
 */
export interface LicenseSimple { 
    /**
     * The Read-Only ID of the License
     */
    ID: number;
    /**
     * The code for this License
     */
    LicenseCode: string;
    DateUsed?: string;
    LicenseStatus: LookupDto;
}

