import { AssessmentResponse, ContentBlock, ParticipantDto, QuestionSimple } from "../core/api";

export class UserAssessment {
  public Assessment: AssessmentResponse = null!
  public CurrentQuestionID: number = null!
  public DimensionAnswers: any = null!
  public Dimensions: any = null!
  public Participant: ParticipantDto = null!
  public ProfileCompleted: boolean = false
  public QuestionAnswers: any = null!
  public Questions: QuestionSimple[] = null!
  public WelcomeScreenText: ContentBlock = null!
}
