import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslatePipe} from "@ngx-translate/core";
import {MessageService} from "primeng/api";
import {BaseComponent} from "../../components/base/base.component";
import {LocalizationInputBaseComponent} from "../../components/controls/localization-input-base/localization-input-base.component";
import {AdminService, Dimension, DimensionEdit, JsonResponse, Language, Localization, LookupService, QuestionAnswer, QuestionEdit, Theme} from "../../core/api";
import {AppTranslatePipe} from "../../pipes/app-translate.pipe";
import {AuthService} from "../../service/auth.service";
import {FormValidationService} from "../../service/form-validation.service";
import {LanguagesService} from "../../service/languages.service";
import {LocalizationService} from "../../service/localization.service";
import {ProcessManagerService} from "../../service/process-manager.service";

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html'
})
export class QuestionDetailComponent extends BaseComponent implements OnInit {
  //#region variables

  public languages: Language[] = null!
  private dimensions!: DimensionEdit[];
  public luDimensions!: { ID: number | undefined, Name: string, ThemeGroupID: number | undefined }[]
  public luScoringParameters: any[] = null!
  private themes: Theme[] = [];
  public luThemes: any[] = []

  public question: QuestionEdit = null!
  public questionForm: FormGroup = null!
  private questionID: number = null!

  public get isFortyQuestionSurvey(): boolean {
    return 'Sum' === this.question.Survey?.CalculationMethod
  }

  public get luThemesFiltered(): any[] {
    let rv = []
    if (this.dimensions && this.luThemes) {
      let currentDimensionID: number = this.questionForm.get('dimensionID')?.value;
      let dimension: any = this.dimensions.find(d => currentDimensionID === d.ID);
      let rv = this.luThemes.filter(t => dimension.ThemeGroup.ID === t.ThemeGroupID);
      return rv
    }
    return rv

  }

  //#endregion

  //#region properties

  @ViewChildren(LocalizationInputBaseComponent) localizationInputElements: QueryList<LocalizationInputBaseComponent> = null!

  get formArrayQuestionAnswers(): FormArray {
    return this.questionForm.get('questionAnswers') as FormArray
  }

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    private appTranslatePipe: AppTranslatePipe,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private languagesService: LanguagesService,
    private localizationService: LocalizationService,
    private lookupService: LookupService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickCancel() {
    this.router.navigate(['/admin', 'assessments', 'questions'])
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['dimensions', 'question', 'themes'])
    this.processManagerService.unforce()

    this.languages = this.languagesService.returnLanguages()

    this.route.params.subscribe((params: Params) => {
      if (params['questionID']) {
        this.questionID = parseInt(params['questionID'])
      }

      if (null !== this.questionID) {
        this.loadQuestion()
      }
    })
  }

  public onLanguageToggled(languageID: number) {
    this.localizationInputElements.forEach(c => c.toggle(languageID))
  }

  public submitQuestion() {
    this.saveQuestion()
  }

  //#endregion

  //#region business logic

  private initializeForm() {
    if (!(this.dimensions && this.themes)) {
      return;
    }
    let localizations: Localization[] = Object.entries(this.question.Localizations!).map(o => o[1]) as Localization[]

    this.questionForm = this.formBuilder.group({
      dimensionID: [this.question.Dimension?.ID, this.isFortyQuestionSurvey ? [] : [Validators.required]],
      questionAnswers: this.formBuilder.array([]),
      scoringParameter: [this.question.ScoringParameter, [Validators.required]],
      text: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Text', true, 255, this.languages, localizations),
      themeID: [this.question.Theme?.ID, this.isFortyQuestionSurvey ? [] : [Validators.required]],
    })

    let questionAnswers: QuestionAnswer[] = Array.from(this.question.QuestionAnswer)
    for (let questionAnswer of questionAnswers) {
      localizations = Object.entries(questionAnswer.DimensionAnswer.Localizations!).map(o => o[1]) as Localization[]
      let formGroup: FormGroup = this.formBuilder.group({
        id: [questionAnswer.ID, []],
        text: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Text', false, 255, this.languages, localizations),
        themeID: [questionAnswer.Theme.ID, this.isFortyQuestionSurvey ? [Validators.required] : []]
      });
      this.formArrayQuestionAnswers.push(formGroup)
    }
  }

  private loadDimensions() {
    this.lookupService.listDimensions(this.question.Survey!.ID).subscribe({
        complete: () => this.processManagerService.notify('dimensions'),
        next: (response: JsonResponse) => {
          this.dimensions = Object.keys(response.data).map(key => {
            let entity: DimensionEdit = response.data[key]
            return entity
          });
          this.luDimensions = Object.keys(response.data).map(key => {
            let entity: Dimension = response.data[key]
            return {ID: entity.ID, Name: this.appTranslatePipe.transform(entity, 'Name'), ThemeGroupID: entity.ThemeGroup?.ID}
          });

          this.initializeForm()

        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )
  }

  private loadQuestion() {
    this.processManagerService.force()
    this.adminService.editQuestion(this.questionID).subscribe({
      complete: () => this.processManagerService.notify('question'),
      next: (response: JsonResponse) => {
        this.question = response.data

        this.loadDimensions()
        this.loadScoringParameters()
        this.loadThemes()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  /**
   * Scoring Parameters are only selectable on the
   * 100 question survey ehis is why we do not include
   * the 'No Scoring Parameter' option
   *
   * @private
   */
  private loadScoringParameters() {
    this.luScoringParameters = []
    this.luScoringParameters.push({Name: '-1 - Negative', ID: -1})
    //this.luScoringParameters.push({Name: '0 - No Scoring Parameter', ID: 0})
    this.luScoringParameters.push({Name: '1 - Positive', ID: 1})
  }

  private loadThemes() {
    this.lookupService.listThemes().subscribe({
      complete: () => this.processManagerService.notify('themes'),
      next: (response: JsonResponse) => {
        this.luThemes = Object.keys(response.data).map(key => {
          let entity: Theme = response.data[key]
          return {ID: entity.ID, Name: this.appTranslatePipe.transform(entity, 'Name'), ThemeGroupID: entity.ThemeGroup.ID};
        });

        this.initializeForm()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public returnFormGroup(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup
  }

  public saveQuestion() {
    let i: number = 0;
    let j: number = 0;
    this.localizationInputElements.forEach(c => c.update())

    if (this.questionForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.questionForm)
      return
    }

    if (!this.isFortyQuestionSurvey) {
      this.question.Dimension!.ID = this.questionForm.get('dimensionID')?.value
      this.question.ScoringParameter = this.questionForm.get('scoringParameter')?.value
      // For the 100 Question survey (CalculationMethod = 'Average') the theme can be assigned directly to the question - it is ultimately then assigned to the question answers themselves [below]
      // For the 40 Question survey (CalculationMethod = 'Average') the theme IS assigned to each question answer
      this.question.Theme!.ID = this.questionForm.get('themeID')?.value
    }
    let localizations: Localization[] = Object.entries(this.question.Localizations!).map(o => o[1]) as Localization[]
    for (i = 0; i < localizations.length; i++) {
      localizations[i].Text = this.localizationService.returnLocalizationValue(localizations[i].LanguageId!, 'text', this.questionForm)
    }

    let questionAnswers: QuestionAnswer[] = Array.from(this.question.QuestionAnswer)
    for (i = 0; i < this.formArrayQuestionAnswers.controls.length; i++) {
      let formGroup: FormGroup = this.formArrayQuestionAnswers.controls[i] as FormGroup
      let id: number = formGroup.get('id')?.value
      let questionAnswer: QuestionAnswer = questionAnswers.find(a => a.ID == id)!
      let localizations: Localization[] = Object.entries(questionAnswer.DimensionAnswer.Localizations!).map(o => o[1]) as Localization[]
      for (j = 0; j < localizations.length; j++) {
        localizations[j].Text = this.localizationService.returnLocalizationValue(localizations[j].LanguageId!, 'text', formGroup)
      }
      if (this.isFortyQuestionSurvey) {
        questionAnswer.Theme.ID = formGroup.get('themeID')?.value
      } else {
        questionAnswer.Theme.ID = this.questionForm.get('themeID')?.value
      }
    }

    this.processManagerService.force()
    this.adminService.modifyQuestion(this.question).subscribe({
      complete: () => this.processManagerService.unforce,
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('ADMIN.QUESTION_DETAIL.SAVED')})

        this.router.navigate(['/admin', 'assessments', 'questions'])
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  //#endregion
}
