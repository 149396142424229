import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BaseComponent } from "../../components/base/base.component";
import { LocalizationInputBaseComponent } from '../../components/controls/localization-input-base/localization-input-base.component';
import { AdminService, ContentBlock, JsonResponse, Language, Localization } from '../../core/api';
import { EditorDisplayOptions } from '../../models/editor-display-options.model';
import { AuthService } from '../../service/auth.service';
import { FormValidationService } from '../../service/form-validation.service';
import { LanguagesService } from '../../service/languages.service';
import { LocalizationService } from '../../service/localization.service';
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-content-block-detail',
  templateUrl: './content-block-detail.component.html'
})
export class ContentBlockDetailComponent extends BaseComponent implements OnInit {
  //#region variables

  public contentBlock: ContentBlock = null!
  public contentBlockForm: FormGroup = null!
  private contentBlockID: number = null!
  public editorConfiguration: any
  public languages: Language[] = null!

  //#endregion

  //#region properties

  @ViewChildren(LocalizationInputBaseComponent) localizationInputElements: QueryList<LocalizationInputBaseComponent> = null!

  //#endregion

  //#region constructor

  constructor(
    private activatedRoute: ActivatedRoute,
    private adminService: AdminService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private languagesService: LanguagesService,
    private localizationService: LocalizationService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickCancel() {
    this.router.navigate(['/admin', 'content'])
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['contentBlock'])
    this.processManagerService.unforce()

    this.languages = this.languagesService.returnLanguages()

    this.editorConfiguration = EditorDisplayOptions.returnConfiguration()

    this.activatedRoute.params.subscribe(params => {
      if (params['contentBlockID']) {
        this.contentBlockID = parseInt(params['contentBlockID'])
      }

      this.loadContentBlock()
    })
  }

  public onLanguageToggled(languageID: number) {
    this.localizationInputElements.forEach(c => c.toggle(languageID))
  }

  public submitContentBlock() {
    this.saveContentBlock()
  }

  //#endregion

  //#region business logic

  private initializeContentBlockForm() {
    let localizations: Localization[] = Object.entries(this.contentBlock.Localizations!).map(o => o[1]) as Localization[]

    this.contentBlockForm = this.formBuilder.group({
      content: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Content', true, null!, this.languages, localizations),
    })
  }

  private loadContentBlock() {
    this.processManagerService.force()
    this.adminService.editContentBlock(this.contentBlockID).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.contentBlock = response.data

        this.initializeContentBlockForm()
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        }
        else {
          this.handleApiException(error)
        }
      }
    })
  }

  private saveContentBlock() {
    this.localizationInputElements.forEach(c => c.update())

    if (this.contentBlockForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.contentBlockForm)
      return
    }

    let localizations: Localization[] = Object.entries(this.contentBlock.Localizations!).map(o => o[1]) as Localization[]
    for (var i: number = 0; i < localizations.length; i++) {
      localizations[i].Content = this.localizationService.returnLocalizationValue(localizations[i].LanguageId!, 'content', this.contentBlockForm)
    }

    this.processManagerService.force()
    this.adminService.modifyContentBlock(this.contentBlockID, this.contentBlock).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('ADMIN.CONTENT_BLOCK_DETAIL.SAVED') })

        this.router.navigate(['/admin', 'content'])
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        }
        else {
          this.handleApiException(error)
        }
      }
    })
  }

  //#endregion
}
