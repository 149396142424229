import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslatePipe} from '@ngx-translate/core';
import {BaseComponent} from "../../components/base/base.component";
import {AssessmentAnswer, AssessmentResponse, JsonResponse, ParticipantService} from "../../core/api";
import {UserAssessment} from "../../models/user-assessment.model";
import {UserQuestion} from '../../models/user-question.model';
import {FormValidationService} from '../../service/form-validation.service';
import {ProcessManagerService} from "../../service/process-manager.service";
import {UserAssessmentService} from '../../service/user-assessment.service';

@Component({
  selector: 'app-assessment-question',
  templateUrl: './assessment-question.component.html',
  styleUrls: ['./assessment-question.component.scss']
})
export class AssessmentQuestionComponent extends BaseComponent implements OnInit {
  // variables

  public answerForm: FormGroup = null!
  private userAssessment: UserAssessment = null!
  private displayRequestConfirmation: boolean = false

  public get showDimensionAndQuestionText(): boolean {
    // let userAssessment: UserAssessment = this.userAssessmentService.loadUserAssessment()
    if (null === this.userAssessment) {
      this.userAssessment = this.userAssessmentService.loadUserAssessment()
    }
    // return 'Average' === this.userAssessmentService.loadUserAssessment().Assessment.Survey?.CalculationMethod
    if (null !== this.userAssessment) {
      return 'Average' === this.userAssessment.Assessment.Survey?.CalculationMethod

    }
    return true
  }

  // properties

  public get displaySubmitConfirmation(): boolean {
    return this.displayRequestConfirmation
  }

  /**
   * @see
   */
  public get displaySaveAndReturn(): boolean {
    return !this.displaySubmitConfirmation
      && !(this.userQuestion.Index == (this.userQuestion.Total - 1))
  }

  public get displayComplete(): boolean {
    return !this.displaySubmitConfirmation
      && (this.userQuestion.Index == (this.userQuestion.Total - 1))
  }

  public get displayNext(): boolean {
    return this.userQuestion.Index < (this.userQuestion.Total - 1)
  }

  public get displayPrevious(): boolean {
    return 0 < this.userQuestion.Index
  }

  public get questionIndex(): number {
    return this.userQuestion.Index + 1
  }

  public get questionTotal(): number {
    return this.userAssessmentService.progress.QuestionTotal
  }

  @Input() userQuestion: UserQuestion = null!
  @Output() onNext: EventEmitter<number> = new EventEmitter()
  @Output() onPrevious: EventEmitter<number> = new EventEmitter()
  @Output() onSaved: EventEmitter<void> = new EventEmitter()
  @Output() onSaveForLater: EventEmitter<void> = new EventEmitter()
  @Output() onSaving: EventEmitter<void> = new EventEmitter()
  //@Output() onRequestConfirmation: EventEmitter<void> = new EventEmitter()
  @Output() onComplete: EventEmitter<void> = new EventEmitter()

  // constructor

  constructor(
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private participantService: ParticipantService,
    processManagerService: ProcessManagerService,
    private translate: TranslatePipe,
    private userAssessmentService: UserAssessmentService
  ) {
    super(processManagerService)
  }

  // event handlers
  public clickNext() {
    //if (this.answerForm.get('questionAnswerID')?.value) {
      this.saveAnswer(true, false, false, false, true)
    //}
  }

  public clickPrevious() {
    if (this.answerForm.get('questionAnswerID')?.value) {
      this.saveAnswer(true, true)
    } else {
      this.onPrevious.emit(this.userQuestion.Question.ID)
    }
  }

  public clickSaveReturn() {
    this.saveAnswer(false, false, true)
  }

  public requestConfirmation() {
    this.saveAnswer(false, false, false, true)
  }

  public revokeConfirmation() {
    this.displayRequestConfirmation = false
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.initialize()
  }

  public submit() {
    this.saveAnswer()
  }

  // business logic

  private initialize() {
    this.answerForm = this.formBuilder.group({
      questionAnswerID: [null, [Validators.required]],
    })
  }

  public load() {
    this.answerForm.get('questionAnswerID')?.setValue(null)

    let assessmentAnswer: AssessmentAnswer = this.userAssessmentService.progress.Answers[this.userQuestion.Question.ID!] as AssessmentAnswer
    if (assessmentAnswer != undefined) {
      this.answerForm.get('questionAnswerID')?.setValue(assessmentAnswer.QuestionAnswerID)
    }

    this.answerForm.markAsPristine()
    this.answerForm.markAsUntouched()

    this.userAssessmentService.setCurrentQuestionID(this.userQuestion.Question.ID!)
  }

  private saveAnswer(
    validate: boolean = true,
    previous: boolean = false,
    saveReturn: boolean = false,
    requestConfirmation: boolean = false,
    next: boolean = false
  ) {
    if (validate && this.answerForm.invalid) {
      //this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.answerForm)
      return
    }

    if (saveReturn && null == this.answerForm.get('questionAnswerID')?.value) {
      this.onSaveForLater.emit()

      return
    }

    let assessmentAnswer: AssessmentAnswer = {
      QuestionAnswerID: this.answerForm.get('questionAnswerID')?.value,
      QuestionID: this.userQuestion.Question.ID
    }

    let assessmentResponse: AssessmentResponse = {
      AssessmentAnswer: [assessmentAnswer],
      ID: this.userQuestion.AssessmentID
    }

    this.onSaving.emit()
    this.participantService.assessmentSave(assessmentResponse).subscribe({
      complete: () => this.processManagerService.notify('participantWelcome'),
      next: (response: JsonResponse) => {
        this.userAssessmentService.setAnswer(this.userQuestion.Question.ID!, assessmentAnswer)

        this.onSaved.emit()

        switch (true) {

          // Navigate to previous question
          case previous:
            //alert("previous");
            this.onPrevious.emit(this.userQuestion.Question.ID)
            break;

          // Save the survey and come back later
          case saveReturn:
            //alert("saveReturn");
            this.onSaveForLater.emit()
            break;

          // Prompt the user to save their completed survey
          case requestConfirmation:
            //alert("requestConfirmation");
            this.displayRequestConfirmation = true
            break;

          // Navigate to next question
          case next:
            //alert("onNext");
            this.onNext.emit(this.userQuestion.Question.ID)
            break;

          // Save the completed survey after confirmation
          case this.userAssessmentService.isAssessmentComplete():
            //alert("this.userAssessmentService.isAssessmentComplete()");
            this.onComplete.emit()
            break;


          // Navigate to next question
          default:
            alert("Unexpected trigger");
        }

      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        } else {
          this.handleApiException(error)
        }

        this.onSaved.emit()
      }
    })
  }
}
