/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserLoginResponseDto } from './userLoginResponseDto';
import { ContentBlock } from './contentBlock';
import { ParticipantDto } from './participantDto';
import { Question } from './question';
import { AssessmentResponse } from './assessmentResponse';
import { AssessmentAnswer } from './assessmentAnswer';


/**
 * Class WelcomeDataDto
 */
export interface WelcomeData { 
    WelcomeScreenText: ContentBlock;
    /**
     * Assessment Questions
     */
    Questions: Array<Question>;
    /**
     * Assessment Answers
     */
    AssessmentAnswers: Array<AssessmentAnswer>;
    /**
     * Any error message generated by the API - please note this is now a translatable string but used to be a ContentBlockDto to accommodate translations
     */
    Error: string;
    UserLoginResponse?: UserLoginResponseDto;
    Participant: ParticipantDto;
    Assessment: AssessmentResponse;
}

