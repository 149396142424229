import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../components/base/base.component";
import {JsonResponse, LookupService} from '../../core/api';
import {ProcessManagerService} from "../../service/process-manager.service";

@Component({
  selector: 'app-scoring',
  templateUrl: './scoring.component.html',
  styleUrls: ['./scoring.component.scss']
})
export class ScoringComponent extends BaseComponent implements OnInit {
  //#region variables

  public surveys!: any[]

  //#endregion

  //#region constructor

  constructor(
    private lookupService: LookupService,
    processManagerService: ProcessManagerService,
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['surveys'])
    this.processManagerService.unforce()

    this.loadSurveys();
  }

  //#endregion

  //#region business logic

  /**
   * Loads Surveys to which a scoring matrix can be applied
   * Only surveys with the Calculation Method 'Average'
   * can have scores applied for negative and positive questions
   * Those with the Calculation Method 'Sum' do not use this calculation system
   * @private
   */
  private loadSurveys() {
    this.processManagerService.force()
    this.lookupService.listSurveys().subscribe({
      complete: () => this.processManagerService.notify('surveys'),
      next: (response: JsonResponse) => {
        this.surveys = Object.keys(response.data).map(key => {
          console.log('response.data', response.data)
          console.log('response.data[key]', response.data[key])
          return response.data[key];
        })
        //.filter(s => 'Average' === s.CalculationMethod)
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  //#endregion
}
