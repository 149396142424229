/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SurveyDto } from './surveyDto';
import { DimensionAnswerSimple } from './dimensionAnswerSimple';
import { Localization } from './localization';


/**
 * Class DimensionEditDto
 */
export interface DimensionEdit { 
    /**
     * The Read-Only ID of the Dimension
     */
    ID: number;
    /**
     * The [Internal] name/label for this Dimension
     */
    Name: string;
    Survey?: SurveyDto | null;
    /**
     * *_/
     */
    DimensionAnswer: Array<DimensionAnswerSimple>;
    /**
     * *_/
     */
    Localizations: Array<Localization>;
}

