<div class="layout-content-inner participant-page">
  <div class="wrapper">

    <!-- Participant Authentication Interface -->
    <div class="participant-login" *ngIf="displayParticipantAuthentication">
      <p class="welcome-instructions">
        <span innerHTML="{{'PARTICIPANT.WELCOME.INSTRUCTIONS'|translate}}"></span>
      </p>
      <form class="light-theme participant-login-form" [formGroup]="participantAuthenticationForm" (submit)="submitParticipantAuthentication()" autocomplete="off" novalidate *ngIf="participantAuthenticationForm">
        <div class="p-fluid">
          <div class="grid">
            <div class="field form-field col-12">
              <label for="inputEmail" class="sr-only">{{'PARTICIPANT.WELCOME.EMAIL'|translate}}</label>
              <input type="text" pInputText id="inputEmail" formControlName="email" placeholder="{{'PARTICIPANT.WELCOME.EMAIL'|translate}}">
              <app-validation-error fieldName="email" [formGroup]="participantAuthenticationForm" validatorName="email">{{'PARTICIPANT.WELCOME.ERROR_EMAIL_ERROR_FORMAT'|translate}}</app-validation-error>
              <app-validation-error fieldName="email" [formGroup]="participantAuthenticationForm" validatorName="required">{{'PARTICIPANT.WELCOME.ERROR_EMAIL_ERROR_REQUIRED'|translate}}</app-validation-error>
            </div>
          </div>
          <div class="grid">
            <div class="field form-field col-12">
              <p-button type="submit" label="{{'PARTICIPANT.WELCOME.BUTTON_AUTHENTICATE'|translate}}"></p-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Participant Profile Interface -->
    <div *ngIf="displayParticipantProfile">
      <div class="introduction">

        <div [innerHTML]="welcomeScreenText|appTranslate:'Content'"></div>

        <p class="validation-instructions">
          <span [innerHTML]="'PARTICIPANT.WELCOME.VALIDATION_INSTRUCTIONS'|translate"></span>
        </p>
      </div>
      <form class="light-theme participant-details" [formGroup]="participantProfileForm" (submit)="submitParticipantProfile()" autocomplete="off" novalidate *ngIf="participantProfileForm">
        <div class="p-fluid">
          <div class="grid participant-fields">
            <div class="field form-field col-12 md:col-6">
              <label for="inputFirstName">{{'PARTICIPANT.WELCOME.FIRST_NAME'|translate}}</label>
              <input type="text" pInputText id="inputFirstName" formControlName="firstName" placeholder="{{'PARTICIPANT.WELCOME.FIRST_NAME'|translate}}">
              <app-validation-error fieldName="firstName" [formGroup]="participantProfileForm" validatorName="required">{{'PARTICIPANT.WELCOME.ERROR_FIRST_NAME_ERROR_REQUIRED'|translate}}</app-validation-error>
            </div>
            <div class="field form-field col-12 md:col-6">
              <label for="inputLastName">{{'PARTICIPANT.WELCOME.LAST_NAME'|translate}}</label>
              <input type="text" pInputText id="inputLastName" formControlName="lastName" placeholder="{{'PARTICIPANT.WELCOME.LAST_NAME'|translate}}">
              <app-validation-error fieldName="lastName" [formGroup]="participantProfileForm" validatorName="required">{{'PARTICIPANT.WELCOME.ERROR_LAST_NAME_ERROR_REQUIRED'|translate}}</app-validation-error>
            </div>
            <div class="field form-field col-12 md:col-12">
              <label for="inputEmail">{{'PARTICIPANT.WELCOME.EMAIL'|translate}}</label>
              <input type="text" pInputText id="inputEmail" formControlName="email" placeholder="{{'PARTICIPANT.WELCOME.EMAIL'|translate}}">
              <app-validation-error fieldName="email" [formGroup]="participantProfileForm" validatorName="email">{{'PARTICIPANT.WELCOME.ERROR_EMAIL_ERROR_FORMAT'|translate}}</app-validation-error>
              <app-validation-error fieldName="email" [formGroup]="participantProfileForm" validatorName="required">{{'PARTICIPANT.WELCOME.ERROR_EMAIL_ERROR_REQUIRED'|translate}}</app-validation-error>
            </div>
            <div class="field form-field col-12 md:col-12">
              <label for="inputOrganization">{{'PARTICIPANT.WELCOME.ORGANIZATION'|translate}}</label>
              <input type="text" pInputText id="inputOrganization" formControlName="organization" placeholder="{{'PARTICIPANT.WELCOME.ORGANIZATION'|translate}}">
            </div>
            <div class="field form-field col-12 md:col-6">
              <label for="inputPosition">{{'PARTICIPANT.WELCOME.POSITION'|translate}}</label>
              <input type="text" pInputText id="inputPosition" formControlName="position" placeholder="{{'PARTICIPANT.WELCOME.POSITION'|translate}}">
            </div>
            <div class="field form-field form-field col-12 md:col-6">
              <label for="inputTitle">{{'PARTICIPANT.WELCOME.TITLE'|translate}}</label>
              <input type="text" pInputText id="inputTitle" formControlName="title" placeholder="{{'PARTICIPANT.WELCOME.TITLE'|translate}}">
            </div>
          </div>
          <div class="grid">
            <div class="field form-field col-3">
              <p-button type="submit" label="{{'PARTICIPANT.WELCOME.BUTTON_CONTINUE'|translate}}"></p-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Assessment Questions interface -->
    <div *ngIf="displayAssessment">
      <div class="grid">
        <div class="col-12">

          <app-assessment-question #assessmentQuestionElement
                                   [userQuestion]="userQuestion"
                                   (onNext)="onNextQuestion($event)"
                                   (onPrevious)="onPreviousQuestion($event)"
                                   (onSaveForLater)="onQuestionSaveForLater()"
                                   (onSaving)="onQuestionSaving()"
                                   (onSaved)="onQuestionSaved()"
                                   (onComplete)="onCompleteAssessment()"
                                   *ngIf="userQuestion">
          </app-assessment-question>
        </div>
      </div>
    </div>

    <!-- Assessment Complete Interface -->
    <div *ngIf="displaySavedForLater">
      <div class="grid completion">
        <div class="col-12 md:col-6">
          <h2>
            {{'PARTICIPANT.WELCOME.ASSESSMENT_SAVED_FOR_LATER_HEADER'|translate}}
          </h2>
          <p [innerHTML]="'PARTICIPANT.WELCOME.ASSESSMENT_SAVED_FOR_LATER'|translate"></p>
          <a class="button-style" routerLink="/recover-assessment-link">{{'GUEST.LOGIN.RECOVER_ASSESSMENT_LINK'|translate}}</a>
        </div>
      </div>
    </div>

    <!-- Assessment Complete Interface -->
    <div *ngIf="displayAssessmentComplete">
      <div class="grid completion">
        <div class="col-12 md:col-6">
          <h2>
            {{'PARTICIPANT.WELCOME.ASSESSMENT_COMPLETE_HEADER'|translate}}
          </h2>
          <span [innerHTML]="confirmationText"></span>
          <p>
            <p-button type="button" label="{{'PARTICIPANT.WELCOME.BUTTON_DOWNLOAD'|translate}}" (click)="clickDownloadReport()"></p-button>
          </p>
        </div>
        <div class="col-12 md:col-6">
          <div class="img-wrap pdf-img"></div>
        </div>
      </div>
    </div>

    <div *ngIf="displayMissingAccessToken">
      <div class="grid token">
        <div class="col-12">
          <h1>
            {{'PARTICIPANT.WELCOME.ASSESSMENT_NO_ACCESS'|translate}}
          </h1>
          <p>
            <span [innerHTML]="'PARTICIPANT.WELCOME.ERROR_MISSING_ACCESS_TOKEN'|translate"></span>
          </p>
          <p>
            <a class="button-style" routerLink="/recover-assessment-link">{{'GUEST.LOGIN.RECOVER_ASSESSMENT_LINK'|translate}}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
