/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class PurchaseStatsDto
 */
export interface PurchaseStats { 
    ID: string;
    SurveyID?: number | null;
    Purchased: number;
    Complimentary: number;
    Removed: number;
    Issued: number;
    Pending: number;
    Incomplete: number;
    Submitted: number;
    Available: number;
}

