import { Injectable } from '@angular/core'
import { AssessmentAnswer, AssessmentResponse, ParticipantDto } from '../core/api'
import { UserAssessmentProgress } from '../models/user-assessment-progress.model'
import { UserAssessment } from '../models/user-assessment.model'
import { CacheService } from './cache.service'

@Injectable()
export class UserAssessmentService {
  // variables

  private readonly userAssessmentKey: string = 'userAssessment'
  private userAssessmentProgress: UserAssessmentProgress = null!
  private readonly userAssessmentProgressKey: string = 'userAssessmentProgress'

  // properties

  public get progress(): UserAssessmentProgress {
    return this.userAssessmentProgress
  }

  private static get CACHE_DURATION() : number {
    return 60 * 60 * 24 // 1 day
  }

  // constructor

  constructor(
    private cacheService: CacheService
  ) {
    this.loadUserAssessmentProgress()
  }

  // business logic

  public decrementQuestionIndex() {
    this.updateQuestionIndex(-1)
  }

  public incrementQuestionIndex() {
    this.updateQuestionIndex(1)
  }

  public initializeUserAssessmentProgress() {
    this.loadUserAssessmentProgress()
  }

  public isAssessmentComplete(): boolean {
    if (!this.userAssessmentProgress || !this.userAssessmentProgress.Answers) {
      return false
    }

    return Object.entries(this.userAssessmentProgress.Answers).length == this.userAssessmentProgress.QuestionTotal
  }

  public loadUserAssessment(): UserAssessment {
    let userAssessment: UserAssessment = this.cacheService.longTerm.get(this.userAssessmentKey)! as UserAssessment

    if (null != userAssessment) {
      userAssessment.Assessment = userAssessment.Assessment as AssessmentResponse
      userAssessment.Participant = userAssessment.Participant as ParticipantDto
    }

    return userAssessment
  }

  private loadUserAssessmentProgress(): any {
    let userAssessmentProgress: UserAssessmentProgress = this.cacheService.longTerm.get(this.userAssessmentProgressKey)! as UserAssessmentProgress

    if (null == userAssessmentProgress) {
      let userAssessment: UserAssessment = this.loadUserAssessment()

      if (null != userAssessment) {
        userAssessmentProgress = new UserAssessmentProgress()
        userAssessmentProgress.Answers = {}
        userAssessmentProgress.CurrentQuestionID = null!
        userAssessmentProgress.QuestionIndex = 1
        userAssessmentProgress.QuestionTotal = userAssessment.Assessment.Survey?.QuestionCount!

        this.saveUserAssessmentProgress()
      }
    }

    this.userAssessmentProgress = userAssessmentProgress
  }

  public reloadUserAssessmentProgress() {
    let userAssessment: UserAssessment = this.loadUserAssessment()

    if (userAssessment.Assessment && userAssessment.Assessment.AssessmentAnswer) {
      let length: number = Object.entries(this.userAssessmentProgress.Answers).length

      if (0 == length) {
        let answers: any = {}
        let currentQuestionID: number = 0
        let questionIndex: number = 0

        userAssessment.Assessment.AssessmentAnswer.forEach(a => {
          currentQuestionID = a.QuestionID!
          questionIndex += 1

          let assessmentAnswer: AssessmentAnswer = {
            QuestionAnswerID: a.QuestionAnswerID,
            QuestionID: a.QuestionID
          }

          answers[currentQuestionID] = assessmentAnswer
        })

        this.saveUserAssessment(userAssessment)
        this.userAssessmentProgress.Answers = answers
        this.userAssessmentProgress.CurrentQuestionID = currentQuestionID
        this.userAssessmentProgress.QuestionIndex = questionIndex
        this.saveUserAssessmentProgress()
      }
    }
  }

  public reset() {
    this.cacheService.longTerm.delete(this.userAssessmentKey)
    this.cacheService.longTerm.delete(this.userAssessmentProgressKey)
  }

  public resetQuestionIndex() {
    this.userAssessmentProgress.QuestionIndex = 1

    this.saveUserAssessmentProgress()
  }

  public saveUserAssessment(userAssessment: UserAssessment) {
    this.cacheService.longTerm.set(this.userAssessmentKey, userAssessment, UserAssessmentService.CACHE_DURATION)
  }

  public saveUserAssessmentProgress() {
    if (null != this.userAssessmentProgress) {
      this.cacheService.longTerm.set(this.userAssessmentProgressKey, this.userAssessmentProgress, UserAssessmentService.CACHE_DURATION)
    }
  }

  public setAnswer(questionID: number, assessmentAnswer: AssessmentAnswer) {
    this.userAssessmentProgress.Answers[questionID] = assessmentAnswer

    this.saveUserAssessmentProgress()
  }

  public setCurrentQuestionID(questionID: number) {
    this.userAssessmentProgress.CurrentQuestionID = questionID

    this.saveUserAssessmentProgress()
  }

  private updateQuestionIndex(number: number) {
    this.userAssessmentProgress.QuestionIndex += number

    this.saveUserAssessmentProgress()
  }
}
