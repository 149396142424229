import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { TranslatePipe } from "@ngx-translate/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BaseComponent } from "../../components/base/base.component";
import { DistributionReminder, DistributorService, JsonResponse } from "../../core/api";
import { AppTranslatePipe } from "../../pipes/app-translate.pipe";
import { AuthService } from "../../service/auth.service";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html'
})
export class RemindersComponent extends BaseComponent implements OnInit {
  //#region variables

  public distributionID: number = null!
  public instructionsMessage: string = null!
  public reminders!: DistributionReminder[]

  //#endregion

  //#region constructor

  constructor(
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private distributorService: DistributorService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickDeleteReminder(reminder: DistributionReminder) {
    this.confirmationService.confirm({
      header: this.translate.transform('DISTRIBUTOR.REMINDERS.DELETE'),
      message: this.translate.transform('DISTRIBUTOR.REMINDERS.DELETE_CONFIRMATION'),
      accept: () => {
        this.deleteReminder(reminder)
      }
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['reminders'])
    this.processManagerService.unforce()

    this.route.params.subscribe((params: Params) => {
      if (params['distributionID']) {
        this.distributionID = parseInt(params['distributionID'])
      }

      if (this.distributionID != null) {
        this.loadReminders()
      }
    })
  }

  //#endregion

  //#region business logic

  private deleteReminder(reminder: DistributionReminder) {
    this.processManagerService.force()
    this.distributorService.deleteReminder(reminder).subscribe({
      complete: () => this.processManagerService.notify('reminders'),
      next: (response: JsonResponse) => {
        this.messageService.add({
          life: 10000, severity: 'info', detail: this.translate.transform('DISTRIBUTOR.REMINDERS.DELETED')
        })

        this.loadReminders()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  private loadReminders() {
    this.distributorService.listReminders(this.distributionID).subscribe({
      complete: () => this.processManagerService.notify('reminders'),
      next: (response: JsonResponse) => {
        this.reminders = Object.keys(response.data).map(k => {
          return response.data[k];
        });

        if (this.reminders.length > 0) {
          this.instructionsMessage = 'DISTRIBUTOR.REMINDERS.INSTRUCTIONS'
        }
        else {
          this.instructionsMessage = 'DISTRIBUTOR.REMINDERS.INSTRUCTIONS_NO_REMINDERS'
        }
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  //#endregion
}
