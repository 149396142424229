/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmailType } from './emailType';
import { EmailMessageVariable } from './emailMessageVariable';
import { UserMinimalDto } from './userMinimalDto';
import { Localization } from './localization';
import { EmailBlock } from './emailBlock';


/**
 * Class EmailMessageDto
 */
export interface EmailMessage { 
    ID?: number;
    Body?: string;
    SenderName?: string;
    SenderEmail?: string;
    EmailType?: EmailType;
    Owner?: UserMinimalDto;
    /**
     * The Localizations
     */
    Localizations?: Set<Localization>;
    /**
     * The Email Message Variables
     */
    EmailMessageVariable?: Set<EmailMessageVariable>;
    /**
     * The Email Blocks
     */
    EmailBlock?: Set<EmailBlock>;
}

