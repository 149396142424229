/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class PurchaseItemDto
 */
export interface PurchaseItem { 
    /**
     * SurveyID: Integer that corresponds with \'100 Questions 5-point scale\', \'40 Questions A/B Survey\'
     */
    SurveyID: number;
    /**
     * PurchasePurpose: \'Self-Assessment\', \'Redistribution\'
     */
    PurchasePurpose: string;
    /**
     * LicenseCount: The number of licenses purchased for this product type
     */
    LicenseCount: number;
}

