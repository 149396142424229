/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class AssessmentResponseChangeEmailDto
 */
export interface AssessmentResponseChangeEmail { 
    /**
     * ID of the AssessmentResponse
     */
    ID: number;
    /**
     * The revised email address for the AssessmentResponse User
     */
    Email: string;
}

