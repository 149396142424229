/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class DistributionReminderDto
 */
export interface DistributionReminder { 
    /**
     * The Read-Only ID of the Reminder
     */
    ID?: number | null;
    /**
     * The date of the Reminder
     */
    ReminderDate: string | null;
    /**
     * The date the Reminder was sent
     */
    ReminderSent?: string | null;
    DistributionID?: number | null;
}

