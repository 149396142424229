import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProcessManagerService {

  //#region variables
  public processes: Process[];
  //#endregion

  //#region constructors
  constructor() {
    this.processes = [];
  }

  //#endregion

  //#region business logic
  public addProcess(name: string) {
    let existingProcess: Process | undefined = this.processes.find(p => p.name == name);

    if (undefined === existingProcess) {
      this.processes.push(new Process(name));
    } else {
      existingProcess.isComplete = false
    }
  }

  public addProcesses(names: string[]) {
    this.processes = [];
    for (let i: number = 0; i < names.length; i++) {
      this.addProcess(names[i]);
    }
  }

  public force() {
    for (let i: number = 0; i < this.processes.length; i++) {
      this.processes[i].isComplete = false;
    }
  }

  public isComplete(): boolean {
    const incompleteProcesses: Process[] = this.processes.filter(p => !p.isComplete);
    return 0 === incompleteProcesses.length;
  }

  public notify(name: string) {
    const existingProcess: Process | undefined = this.processes.find(p => p.name == name);

    if (existingProcess != undefined) {
      existingProcess.isComplete = true;
    }
  }

  public reset() {
    for (let i: number = 0; i < this.processes.length; i++) {
      this.processes[i].isComplete = false;
    }
  }

  public resetProcess(name: string) {
    const existingProcess: Process | undefined = this.processes.find(p => p.name == name);

    if (existingProcess != undefined) {
      existingProcess.isComplete = false;
    }
  }

  public unforce() {
    for (let i: number = 0; i < this.processes.length; i++) {
      this.processes[i].isComplete = true;
    }
  }

  //#endregion

}

export class Process {
  public name: string;
  public isComplete: boolean = false;

  constructor(name: string, isComplete: boolean = false) {
    this.isComplete = isComplete;
    this.name = name;
  }
}
