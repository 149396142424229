/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserMinimalDto } from './userMinimalDto';
import { LookupDto } from './lookupDto';
import { LicenseSimple } from './licenseSimple';


/**
 * Class AssessmentResponseForDistributionDto
 */
export interface AssessmentResponseForDistribution { 
    /**
     * ID of the AssessmentResponse
     */
    ID: number;
    /**
     * AccessToken of the AssessmentResponse
     */
    AccessToken?: string;
    User: UserMinimalDto;
    /**
     * Date Created
     */
    CreatedDate: string;
    /**
     * Date Submitted (if any)
     */
    SubmittedDate?: string | null;
    /**
     * Number of questions currently answered
     */
    AnswerCount: number;
    License: LicenseSimple;
    Status: LookupDto;
}

