import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { TranslateLoader, TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "src/environments/environment";
import { AdminModule } from "./admin/admin.module";
import { DateFormatPipe, MomentModule } from 'ngx-moment'

import { registerLocaleData } from "@angular/common";
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppComponentsModule } from './components/app-components.module';
import { BaseComponent } from './components/base/base.component';
import { ApiModule, Configuration } from "./core/api";
import { DistributorModule } from "./distributor/distributor.module";
import { ForgotPasswordComponent } from "./guest/forgot-password.component";
import { RecoverAssessmentLinkComponent } from './guest/recover-assessment-link/recover-assessment-link.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { DistributorLayoutComponent } from './layout/distributor-layout/distributor-layout.component';
import { GuestLayoutComponent } from './layout/guest-layout/guest-layout.component';
import { ParticipantLayoutComponent } from './layout/participant-layout/participant-layout.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ParticipantModule } from "./participant/participant.module";
import { PrimeNgModule } from "./prime-ng-module";
import { FormValidationService } from "./service/form-validation.service";
import { LanguagesService } from "./service/languages.service";
import { LocalizationService } from "./service/localization.service";
import { UserAssessmentService } from "./service/user-assessment.service";
import { AuthInterceptor } from "./shared/authconfig.interceptor";
import { CacheService } from "./service/cache.service";
import { StaticHtmlService } from "./service/static-html.service";

registerLocaleData(localeEn, 'en')
registerLocaleData(localeFr, 'fr')

// export function tokenGetter() {
//   return localStorage.getItem(TOKEN_NAME)
// }
//
// const JWT_Module_Options: JwtModuleOptions = {
//   config: {
//     allowedDomains: [
//       'localhost:4200',
//       'local.conflictability.com',
//       'survey.conflictability.qa.caorda.com'
//     ],
//     tokenGetter: tokenGetter
//   }
// };

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http)
// }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class wpConfiguration {
  wpUrl?: string
}

// make sure to import the HttpClientModule in the AppModule only,
// see https://github.com/angular/angular/issues/20575
@NgModule({
  imports: [
    AdminModule,
    ApiModule,
    AppComponentsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DistributorModule,
    HttpClientModule,
    // JwtModule.forRoot(JWT_Module_Options),
    MomentModule,
    ParticipantModule,
    PrimeNgModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        //useFactory: (HttpLoaderFactory),
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  // Like PHP use class
  declarations: [
    AdminLayoutComponent,
    AppComponent,
    BaseComponent,
    DistributorLayoutComponent,
    GuestLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    PageNotFoundComponent,
    ParticipantLayoutComponent,
    RecoverAssessmentLinkComponent,
  ],
  // entryComponents[],
  providers: [
    CacheService,
    StaticHtmlService,
    FormValidationService,
    JwtHelperService,
    LanguagesService,
    LocalizationService,
    Title,
    DateFormatPipe,
    TranslatePipe,
    UserAssessmentService,
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // default behaviour when a specific type of object is requested (in this case, a request for Configuration)
    {
      provide: Configuration,
      useFactory: () => new Configuration(
        {
          basePath: environment.apiUrl
        }
      ),
      multi: false
    },
    {
      provide: wpConfiguration,
      useFactory: () => {
        return {
          wpUrl: environment.wpUrl
        }
      }
    }
  ],
  // Occurs on startup
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule {
  constructor() {
  }
}
