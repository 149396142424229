/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserStatusDto } from './userStatusDto';


/**
 * Class UserStatusModify
 */
export interface UserStatusModify { 
    /**
     * The Read-Only ID of the User
     */
    ID: number;
    /**
     * The User Status DTO for the user - defaults to active [1]
     */
    UserStatus?: UserStatusDto | null;
}

