import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../components/base/base.component";
import {ProcessManagerService} from "../../service/process-manager.service";

@Component({
  selector: 'app-distributor-layout',
  templateUrl: './distributor-layout.component.html',
  // styleUrls: ['./distributor-layout.component.css']
})
export class DistributorLayoutComponent extends BaseComponent implements OnInit {

  constructor(
    processManagerService: ProcessManagerService,
  ) {
    super(processManagerService)
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

}
