import { AbstractControl } from "@angular/forms";

export function PasswordStrengthValidator(minLength: number) : (control: AbstractControl) => object | null {
    return (control: AbstractControl) : object | null => {
        if (null === control || null === control.value || (
            control.value.toLocaleUpperCase() !== control.value
         && control.value.toLocaleLowerCase() !== control.value
         && 0 !== control.value.replace(/[^\d]/g, '').length
         && 0 !== control.value.replace(/[a-zA-Z0-9]/gi, '').length
         && minLength <= control.value.length
        )) {
            return null
        }

        return {
            invalidStrength: true
        }
    }
}