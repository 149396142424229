/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Language } from './language';


/**
 * Class ParticipantDto
 */
export interface ParticipantDto { 
    /**
     * The Read-Only ID of the User
     */
    ID?: number | null;
    /**
     * First Name
     */
    FirstName?: string | null;
    /**
     * Last Name
     */
    LastName?: string | null;
    /**
     * Email
     */
    Email?: string | null;
    /**
     * Organization
     */
    Organization?: string | null;
    /**
     * Position in Organization
     */
    Position?: string | null;
    /**
     * Title: Mr., Mrs., Ms., Dr., Prof. Etc 
     */
    Title?: string | null;
    /**
     * User/Participant Language
     */
    Language?: Language | null;
}

