import {Component, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent {
  @Input() controlName!: string
  @Input() formGroup!: FormGroup
  @Input() id: string = ''
  @Input() label: string = ''
  @Input() options!: { ID: number, Name: string }[]

  constructor() {
    if (!this.id) {
      this.id = this.controlName
    }
  }
}
