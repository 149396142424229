import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from "../app-routing.module";
import { AppComponentsModule } from "../components/app-components.module";
import { DistributorModule } from "../distributor/distributor.module";
import { PipesModule } from '../pipes/pipes.module';
import { PrimeNgModule } from "../prime-ng-module";
import { AdminComponent } from "./admin.component";
import { AssessmentsComponent } from "./assessments/assessments.component";
import { ComplimentaryLicenseDetailComponent } from './complimentary-license-detail/complimentary-license-detail.component';
import { ComplimentaryLicensesComponent } from './complimentary-licenses/complimentary-licenses.component';
import { ContentBlockDetailComponent } from './content-block-detail/content-block-detail.component';
import { ContentBlocksComponent } from './content-blocks/content-blocks.component';
import { DashboardAdminComponent } from "./dashboard-admin/dashboard-admin.component";
import { DimensionDetailComponent } from './dimension-detail/dimension-detail.component';
import { DimensionsComponent } from "./dimensions/dimensions.component";
import { LicenseAdjustCountComponent } from './license-adjust-count/license-adjust-count.component';
import { LicenseDetailComponent } from './license-detail/license-detail.component';
import { LicensesComponent } from "./licenses/licenses.component";
import { QuestionDetailComponent } from './question-detail/question-detail.component';
import { QuestionsComponent } from "./questions/questions.component";
import { ScoringMatrixComponent } from './scoring-matrix/scoring-matrix.component';
import { ScoringComponent } from "./scoring/scoring.component";
import { ThemesComponent } from "./themes/themes.component";
import { UserDetailComponent } from "./user-detail/user-detail.component";
import { UsersComponent } from "./users/users.component";

@NgModule({
  declarations: [
    AdminComponent,
    AssessmentsComponent,
    ComplimentaryLicensesComponent,
    ComplimentaryLicenseDetailComponent,
    ContentBlockDetailComponent,
    ContentBlocksComponent,
    DashboardAdminComponent,
    DimensionDetailComponent,
    DimensionsComponent,
    LicenseAdjustCountComponent,
    LicenseDetailComponent,
    LicensesComponent,
    QuestionDetailComponent,
    QuestionsComponent,
    ScoringComponent,
    ScoringMatrixComponent,
    ThemesComponent,
    UsersComponent,
    UserDetailComponent,
  ],
  imports: [
    AppComponentsModule,
    AppRoutingModule,
    CKEditorModule,
    CommonModule,
    DistributorModule,
    MomentModule,
    PipesModule,
    PrimeNgModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  exports: [
    AssessmentsComponent,
    ComplimentaryLicensesComponent,
    ComplimentaryLicenseDetailComponent,
    ContentBlockDetailComponent,
    ContentBlocksComponent,
    DashboardAdminComponent,
    DimensionDetailComponent,
    DimensionsComponent,
    LicenseAdjustCountComponent,
    LicenseDetailComponent,
    LicensesComponent,
    QuestionDetailComponent,
    QuestionsComponent,
    ScoringComponent,
    ScoringMatrixComponent,
    ThemesComponent,
    UsersComponent,
    UserDetailComponent,
  ]

})
export class AdminModule {
}
