<div>
  <!-- Welcome Screen Text -->
  <section class="welcome" [innerHTML]="welcomeScreenText | appTranslate: 'Content'"></section>

  <!-- License Stats -->
  <section class="license-stats">
    <p *ngIf="!purchaseStatsALL">
      {{'DISTRIBUTOR.DASHBOARD.LOADING_STATISTICS'|translate}}
    </p>
    <div class="stats" *ngIf="purchaseStatsALL">

      <!-- Total Licenses Purchased -->
      <div class="total">
        <span class="label">{{'DISTRIBUTOR.DASHBOARD.STATISTICS_TOTAL_PURCHASED'|translate}}</span>
        <span class="value">{{this.purchaseStatsALL.Purchased}}</span>
      </div>
      <!-- Total Licenses Issued -->
      <div class="issued">
        <span class="label">{{'DISTRIBUTOR.DASHBOARD.STATISTICS_TOTAL_ISSUED'|translate}}</span>
        <span class="value">{{this.purchaseStatsALL.Issued}}</span>
      </div>
      <!-- Total Licenses Completed -->
      <div class="completed">
        <span class="label">{{'DISTRIBUTOR.DASHBOARD.STATISTICS_TOTAL_COMPLETED'|translate}}</span>
        <span class="value">{{this.purchaseStatsALL.Submitted}}</span>
      </div>
      <!-- Total Licenses Not Completed (Incomplete) -->
      <div class="incomplete">
        <span class="label">{{'DISTRIBUTOR.DASHBOARD.STATISTICS_TOTAL_INCOMPLETE'|translate}}</span>
        <span class="value">{{this.purchaseStatsALL.Incomplete}}</span>
      </div>
      <!-- Total Licenses Available -->
      <div class="available">
        <span class="label">{{'DISTRIBUTOR.DASHBOARD.STATISTICS_TOTAL_AVAILABLE'|translate}}</span>
        <span class="value">{{this.purchaseStatsALL.Available}}</span>
      </div>
    </div>

  </section>

  <!--
  Distributions Create
  Only display if the distributor has licenses available
  -->
  <section *ngIf="hasLicensesAvailable" class="distribution-create">
    <form *ngIf="createDistributionForm"
          (submit)="createDistribution()"
          [formGroup]="createDistributionForm"
          autocomplete="off"
          class="inline-label dark-theme"
          novalidate>
      <fieldset>
        <legend>
          {{'DISTRIBUTOR.DISTRIBUTION_FORM.CREATE_DISTRIBUTION'|translate}}
          -
          {{'DISTRIBUTOR.DISTRIBUTION_FORM.TOTAL_LICENSES_AVAILABLE'|translate}}: {{licensesAvailable}}
        </legend>

        <div class="p-fluid">
          <div class="grid">
            <!--
            Survey Selection
            Only display if the distributor has licenses available for more than one survey type
            -->
            <div *ngIf="showSurveyDropdown" class="col-12 md:col-3 form-field">
              <label for="SurveyID">{{'DISTRIBUTOR.DISTRIBUTION_FORM.SURVEY'|translate}}</label>
              <p-dropdown id="SurveyID"
                          [formGroup]="createDistributionForm"
                          [options]="luValidSurveys"
                          (onChange)="onChangeSurvey()"
                          optionLabel="Name"
                          optionValue="ID"
                          formControlName="SurveyID"
                          placeholder="{{'DISTRIBUTOR.DISTRIBUTION_FORM.SURVEY'|translate}}">
              </p-dropdown>
              <app-validation-error fieldName="SurveyID" [formGroup]="createDistributionForm" validatorName="required">{{'DISTRIBUTOR.DISTRIBUTION_FORM.ERROR_SURVEY'|translate}}</app-validation-error>
            </div>

            <div class="col-12 md:col form-field">
              <label for="Name">{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_NAME'|translate}}</label>
              <input type="text" pInputText formControlName="Name" id="Name" placeholder="{{'DISTRIBUTOR.DISTRIBUTION_FORM.SET_A_NAME_FOR_THE_DISTRIBUTION'|translate}}"/>
              <app-validation-error fieldName="Name" [formGroup]="createDistributionForm" validatorName="required">{{'DISTRIBUTOR.DISTRIBUTION_FORM.ERROR_NAME'|translate}}</app-validation-error>
            </div>

            <div class="col-12 md:col-2 form-field">
              <label for="ParticipantCount">{{'DISTRIBUTOR.DISTRIBUTION_FORM.PARTICIPANT_COUNT'|translate}}</label>
              <input type="number" pInputText min=1 value="1" [max]="maxParticipants" formControlName="ParticipantCount" id="ParticipantCount" placeholder="{{'DISTRIBUTOR.DISTRIBUTION_FORM.PARTICIPANT_COUNT'|translate}}"/>
              <app-validation-error fieldName="ParticipantCount" [formGroup]="createDistributionForm" validatorName="required">{{'DISTRIBUTOR.DISTRIBUTION_FORM.ERROR_PARTICIPANT_COUNT'|translate}}</app-validation-error>
              <app-validation-error fieldName="ParticipantCount" [formGroup]="createDistributionForm" validatorName="max">{{'DISTRIBUTOR.DISTRIBUTION_FORM.ERROR_MAXIMUM_PARTICIPANT_COUNT'|translate:{count: maxParticipants} }}</app-validation-error>
            </div>

            <!--            <div class="col-12 md:col-3 form-field">-->
            <!--              <label for="LicensesAvailable">{{'DISTRIBUTOR.DISTRIBUTION_FORM.TOTAL_LICENSES_AVAILABLE'|translate}}</label>-->
            <!--              <input pInputText readonly type="number" [value]="licensesAvailable" class="read-only" />-->
            <!--            </div>-->

            <div class="col-12 md:col-9 form-field has-app-notice">
              <p-checkbox [binary]="true" formControlName="CanViewResults" inputId="CanViewResults" label="{{'DISTRIBUTOR.DISTRIBUTION_FORM.CAN_VIEW_RESULTS'|translate}}"></p-checkbox>
            </div>

            <div class="col-12 md:col-3 form-field">
              <p-button type="submit" label="{{'COMMON.UI.CREATE'|translate}}"></p-button>

            </div>

            <div class="col-12 form-field">
              <p *ngIf="true === createDistributionForm.get('CanViewResults')?.value" class="app-notice">
                {{'DISTRIBUTOR.DISTRIBUTION_FORM.CAN_VIEW_RESULTS_YES_NOTICE'|translate}}
              </p>
            </div>

          </div>
        </div>

      </fieldset>

    </form>
  </section>

  <!-- Distributions List -->
  <section class="distribution-list" *ngIf="distributionList">

    <h2>{{'DISTRIBUTOR.DASHBOARD.MANAGE_ASSESSMENT_DISTRIBUTIONS'|translate}}</h2>

    <table *ngIf="this.distributionList.length && surveys" class="tbl-distribution-list">
      <thead>
      <tr>
        <th *ngIf="hasMixedSurveyTypes">{{'DISTRIBUTOR.DISTRIBUTION_FORM.SURVEY'|translate}}</th>
        <th>{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_NAME'|translate}}</th>
        <th>{{'DISTRIBUTOR.DISTRIBUTION_FORM.DATE_CREATED'|translate}}</th>
        <th>{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_STATUS'|translate}}</th>
        <th>{{'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_SENT'|translate}}</th>
        <th>{{'DISTRIBUTOR.DISTRIBUTION_FORM.PARTICIPANT_COUNT'|translate}}</th>
        <th>{{'DISTRIBUTOR.DISTRIBUTION_LIST.PARTICIPANT_ENTERED'|translate}}</th>
        <th>{{'DISTRIBUTOR.DISTRIBUTION_LIST.CAN_VIEW_RESULTS_HEADER'|translate}}</th>
        <th>{{'DISTRIBUTOR.DISTRIBUTION_LIST.REMINDER_DATE'|translate}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <!--
      status-incomplete
      status-pending
      status-sent
      status-complete - aka closed
      -->
      <tr *ngFor="let d of this.distributionList; index as index" [className]="'status-'+d.DistributionStatus.toLowerCase()">

        <td *ngIf="hasMixedSurveyTypes" [attr.data-heading-1]="'DISTRIBUTOR.DISTRIBUTION_FORM.SURVEY'|translate">{{returnSurvey(d.SurveyID)|appTranslate }}</td>
        <td [attr.data-heading-2]="'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_NAME'|translate">{{d.DistributionName}}</td>
        <td [attr.data-heading-3]="'DISTRIBUTOR.DISTRIBUTION_FORM.DATE_CREATED'|translate">{{d.CreatedDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a'}}</td>
        <td [attr.data-heading-4]="'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_STATUS'|translate" title="{{('DISTRIBUTOR.DISTRIBUTION_LIST.DISTRIBUTION_STATUS_'+d.DistributionStatus.toUpperCase())|translate}}" class="status"><span>{{d.DistributionStatus}}</span></td>
        <td [attr.data-heading-5]="'DISTRIBUTOR.DISTRIBUTION_FORM.DISTRIBUTION_SENT'|translate">{{d.SentDate ? (d.SentDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a') : (d.SentDate| yesNo)}}</td>
        <td [attr.data-heading-6]="'DISTRIBUTOR.DISTRIBUTION_FORM.PARTICIPANT_COUNT'|translate">{{d.ParticipantCount}}</td>
        <td [attr.data-heading-7]="'DISTRIBUTOR.DISTRIBUTION_LIST.PARTICIPANT_ENTERED'|translate">{{d.ParticipantEntered}}</td>
        <td [attr.data-heading-8]="'DISTRIBUTOR.DISTRIBUTION_LIST.CAN_VIEW_RESULTS_HEADER'|translate">{{d.CanViewResults | yesNo }}</td>
        <td [attr.data-heading-9]="'DISTRIBUTOR.DISTRIBUTION_LIST.REMINDER_DATE'|translate">{{d.ReminderDate ? (d.ReminderDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a') : 'COMMON.UI.NONE'|translate }}</td>
        <td>
          <a class="edit icon-edit" *ngIf="d.IsEditable" routerLink="/distributor/distributions/edit/{{d.ID}}">{{'COMMON.UI.EDIT'|translate}}</a>
            <a class="info" routerLink="/distributor/distributions/{{d.ID}}/reminders">{{'COMMON.UI.REMINDERS'|translate}}</a>
            <a class="details" *ngIf="!d.IsEditable" routerLink="/distributor/distributions/view/{{d.ID}}">{{'COMMON.UI.VIEW'|translate}}</a>
          <a class="close" *ngIf="'Sent' === d.DistributionStatus" (click)="clickCloseDistribution(d)">{{'COMMON.UI.CLOSE'|translate}}</a>
        </td>

      </tr>
      </tbody>

    </table>

    <div *ngIf="0===this.distributionList.length" class="tbl-distribution-list no-data">{{'DISTRIBUTOR.DISTRIBUTION_LIST.NO_DISTRIBUTIONS_CREATED'|translate}}</div>

  </section>

</div>
