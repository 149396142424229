import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {BaseComponent} from "../../components/base/base.component";
import {AdminService, DashboardAdminDto, DateRangeDto, JsonResponse} from "../../core/api";
import {ProcessManagerService} from "../../service/process-manager.service";

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss'],
})
export class DashboardAdminComponent extends BaseComponent implements OnInit {
  //#region variables

  public displayMonths: any[] = null!
  public displayYears: any[] = null!
  public monthlyStatisticsForm!: FormGroup;
  public statistics!: DashboardAdminDto;
  public yearlyStatisticsForm!: FormGroup;

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    private formbuilder: FormBuilder,
    processManagerService: ProcessManagerService
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['dashboard'])
    this.processManagerService.force()

    this.yearlyStatisticsForm = this.formbuilder.group({
      year: [null, []],
    })
    this.monthlyStatisticsForm = this.formbuilder.group({
      month: [null, []],
    })

    this.loadDashboard();
  }

  public onChangeMonth() {
    let value: string = this.monthlyStatisticsForm.get('month')!.value;
    let values: string[] = value.split('-')

    this.loadMonthlyStatistics(parseInt(values[0]), parseInt(values[1]))
  }

  public onChangeYear() {
    let value: number = this.yearlyStatisticsForm.get('year')!.value;

    this.loadYearlyStatistics(value)
  }

  //#endregion

  //#region business logic

  private loadDashboard() {
    this.adminService.dashboardAdmin().subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.statistics = response.data
        this.displayMonths = this.statistics.PurchaseStatsMonthRange!.map(date => {
          return { Value: date.Year + '-' + date.Month, Label: date.Year + '-' + date.Month }
        })
        this.displayYears = this.statistics.PurchaseStatsYearRange!.map(date => {
          return { Value: date.Year, Label: date.Year }
        })

        let selectedMonth: DateRangeDto = this.statistics.PurchaseStatsMonthRange[this.statistics.PurchaseStatsMonthRange.length - 1]
        this.monthlyStatisticsForm.get('month')?.setValue(selectedMonth.Year + '-' + selectedMonth.Month)

        let selectedYear: DateRangeDto = this.statistics.PurchaseStatsYearRange[this.statistics.PurchaseStatsYearRange.length - 1]
        this.yearlyStatisticsForm.get('year')?.setValue(selectedYear.Year)
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private loadMonthlyStatistics(year: number, month: number) {
    this.processManagerService.force()
    this.adminService.dashboardStatsFilter(year, month).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.statistics = { ...this.statistics, PurchaseStatsMTD: response.data }
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private loadYearlyStatistics(year: number) {
    this.processManagerService.force()
    this.adminService.dashboardStatsFilter(year).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.statistics = { ...this.statistics, PurchaseStatsYTD: response.data }
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });

  }

  //#endregion
}
