import { AbstractControl } from '@angular/forms'

export function PasswordMatchValidator(control: AbstractControl) {
  // business logic

  let password: AbstractControl = control.get('password')!
  let passwordConfirm: AbstractControl = control.get('passwordConfirm')!

  if ((passwordConfirm.value != null) && (passwordConfirm.value != '') && (password.value !== passwordConfirm.value)) {
    passwordConfirm.markAsTouched()
    passwordConfirm.setErrors({ mismatch: true })
    return { mismatch: true }
  }

  return null
}
