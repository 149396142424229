<div class="form-field" [formGroup]="formGroup">
  <label [for]="id">
    {{ label }}
  </label>
  <input
    [type]="type"
    [formControlName]="formControlName"
    [id]="id"
    [placeholder]="placeholder"
  />
</div>
