<section class="container" *ngIf="this.userLicenseDetail">

  <!-- License Stats -->
  <section class="license-stats">
    <div *ngFor="let user of this.userLicenseDetail.LicenseInformation?.items; let index = index">

      <div class="stats">

        <!-- Total Licenses Purchased -->
        <div class="total">
          <span class="label">{{'DISTRIBUTOR.DASHBOARD.STATISTICS_TOTAL_PURCHASED'|translate}}</span>
          <span class="value">{{user.Purchased}}</span>
        </div>
        <!-- Total Licenses Issued -->
        <div class="issued">
          <span class="label">{{'DISTRIBUTOR.DASHBOARD.STATISTICS_TOTAL_ISSUED'|translate}}</span>
          <span class="value">{{user.Issued}}</span>
        </div>

        <!-- Total Licenses Completed -->
        <div class="available">
          <span class="label">{{'DISTRIBUTOR.DASHBOARD.STATISTICS_TOTAL_AVAILABLE'|translate}}</span>
          <span class="value">{{user.Available}}</span>
        </div>

      </div>
    </div>

  </section>

  <table class="tbl-license-detail">
    <thead>
    <tr>
<!--      <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL.DISTRIBUTION_ID'|translate}}</th>-->
      <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL.DISTRIBUTION_NAME'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL.DATE_CREATED'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL.DISTRIBUTION_STATUS'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL.DISTRIBUTION_SENT'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL.PARTICIPANT_COUNT'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL.STATISTICS_TOTAL_COMPLETED'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL.STATISTICS_TOTAL_INCOMPLETE'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST_DETAIL.STATISTICS_TOTAL_IN_PROGRESS'|translate}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody *ngFor="let d of this.userLicenseDetail.DistributionList?.items;let index = index">
    <tr class="row-details">
<!--      <td [attr.data-heading-1]="'ADMIN.USER_LICENSE_LIST_DETAIL.DISTRIBUTION_ID'|translate">{{d.ID}}</td>-->
      <td [attr.data-heading-1]="'ADMIN.USER_LICENSE_LIST_DETAIL.DISTRIBUTION_NAME'|translate">{{d.DistributionName}}</td>
      <td [attr.data-heading-2]="'ADMIN.USER_LICENSE_LIST_DETAIL.DATE_CREATED'|translate">{{d.CreatedDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY'}}</td>
      <td [attr.data-heading-3]="'ADMIN.USER_LICENSE_LIST_DETAIL.DISTRIBUTION_STATUS'|translate">{{d.DistributionStatus}}</td>
      <td [attr.data-heading-4]="'ADMIN.USER_LICENSE_LIST_DETAIL.DISTRIBUTION_SENT'|translate">{{d.SentDate ? (d.SentDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a') : (d.SentDate| yesNo)}}</td>
      <td [attr.data-heading-5]="'ADMIN.USER_LICENSE_LIST_DETAIL.PARTICIPANT_COUNT'|translate">{{d.ParticipantCount}}</td>
      <td [attr.data-heading-6]="'ADMIN.USER_LICENSE_LIST_DETAIL.STATISTICS_TOTAL_COMPLETED'|translate">{{d.TotalSubmitted}}</td>
      <td [attr.data-heading-7]="'ADMIN.USER_LICENSE_LIST_DETAIL.STATISTICS_TOTAL_INCOMPLETE'|translate">{{d.TotalIncomplete}}</td>
      <td [attr.data-heading-8]="'ADMIN.USER_LICENSE_LIST_DETAIL.STATISTICS_TOTAL_IN_PROGRESS'|translate">{{d.TotalNotStarted}}</td>
      <td>
        <p-button class="btn-details btn-txt" (click)="clickExpandDistribution(d)">{{'ADMIN.USER_LICENSE_LIST_DETAIL.VIEW_PARTICIPANTS'|translate}}</p-button>
      </td>
    </tr>

    <tr class="row-participants" *ngIf="d.ParticipantCount && isExpanded(d)">
      <td colspan="10">
        <app-participant-list [distribution]="d" (onError)="onError($event)" (onLoaded)="onLoaded()" (onLoading)="onLoading()"></app-participant-list>
      </td>
    </tr>

    </tbody>
  </table>

</section>
