import {Component, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html'
})
export class InputTextComponent {
  @Input() formControlName!: string
  @Input() placeholder!: string
  @Input() type!: string
  @Input() formGroup!: FormGroup
  @Input() id: string = ''
  @Input() label!: string

  // @Input() options!: {ID: number, Name: string}[]

  constructor() {
    if (!this.id) {
      this.id = this.formControlName
    }
  }
}
