import { Injectable } from "@angular/core";
import { Observable, Subscriber } from "rxjs";
import { JsonResponse, LookupService } from "../core/api";
import { CacheService } from "./cache.service";

/**
 * A service that returns static HTML.
 */
@Injectable()
export class StaticHtmlService {
    //#region Constants
    /**
     * How long a variable should be cached for.
     */
    private static EXPIRY_DURATION: number = 1000 * 60 * 60 * 24 // 1 day
    /**
     * The cache key to use for the footer.
     */
    private static BODY_CLASSES_CACHE_KEY: string = 'body-classes'
    /**
     * The cache key to use for the footer.
     */
    private static FOOTER_CACHE_KEY: string = 'footer-html'
    /**
     * The cache key to use for the head.
     */
    private static HEAD_CACHE_KEY: string = 'head-html'
    /**
     * The cache key to use for the header.
     */
    private static HEADER_CACHE_KEY: string = 'header-html'
    //#endregion

    //#region Constructors
    constructor(
        private cacheService: CacheService,
        private lookupService: LookupService
    ) {}
    //#endregion

    //#region Business Logic
    /**
     * Retrieves classes that should be attached to the body element.
     * @returns {Observable<string>} A space-deliminated list of body classes.
     */
    public bodyClasses() : Observable<string> {
        return this.lookupAndCache(StaticHtmlService.BODY_CLASSES_CACHE_KEY, () => this.lookupService.bodyClasses())
    }

    /**
     * Retrieves the value of the footer HTML.
     * @param {boolean} bypassCache Whether to bypass the cache and reload from source.
     * @returns {Observable<string>} An observer that will return the value of the footer HTML.
     */
    public footer(bypassCache: boolean = false) : Observable<string> {
        return this.lookupAndCache(StaticHtmlService.FOOTER_CACHE_KEY, () => this.lookupService.footerHtml(), bypassCache)
    }

    /**
     * Retrieves the value of the header HTML.
     * @param {boolean} bypassCache Whether to bypass the cache and reload from source.
     * @returns {Observable<string>} An observer that will return the value of the header HTML.
     */
    public header(bypassCache: boolean = false) : Observable<string> {
        return this.lookupAndCache(StaticHtmlService.HEADER_CACHE_KEY, () => this.lookupService.headerHtml(), bypassCache)
    }

    /**
     * Retrieves the value of the head HTML.
     * @returns {Observable<string>} An observer that will return the value of the head HTML.
     */
    public styles() : Observable<string> {
        return this.lookupAndCache(StaticHtmlService.HEAD_CACHE_KEY, () => this.lookupService.headHtml())
    }
    //#endregion

    //#region Internals
    private lookupAndCache(key: string, callback: () => Observable<JsonResponse>, bypassCache: boolean = false) : Observable<string> {
        return new Observable<string>((subscriber: Subscriber<string>) => {
            let html : string | undefined = this.cacheService.longTerm.get(key)
            if (!bypassCache && undefined !== html) {
                subscriber.next(html)
                subscriber.complete()
                return
            }

            callback().subscribe({
                next: (response: JsonResponse) => {
                    html = response.data as string
                    this.cacheService.longTerm.set(key, html, StaticHtmlService.EXPIRY_DURATION)
                    subscriber.next(html)
                    subscriber.complete()
                },
                error: (err: any) => subscriber.error(err)
            })
        })
    }
    //#endregion
}