import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslatePipe } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { BaseComponent } from "../../components/base/base.component";
import { DistributionReminder, DistributorService, JsonResponse } from "../../core/api";
import { AuthService } from "../../service/auth.service";
import { FormValidationService } from "../../service/form-validation.service";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-reminder-detail',
  templateUrl: './reminder-detail.component.html'
})
export class ReminderDetailComponent extends BaseComponent implements OnInit {
  //#region variables

  public distributionReminder!: DistributionReminder
  public distributionReminderForm: FormGroup = null!
  private distributionID!: number
  public instructionsMessage: string = null!
  public minimumDate: Date = null!
  public reminderID: number = null!

  //#endregion

  //#region constructor

  constructor(
    public authService: AuthService,
    private distributorsService: DistributorService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickCancel() {
    this.router.navigate(['/distributor', 'distributions', this.distributionID, 'reminders'])
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['reminder'])
    this.processManagerService.unforce()

    this.minimumDate = new Date()
    this.minimumDate.setDate(this.minimumDate.getDate() + 1)

    this.route.params.subscribe((params: Params) => {
      if (params['distributionID']) {
        this.distributionID = parseInt(params['distributionID'])
      }
      if (params['reminderID']) {
        this.reminderID = parseInt(params['reminderID'])
      }

      if (this.reminderID != null) {
        this.loadReminder()
      }
      else {
        this.createNewReminder()
      }
    })
  }

  public submitReminder() {
    this.saveReminder()
  }

  //#endregion

  //#region business logic

  private createNewReminder() {
    let date: Date = new Date()
    date.setDate(date.getDate() + 14)
    date.setHours(12, 0, 0, 0)

    let distributionReminder: DistributionReminder = {
      ReminderDate: date.toISOString(),
      DistributionID: this.distributionID,
      ReminderSent: null
    }

    this.distributionReminder = distributionReminder

    this.instructionsMessage = 'DISTRIBUTOR.REMINDER_DETAIL.CREATE_INSTRUCTIONS'

    this.initializeForm()
  }

  private initializeForm() {
    this.distributionReminderForm = this.formBuilder.group({
      date: [new Date(this.distributionReminder.ReminderDate!), [Validators.required]],
    })

    if (this.reminderID != null) {
      this.distributionReminderForm.get('date')?.disable()
    }
  }

  private loadReminder() {
    this.processManagerService.force()
    this.distributorsService.editReminder(this.reminderID).subscribe({
      complete: () => this.processManagerService.notify('reminder'),
      next: (response: JsonResponse) => {
        this.distributionReminder = response.data

        this.instructionsMessage = 'DISTRIBUTOR.REMINDER_DETAIL.VIEW_INSTRUCTIONS'

        this.initializeForm()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  public saveReminder() {
    if (this.distributionReminderForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.distributionReminderForm)
      return
    }

    let date: Date = this.distributionReminderForm.get('date')?.value

    this.distributionReminder.ReminderDate = date.toISOString()

    this.processManagerService.force()
    if (this.reminderID == null) {
      this.distributorsService.addReminder(this.distributionReminder).subscribe({
        complete: () => this.processManagerService.unforce(),
        next: (response: JsonResponse) => {
          this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('DISTRIBUTOR.REMINDER_DETAIL.CREATED') })

          this.router.navigate(['/distributor', 'distributions', this.distributionID, 'reminders'])
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      })
    }
  }

  //#endregion
}
