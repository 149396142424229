<div>
  <h1>
    {{'ADMIN.CONTENT_BLOCKS.HEADING'|translate}}
  </h1>
  <p [innerHTML]="'ADMIN.CONTENT_BLOCKS.INSTRUCTIONS'|translate"></p>
  <ul class="block-links">
    <li *ngFor="let item of contentBlocks">
      <a [routerLink]="['/admin', 'content', 'edit', item.ID]">{{item.Name}}</a>
    </li>
  </ul>
</div>
