import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { AppRoutingModule } from "../app-routing.module";
import { AppComponentsModule } from '../components/app-components.module';
import { PipesModule } from '../pipes/pipes.module';
import { PrimeNgModule } from "../prime-ng-module";
import { AssessmentQuestionComponent } from './assessment-question/assessment-question.component';
import { ParticipantComponent } from "./participant/participant.component";

@NgModule({
  declarations: [
    AssessmentQuestionComponent,
    ParticipantComponent,
  ],
  imports: [
    AppComponentsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    PrimeNgModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    PipesModule,
  ],
  exports: [],
  providers: [
  ]
})
export class ParticipantModule {
}
