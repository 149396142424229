/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class ViewDistributorParticipantsDto
 */
export interface ViewDistributorParticipantsDto { 
    DistributionID: number;
    DistributionName: string;
    DistributorID: number;
    ParticipantCount: number;
    CreatedDate: string;
    SentDate?: string | null;
    SurveyID: number;
    SurveyName: string;
    QuestionCount: number;
    DistributionStatusID: number;
    DistributionStatus: string;
    TotalAssessments: number;
    TotalSubmitted: number;
    TotalIncomplete: number;
    TotalNotStarted: number;
    AssessmentStatusID: number;
    ParticipantID: number;
    AssessmentStatus: string;
    QuestionsAnswered: number;
    ParticipantFirstName?: string | null;
    ParticipantLastName?: string | null;
    ParticipantEmail: string;
    ParticipantRoleID: number;
    ParticipantStatusID: number;
    ParticipantRoleName: string;
    ParticipantStatusName: string;
    LicenseID: number;
    LicenseCode: string;
}

