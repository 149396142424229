<div *ngIf="filterFormGroup">
  <section class="search-filters dark-theme">
    <form *ngIf="filterFormGroup" (submit)="submitSearchForm()" [formGroup]="filterFormGroup" autocomplete="off" novalidate>
      <fieldset>
        <legend>{{'ADMIN.USER_LICENSE_LIST.FILTER_LICENSES'|translate}}</legend>
        <div class="p-fluid">
          <div class="grid filter-wrap">
            <div class="col-12 md:col-4 form-field" [formGroup]="filterFormGroup">
              <label for="UserStatus">{{'ADMIN.USER_DETAIL.USER_STATUS'|translate}}</label>
              <p-dropdown appendTo="body"
                          id="UserRole"
                          [formGroup]="filterFormGroup"
                          [options]="userStatuses"
                          optionLabel="Name"
                          optionValue="ID"
                          formControlName="UserStatus">
              </p-dropdown>
            </div>
            <div class="col-12 md:col-4 form-field">
              <label for="SearchTerm">{{'COMMON.UI.SEARCH'|translate}}</label>
              <input type="text" pInputText formControlName="SearchTerm" id="SearchTerm" />
            </div>
            <div class="col-12 md:col-4 form-field flex align-items-end">
              <p-checkbox class="mb-4" [binary]="true" formControlName="AvailableOnly" inputId="AvailableOnly" label="{{'ADMIN.USER_LICENSE_LIST.AVAILABLE_ONLY'|translate}}"></p-checkbox>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col-2 button-wrap">
            <p-button type="submit" label="{{'COMMON.UI.FILTER'|translate}}"></p-button>
          </div>
        </div>
      </fieldset>
    </form>
  </section>

  <section class="container" *ngIf="uLicenseSearch.items">
    <app-pagination [currentPage]="uLicenseSearch.page ?? 1" [perPage]="uLicenseSearch.perPage ?? 2" [totalItems]="uLicenseSearch.totalItems ?? 0" (goTo)="onGoTo($event)"></app-pagination>
  </section>

  <table class="tbl-licenses" *ngIf="uLicenseSearch.items">
    <thead>
    <tr>
      <th>{{'ADMIN.USER_DETAIL.FIRST_NAME'|translate}}</th>
      <th>{{'ADMIN.USER_DETAIL.LAST_NAME'|translate}}</th>
      <th>{{'ADMIN.USER_DETAIL.EMAIL'|translate}}</th>
      <th>{{'ADMIN.USER_DETAIL.USER_STATUS'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST.PURCHASED'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST.ISSUED'|translate}}</th>
      <th>{{'ADMIN.USER_LICENSE_LIST.AVAILABLE'|translate}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of uLicenseSearch.items;let index = index">
      <td [attr.data-heading-1]="'ADMIN.USER_DETAIL.FIRST_NAME'|translate">{{user.FirstName}}</td>
      <td [attr.data-heading-2]="'ADMIN.USER_DETAIL.LAST_NAME'|translate">{{user.LastName}}</td>
      <td [attr.data-heading-3]="'ADMIN.USER_DETAIL.EMAIL'|translate">{{user.Email}}</td>
      <td [attr.data-heading-4]="'ADMIN.USER_DETAIL.USER_STATUS'|translate">{{user.UserStatus}}</td>
      <td [attr.data-heading-5]="'ADMIN.USER_LICENSE_LIST.PURCHASED'|translate">{{user.Purchased}}</td>
      <td [attr.data-heading-6]="'ADMIN.USER_LICENSE_LIST.ISSUED'|translate">{{user.Issued}}</td>
      <td [attr.data-heading-7]="'ADMIN.USER_LICENSE_LIST.AVAILABLE'|translate">{{user.Available}}</td>
      <td>
        <a class="details" routerLink="details/{{user.UserID}}">{{'COMMON.UI.VIEW_DETAILS'|translate}}</a>
        <a class="icon-edit manage" routerLink="adjust-count/{{user.UserID}}">{{'ADMIN.USER_LICENSE_LIST.MANAGE_USER_LICENSES'|translate}}</a>
      </td>
    </tr>
    </tbody>
  </table>
</div>

