import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../../components/base/base.component";
import { JsonResponse, LookupService, Theme } from "../../core/api";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss']
})
export class ThemesComponent extends BaseComponent implements OnInit {
  //#region variables

  public themes!: Theme[]

  //#endregion

  //#region constructor

  constructor(
    private lookupService: LookupService,
    processManagerService: ProcessManagerService,
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['themes'])

    this.loadThemes()
  }

  //#endregion

  //#region business logic

  private loadThemes() {
    this.processManagerService.force()
    this.lookupService.listThemes().subscribe({
      complete: () => this.processManagerService.notify('themes'),
      next: (response: JsonResponse) => {
        this.themes = Object.keys(response.data).map(k => {
          return response.data[k];
        })
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  //#endregion
}
