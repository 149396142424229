<div>
  <h1>
    {{'ADMIN.DIMENSIONS.HEADING'|translate}}
  </h1>

  <p [innerHTML]="'ADMIN.DIMENSIONS.INSTRUCTIONS'|translate"></p>

  <section class="dark-theme search-filters">
    <form *ngIf="filterFormGroup" (submit)="submitSearchForm()" [formGroup]="filterFormGroup" autocomplete="off" novalidate>
      <fieldset>
        <legend>{{'ADMIN.DIMENSIONS.FILTER_DIMENSIONS'|translate}}</legend>
        <div class="form-field" [formGroup]="filterFormGroup">
          <label for="SurveyID">{{'ADMIN.DIMENSIONS.SURVEY_NAME'|translate}}</label>
          <p-dropdown id="SurveyID"
                      [formGroup]="filterFormGroup"
                      [options]="displaySurveys"
                      optionLabel="Name"
                      optionValue="ID"
                      formControlName="SurveyID"
                      placeholder="{{'ADMIN.DIMENSIONS.SURVEY_NAME'|translate}}">
          </p-dropdown>
        </div>
        <p-button type="submit" label="{{'COMMON.UI.FILTER'|translate}}"></p-button>
      </fieldset>
    </form>
  </section>

  <section class="container" *ngIf="dimensions">
    <table class="tbl-list-dimension">
      <thead>
        <tr>
          <th class="name">{{'ADMIN.DIMENSIONS.NAME'|translate}}</th>
          <th class="text">{{'ADMIN.DIMENSIONS.TEXT'|translate}}</th>
          <th class="updated-by">{{'ADMIN.DIMENSIONS.UPDATED_BY'|translate}}</th>
          <th class="update-date">{{'ADMIN.DIMENSIONS.UPDATED_DATE'|translate}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dimension of this.dimensions; index as index">
          <td [attr.data-heading-1]="'ADMIN.DIMENSIONS.NAME'|translate" class="name">{{dimension|appTranslate :'Name'}}</td>
          <td [attr.data-heading-2]="'ADMIN.DIMENSIONS.TEXT'|translate" class="text">{{dimension|appTranslate :'Text'}}</td>
          <td [attr.data-heading-3]="'ADMIN.DIMENSIONS.UPDATED_BY'|translate" class="updated-by">{{dimension.UpdatedBy?.FirstName}} {{dimension.UpdatedBy?.LastName}}</td>
          <td [attr.data-heading-4]="'ADMIN.DIMENSIONS.UPDATED_DATE'|translate" class="update-date">
            {{dimension.UpdatedDate! | amLocale : authService.languageCode | amDateFormat: 'MMM D, YYYY h:mm a' }}
          </td>
          <td>
            <a class="edit icon-edit" [routerLink]="['/admin', 'assessments', 'dimensions', 'edit', dimension.ID]">{{'COMMON.UI.EDIT'|translate}}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</div>
