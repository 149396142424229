<section>
  <h1>{{'ADMIN.DASHBOARD.STATISTICS'|translate}}</h1>
  <div class="p-fluid">
    <div class="grid heading">
      <div class="col-12 md:col-6 lg:col-9">
        <h2>{{'ADMIN.DASHBOARD.STATISTICS_YTD'|translate}}</h2>
      </div>
      <div class="col-12 md:col-6 lg:col-3 form-field">
        <form [formGroup]="yearlyStatisticsForm" *ngIf="statistics && yearlyStatisticsForm">
          <p-dropdown id="year"
                      [options]="displayYears"
                      optionLabel="Label"
                      optionValue="Value"
                      formControlName="year"
                      (onChange)="onChangeYear()">
          </p-dropdown>
        </form>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <section class="license-stats">
          <p *ngIf="!statistics">
            {{'ADMIN.DASHBOARD.LOADING_STATISTICS'|translate}}
          </p>
          <div class="stats" *ngIf="statistics">
            <div class="total">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_PURCHASED'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsYTD.Purchased}}</span>
            </div>
            <div class="issued">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_ISSUED'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsYTD.Issued}}</span>
            </div>
            <div class="completed">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_COMPLETED'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsYTD.Submitted}}</span>
            </div>
            <div class="incomplete">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_INCOMPLETE'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsYTD.Incomplete}}</span>
            </div>
            <div class="available">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_AVAILABLE'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsYTD.Available}}</span>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="grid heading">
      <div class="col-12 md:col-6 lg:col-9">
        <h2>{{'ADMIN.DASHBOARD.STATISTICS_MTD'|translate}}</h2>
      </div>
      <div class="col-12 md:col-6 lg:col-3 form-field">
        <form [formGroup]="monthlyStatisticsForm" *ngIf="statistics && monthlyStatisticsForm">
          <p-dropdown id="month"
                      [options]="displayMonths"
                      optionLabel="Label"
                      optionValue="Value"
                      formControlName="month"
                      (onChange)="onChangeMonth()">
          </p-dropdown>
        </form>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <section class="license-stats">
          <p *ngIf="!statistics">
            {{'ADMIN.DASHBOARD.LOADING_STATISTICS'|translate}}
          </p>
          <div class="stats" *ngIf="statistics">
            <div class="total">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_PURCHASED'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsMTD.Purchased}}</span>
            </div>
            <div class="issued">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_ISSUED'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsMTD.Issued}}</span>
            </div>
            <div class="completed">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_COMPLETED'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsMTD.Submitted}}</span>
            </div>
            <div class="incomplete">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_INCOMPLETE'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsMTD.Incomplete}}</span>
            </div>
            <div class="available">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_AVAILABLE'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsMTD.Available}}</span>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="grid heading">
      <div class="col-12">
        <h2>{{'ADMIN.DASHBOARD.STATISTICS_ALL'|translate}}</h2>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <section class="license-stats">
          <p *ngIf="!statistics">
            {{'ADMIN.DASHBOARD.LOADING_STATISTICS'|translate}}
          </p>
          <div class="stats" *ngIf="statistics">
            <div class="total">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_PURCHASED'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsALL.Purchased}}</span>
            </div>
            <div class="issued">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_ISSUED'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsALL.Issued}}</span>
            </div>
            <div class="completed">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_COMPLETED'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsALL.Submitted}}</span>
            </div>
            <div class="incomplete">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_INCOMPLETE'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsALL.Incomplete}}</span>
            </div>
            <div class="available">
              <span class="label">{{'ADMIN.DASHBOARD.STATISTICS_TOTAL_AVAILABLE'|translate}}</span>
              <span class="value">{{this.statistics.PurchaseStatsALL.Available}}</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

</section>
