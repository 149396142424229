/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class DistributionSaveDto
 */
export interface DistributionSave { 
    /**
     * The ID of the Distribution
     */
    ID: number;
    /**
     * The name for the distribution
     */
    Name: string;
    /**
     * The number of participants to add to the new distribution
     */
    ParticipantCount: number;
    /**
     * Indicates if Global Admin users can View Participant Results
     */
    CanViewResults: boolean;
}

