import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core'
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular'
import { LanguagesService } from '../../../service/languages.service'
import * as CustomEditor from '../ckeditor/ckeditor.js'
import { LocalizationInputBaseComponent } from '../localization-input-base/localization-input-base.component'

@Component({
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: LocalizationInputBaseComponent, useExisting: LocalizationEditorComponent }],
  selector: 'app-localization-editor',
  templateUrl: './localization-editor.component.html'
})
export class LocalizationEditorComponent extends LocalizationInputBaseComponent {
  // variables

  public customEditor: any = CustomEditor
  public maxLengthErrorMessage: string = ''

  // properties

  @Input() configuration: any = null
  @Input() height: number = null!
  @ViewChild('editorElement') editorComponent: CKEditorComponent = null!

  public get editorHeight(): any {
    if ((this.height != undefined) && (this.height != null)) {
      return this.height.toString() + 'px'
    }

    return '300px'
  }

  // constructor

  constructor(protected override languagesService: LanguagesService) {
    super(languagesService)
  }

  // event handlers

  public onEditorReady(editor: any): void {
    editor.editing.view.change((writer: any) => {
      writer.setStyle('height', this.editorHeight, editor.editing.view.document.getRoot());
    })
  }

  override ngOnInit() {
    super.ngOnInit()

    this.initialize()
  }

  // business logic

  private initialize() {
    if (this.maxLength) {
      this.maxLengthErrorMessage = 'The ' + this.displayName.toLowerCase() + ' cannot be greater than ' + this.maxLength + ' characters'
    }
  }
}
