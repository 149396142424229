import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appAdminRoutes } from './admin/admin.routes';
import { appDistributorRoutes } from "./distributor/distributor.routes";
import { ForgotPasswordComponent } from "./guest/forgot-password.component";
import { RecoverAssessmentLinkComponent } from "./guest/recover-assessment-link/recover-assessment-link.component";
import { GuestLayoutComponent } from "./layout/guest-layout/guest-layout.component";
import { ParticipantLayoutComponent } from "./layout/participant-layout/participant-layout.component";
import { LoginComponent } from "./login/login.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { ParticipantComponent } from "./participant/participant/participant.component";

const routes: Routes = [

  {
    path: '',
    component: GuestLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'logout',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'forgot-password/:token',
        component: ForgotPasswordComponent,
      },
      // participantWelcome
      {
        path: 'welcome/:token',
        component: ParticipantComponent,
      },
      // fallback route for missing token
      {
        path: 'welcome',
        component: ParticipantComponent,
      },
      {
        path: 'recover-assessment-link',
        component: RecoverAssessmentLinkComponent,
      },
      {
        path: '',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'admin',
    children: appAdminRoutes
  },
  {
    path: 'distributor',
    children: appDistributorRoutes
  },
  {
    path: 'participant',
    component: ParticipantLayoutComponent,
    children: [
      {
        path: '',
        component: ParticipantComponent
      },
    ]
  },
  {
    path: '**', component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }
  )],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
