import { Route } from "@angular/router";
import { ConfirmationEmailDetailComponent } from "../components/confirmation-email-detail/confirmation-email-detail.component";
import { InvitationEmailDetailComponent } from '../components/invitation-email-detail/invitation-email-detail.component';
import { ReminderEmailDetailComponent } from "../components/reminder-email-detail/reminder-email-detail.component";
import { EnumUserRoles } from "../core/api";
import { UserAuthGuard } from "../guards/user-auth.guard";
import { AdminLayoutComponent } from "../layout/admin-layout/admin-layout.component";
import { AdminComponent } from "./admin.component";
import { AssessmentsComponent } from "./assessments/assessments.component";
import { ComplimentaryLicenseDetailComponent } from "./complimentary-license-detail/complimentary-license-detail.component";
import { ComplimentaryLicensesComponent } from "./complimentary-licenses/complimentary-licenses.component";
import { ContentBlockDetailComponent } from "./content-block-detail/content-block-detail.component";
import { ContentBlocksComponent } from "./content-blocks/content-blocks.component";
import { DashboardAdminComponent } from "./dashboard-admin/dashboard-admin.component";
import { DimensionDetailComponent } from "./dimension-detail/dimension-detail.component";
import { DimensionsComponent } from "./dimensions/dimensions.component";
import { LicenseAdjustCountComponent } from "./license-adjust-count/license-adjust-count.component";
import { LicenseDetailComponent } from "./license-detail/license-detail.component";
import { LicensesComponent } from "./licenses/licenses.component";
import { QuestionDetailComponent } from "./question-detail/question-detail.component";
import { QuestionsComponent } from "./questions/questions.component";
import { ScoringMatrixComponent } from "./scoring-matrix/scoring-matrix.component";
import { ScoringComponent } from "./scoring/scoring.component";
import { ThemesComponent } from "./themes/themes.component";
import { UserDetailComponent } from "./user-detail/user-detail.component";
import { UsersComponent } from "./users/users.component";

export const appAdminRoutes: Route[] = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [UserAuthGuard],
    data: {role: EnumUserRoles.Admin},
    children: [
      {
        path: '',
        component: AdminComponent,
        // Admin child route paths
        children: [
          {
            path: 'dashboard',
            component: DashboardAdminComponent,
          },
          {
            path: 'users',
            component: UsersComponent,
          },
          {
            path: 'users/create',
            component: UserDetailComponent
          },
          {
            path: 'users/edit/:userID',
            component: UserDetailComponent
          },

          // --- Assessments ---
          {
            path: 'assessments',
            component: AssessmentsComponent,
            children: [

              // --- Dimensions ---
              {
                path: 'dimensions',
                component: DimensionsComponent,
              },
              {
                path: 'dimensions/edit/:dimensionID',
                component: DimensionDetailComponent
              },

              // --- Themes ---
              {
                path: 'themes',
                component: ThemesComponent,
              },

              // --- Questions ---
              {
                path: 'questions',
                component: QuestionsComponent,
              },
              {
                path: 'questions/edit/:questionID',
                component: QuestionDetailComponent
              },

              // --- Scoring ---
              {
                path: 'scoring',
                component: ScoringComponent,
              },
              {
                path: 'scoring/edit/:surveyID',
                component: ScoringMatrixComponent
              },
            ]
          },

          // --- Licenses ---
          {
            path: 'licenses',
            component: LicensesComponent,
          },
          {
            path: 'licenses/complimentary',
            component: ComplimentaryLicensesComponent,
          },
          {
            path: 'licenses/complimentary/issue',
            component: ComplimentaryLicenseDetailComponent,
          },
          // --- Licenses: View Details ---
          {
            path: 'licenses/adjust-count/:userID',
            component: LicenseAdjustCountComponent,
          },
          {
            path: 'licenses/details/:userID',
            component: LicenseDetailComponent,
          },
          {
            path: 'content',
            component: ContentBlocksComponent,
          },
          {
            path: 'content/edit/:contentBlockID',
            component: ContentBlockDetailComponent,
          },
          {
            path: 'content/confirmation',
            component: ConfirmationEmailDetailComponent,
            data: { isAdmin: true }
          },
          {
            path: 'content/invitation',
            component: InvitationEmailDetailComponent,
            data: { isAdmin: true }
          },
          {
            path: 'content/reminder',
            component: ReminderEmailDetailComponent,
            data: { isAdmin: true }
          },
        ]
      },
    ],
  }
]
