import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AssessmentResponseChangeEmail} from "src/app/core/api/model/assessmentResponseChangeEmail";
import {Identity} from "src/app/core/api/model/identity";
import {BaseComponent} from "../../components/base/base.component";
import {
  DistributionResend,
  DistributionView,
  DistributorService,
  JsonResponse,
  LookupDto,
  LookupService,
  Theme
} from "../../core/api";
import {IIDNamePair} from "../../interfaces/IIDNamePair";
import {AppTranslatePipe} from "../../pipes/app-translate.pipe";
import {AuthService} from "../../service/auth.service";
import {ProcessManagerService} from "../../service/process-manager.service";
import {environment} from "../../../environments/environment";
import {MessageService} from "primeng/api";
import {TranslatePipe} from "@ngx-translate/core";

@Component({
  selector: 'app-distribution-view',
  templateUrl: './distribution-view.component.html',
})
export class DistributionViewComponent extends BaseComponent implements OnInit {

  //#region Properties
  public showButtonResendInvitation: boolean = false
  public distribution!: DistributionView
  public filterFormGroup!: FormGroup

  private distributionID!: number
  public distributionForm!: FormGroup
  public distributionParticipantsForm!: FormGroup

  // Lookups
  public luSurveys!: IIDNamePair[]
  public luDistributionStatuses!: IIDNamePair[]
  public luAssessmentStatuses!: IIDNamePair[]

  // Resend invitations
  // resendSuccess: boolean = false;
  // resendError: boolean = false;

  /**
   * Return a list of participants (Users) filtered case-insensitive by the
   */
  get filteredParticipantsFormArray(): FormArray {
    const faControls = this.participantsFormArray.controls as FormGroup[]
    let filterAssessmentId = parseInt(this.filterFormGroup.controls['AssessmentStatusID'].value)
    let filterSearchTerm = this.filterFormGroup.controls['SearchTerm'].value?.toLowerCase()
    if ((0 > filterAssessmentId) && !filterSearchTerm) {
      return this.participantsFormArray
    }
    let rv: FormArray = this.formBuilder.array([])
    let filteredControls = faControls.filter((formGroup, index) => {
      let fgControls = formGroup.controls
      let matchedStatus = false;
      if ((0 < filterAssessmentId)) {
        let assessmentIdForm = parseInt(fgControls['AssessmentStatusID'].value);
        if (assessmentIdForm === filterAssessmentId) {
          matchedStatus = true
        }
      } else {
        matchedStatus = true
      }

      let matchedText = false;
      if (filterSearchTerm) {
        let haystacks: string[] = ['FirstName', 'LastName', 'Email']
        for (let property of haystacks) {
          let haystack: string = fgControls[property].value?.toLowerCase();
          if (haystack) {
            if (-1 !== haystack.indexOf(filterSearchTerm)) {
              matchedText = true
            }
          }
        }
      } else {
        matchedText = true
      }
      return matchedStatus && matchedText
    })
    for (let control of filteredControls) {
      rv.push(control)
    }
    return rv

  }

  get participantsFormArray(): FormArray {
    return this.distributionParticipantsForm.get('faParticipants') as FormArray
  }

  get isComplete(): boolean {
    return 'Complete' === this.distribution.DistributionStatus.Name
  }

  //#endregion

  //#region constructor

  constructor(
    public authService: AuthService,
    processManagerService: ProcessManagerService,
    private messageService: MessageService,
    private translate: TranslatePipe,
    private appTranslatePipe: AppTranslatePipe,
    private lookupService: LookupService,
    private distributorService: DistributorService,
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers
  override ngOnInit(): void {
    super.ngOnInit()

    // load distribution ID from URL
    this.route.params.subscribe((params: Params) => {
      this.distributionID = parseInt(params['distributionID'])
      this.loadDistribution()
    })
  }

  public isFieldDisabled(formGroup: AbstractControl, fieldName: string): boolean {
    return !!formGroup.get(fieldName)?.disabled
  }

  public editParticipant(formGroup: AbstractControl) {
    formGroup.get('Email')?.enable()
  }

  public editParticipantCancel(formGroup: AbstractControl) {
    formGroup.get('Email')?.disable()
    formGroup.get('Email')?.setValue(formGroup.get('OriginalEmail')?.value)
  }

  public editParticipantSave(formGroup: AbstractControl) {
    this.changeUserEmailAddress(formGroup)
  }

  private changeUserEmailAddress(formGroup: AbstractControl) {

    let dto: AssessmentResponseChangeEmail = {
      /** ID of the assessment response !!! Not the user !!! */
      ID: formGroup.get('ID')?.value,
      Email: formGroup.get('Email')?.value,
    }

    this.processManagerService.addProcess('changeUserEmailAddress')
    this.distributorService.changeUserEmailAddress(dto).subscribe({
      next: (response: JsonResponse) => {
        this.processManagerService.notify('changeUserEmailAddress')
        // Nothing to do other than handle the error if it happens
        formGroup.get('Email')?.disable()
        formGroup.get('OriginalEmail')?.setValue(formGroup.get('Email')?.value)
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  /**
   * @TODO if any buttons in the grid are checked - show the resend invitation button
   */
  public toggleResendEmailButton() {
    let count = this.participantsFormArray.controls.filter((formGroup, index) => {
        return (formGroup.get('Resend') as FormControl).value
      }
    ).length;
    this.showButtonResendInvitation = (0 < count)
  }

  /**
   * send the invitations
   * uncheck all the checked checkboxs
   */
  resendInvitations() {
    let dto: DistributionResend = {ID: this.distribution.ID, Assessments: []}
    let sendable: AbstractControl[] = this.participantsFormArray.controls.filter((formGroup, index) => {
        return (formGroup.get('Resend') as FormControl).value
      }
    )
    sendable.forEach((element, index) => {
      let a: Identity = {
        ID: element.get('ID')?.value
      }
      dto.Assessments.push(a)
    });

    this.processManagerService.addProcess('resendInvitations')
    this.distributorService.resendInvitations(dto).subscribe(
      {
        complete: () => this.processManagerService.notify('resendInvitations'),
        next: (response: JsonResponse) => {
          let data = response.data

          let sent = data.Sent ? data.Sent : []
          // uncheck all the checked checkboxs
          sent.forEach((p, index) => {
            let match: AbstractControl | undefined = sendable.find((c: AbstractControl) => p.ID === (c as FormControl).get('ID')?.value)
            if (match) {
              match.get('Resend')?.setValue(false)
            }
          });

          // hide the resend invitation button
          let isSuccess = sent.length === dto.Assessments.length;
          this.showButtonResendInvitation = !isSuccess
          // show the success or error message
          // this.resendError = !isSuccess
          // this.resendSuccess = isSuccess

          if (isSuccess) {
            this.messageService.add({
              life: 10000,
              severity: 'info',
              detail: this.translate.transform('DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.RESEND_INVITATION_SUCCESS')
            })
          } else {
            this.messageService.add({
              life: 10000,
              severity: 'error',
              detail: this.translate.transform('DISTRIBUTOR.DISTRIBUTION_PARTICIPANTS.RESEND_INVITATION_COULD_NOT_RESEND_ALL_INVITATIONS')
            })
          }


        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )

  }

  //#endregion

  //#region internals
  // Lookups
  private loadSurveys() {
    this.processManagerService.addProcess('listSurveys')
    this.lookupService.listSurveys().subscribe(
      {
        complete: () => this.processManagerService.notify('listSurveys'),
        next: (response: JsonResponse) => {
          this.luSurveys = Object.keys(response.data).map(key => {
            let entity: Theme = response.data[key]
            return {ID: entity.ID ?? 0, Name: entity.Name ?? ''};
          });

          this.initializeForm()
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )
  }

  private loadAssessmentStatuses() {
    this.processManagerService.addProcess('listAssessmentStatuses')
    this.lookupService.listAssessmentStatuses().subscribe({
      next: (response: JsonResponse) => {
        this.processManagerService.notify('listAssessmentStatuses')
        this.luAssessmentStatuses = Object.keys(response.data).map(key => {
          /** @todo replace UserStatusDto with generic Lookup */
          let entity: LookupDto = response.data[key]
          return entity
        });
        this.luAssessmentStatuses.unshift({ID: -1, Name: 'All'})

        this.initializeForm()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public getSurveyName(surveyID: number) {
    if (!this.luSurveys) {
      return null
    }

    /** @todo - this.luSurveys needs to be a list of Surveys for translation */
    return this.luSurveys.filter((obj) => {
      return (surveyID === obj.ID);
    })
      .map(obj => (
        /** @todo - Translation */
        //obj.Name
        obj.Name
      ))
  }

  private initializeForm() {
    if (
      this.distribution
      && this.luDistributionStatuses
      && this.luAssessmentStatuses
      && !this.distributionForm
      && !this.filterFormGroup
    ) {

      this.buildDistributionForm();
      this.buildParticipantsForm()

      // -----------------------------------------------------------------
      // Create the Participants filter form
      // -----------------------------------------------------------------
      this.filterFormGroup = this.formBuilder.group({
        AssessmentStatusID: [],
        SearchTerm: [],
      })
      this.filterFormGroup.controls['AssessmentStatusID'].setValue(-1)
    }
  }

  /**
   * Takes the properties of 'this.distribution' and populates
   * them into distributionForm with their current values
   * @private
   */
  private buildDistributionForm() {
    this.distributionForm = this.formBuilder.group({
      Name: [this.distribution.Name],
      //SurveyID: [this.distribution.SurveyID],
      Survey: [this.getSurveyName(this.distribution.Survey.ID)],
      ParticipantCount: [this.distribution.ParticipantCount],
      CanViewResults: [this.distribution.CanViewResults],
    })
  }

  /**
   * Takes the participants of 'this.distribution.DistributionParticipant'
   * and populates them into the form in the UI with their current values
   * @private
   */
  private buildParticipantsForm() {

    // -----------------------------------------------------------------
    // Create the Participants form with a 'placeholder' for the participant rows
    // Populate the participant form with the participants
    // -----------------------------------------------------------------
    this.distributionParticipantsForm = this.formBuilder.group({
      faParticipants: this.formBuilder.array([]),
    })

    let assessmentResponses = this.distribution.AssessmentResponse ? this.distribution.AssessmentResponse : [];
    for (let assessmentResponse of assessmentResponses) {

      const formGroup: FormGroup = this.formBuilder.group({
        //show: true,
        Resend: [false],
        ID: assessmentResponse.ID,
        FirstName: assessmentResponse.User.FirstName,
        LastName: assessmentResponse.User.LastName,
        Email: [
          {
            value: assessmentResponse.User.Email,
            disabled: true
          },
          [Validators.email]
        ],
        //LicenseCode: assessmentResponse.License.LicenseCode,
        AccessToken: assessmentResponse.AccessToken,
        AssessmentStatusID: assessmentResponse.Status.ID,
        AssessmentStatus: this.appTranslatePipe.transform(assessmentResponse.Status, 'Name'),
        QuestionsAnswered: assessmentResponse.AnswerCount + ' / ' + this.distribution.Survey?.QuestionCount,
        OriginalEmail: assessmentResponse.User.Email
      });

      this.participantsFormArray.push(formGroup)
    }
  }

  private loadDistribution() {
    this.processManagerService.addProcess('viewDistribution')
    this.distributorService.viewDistribution(this.distributionID).subscribe(
      {
        complete: () => this.processManagerService.notify('viewDistribution'),
        next: (response: JsonResponse) => {
          this.distribution = response.data

          this.loadDistributionStatuses()
          this.loadSurveys()
          this.loadAssessmentStatuses()
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    );
  }

  private loadDistributionStatuses() {
    this.processManagerService.addProcess('loadDistributionStatuses')
    this.lookupService.listDistributionStatuses().subscribe(
      {
        complete: () => this.processManagerService.notify('loadDistributionStatuses'),
        next: (response: JsonResponse) => {
          this.luDistributionStatuses = Object.keys(response.data).map(key => {
            let entity: LookupDto = response.data[key]
            return entity;
          });

          this.initializeForm()
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )
  }

  //#endregion

  copyAccessLinkToClipboard(formGroup: AbstractControl) {
    let url = window.location.origin + '/welcome/' + formGroup.get('AccessToken')?.value;
    navigator.clipboard.writeText(url)
      .then(
        () => {
          /* clipboard successfully set */
        },
        () => {
          /*
          clipboard write failed
          Probably non-https lan or local development
          */
          //alert('Failed to copy link')
          if (!environment.production) {
            let url = window.location.origin + '/welcome/' + formGroup.get('AccessToken')?.value;
            let dummy = document.createElement('input')
            document.body.appendChild(dummy);
            dummy.value = url;
            dummy.select();
            document.execCommand('copy');
            document.body.removeChild(dummy);
          }
        }
      );
  }

}
