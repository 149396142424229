/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ComplimentaryLicense } from './complimentaryLicense';


export interface ComplimentaryLicenseSearchAllOf { 
    /**
     * ID of the Assessment Status to filter by
     */
    AssessmentStatusID?: number | null;
    /**
     * ID of the User to filter by (used internally)
     */
    UserID?: number | null;
    /**
     * Search terms
     */
    SearchTerm?: string | null;
    /**
     * List of ComplimentaryLicense DTOs
     */
    items?: Set<ComplimentaryLicense>;
}

