import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Observable } from 'rxjs';
import { BaseComponent } from "./components/base/base.component";
import { EnumUserRoles, LookupService } from './core/api';
import { AuthService } from './service/auth.service';
import { ProcessManagerService } from "./service/process-manager.service";
import { StaticHtmlService } from './service/static-html.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
  public footer?: string
  public header?: string
  public initialOverlay: boolean = true

  public title: string = 'ConflictAbility Assessment';
  public showMobileNav: boolean = false

  public get loading(): boolean {
    return !this.processManagerService.isComplete()
  }

  get showPopup(): boolean {
    return !!BaseComponent.exceptionToShow;
  }

  set showPopup(b: boolean) {
    BaseComponent.exceptionToShow = '';
  }

  get popupText(): string {
    return this.showPopup ? BaseComponent.exceptionToShow : ''
  }

  public get userDashboardLink(): string[] {
    switch (this.authService.userRole) {
      case EnumUserRoles.Admin:
      case EnumUserRoles.System:
      case EnumUserRoles.WordPress:
        return ['/admin/dashboard']
      case EnumUserRoles.Distributor:
        return ['/distributor/dashboard']
      default:
        return ['/welcome']
    }
  }

  public constructor(
    public authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    processManagerService: ProcessManagerService,
    private router: Router,
    private staticHtmlService: StaticHtmlService,
    public translateService: TranslateService
  ) {
    super(processManagerService);
    this.translateService.addLangs(['en'])
    this.translateService.setDefaultLang('en')
    this.translateService.use('en')

    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          const navigationElement = document.querySelector('.elementor-menu-toggle')
          
          if (navigationElement) {
            navigationElement.classList.remove('elementor-active')
          }
        }
      }
    })
  }

  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges()
  }

  public override ngOnInit() {
    super.ngOnInit();

    // if (this.cacheService.hasData(AppComponent.KEY_CURRENT_LANGUAGE)) {
    //   let currentLanguage: string = this.cacheService.get(AppComponent.KEY_CURRENT_LANGUAGE)
    //   this.translateService.setDefaultLang(currentLanguage)
    //   this.translateService.use(currentLanguage)
    // } else if (this.tokenService.account && this.tokenService.account.language && this.tokenService.account.language.code) {
    //   this.translateService.setDefaultLang(this.tokenService.account.language.code)
    //   this.translateService.use(this.tokenService.account.language.code)
    // } else {
    //   const language: string[] = window.navigator.language.split('-')
    //   this.translateService.setDefaultLang(language[0])
    //   this.translateService.use(language[0])
    // this.cacheService.set(AppComponent.KEY_CURRENT_LANGUAGE_ID, LanguageEnum.DEFAULT_LANGUAGE)
    // }
    this.processManagerService.addProcess('head')

    const saveHeaderFooter = ([header, footer] : string[]) => {
      this.header = header
      this.footer = footer
      this.changeDetectorRef.markForCheck()
    }

    forkJoin([
      this.loadHeader(),
      this.loadFooter(),
      this.loadHeadHtml(),
      this.loadBodyClasses()
    ]).subscribe((results) => {
      const body = document.querySelector('body')
      if (body && results[3]) {
          body.classList.add(...(results[3].split(' ')))
      }
      const head = document.querySelector('head')
      if (head && results[2]) {
        head.innerHTML = head.innerHTML + results[2]
      }
      saveHeaderFooter(results)

      setTimeout(() => this.initialOverlay = false, 1250)
      this.processManagerService.notify('head')
    })

    this.authService.userLoginChanges.subscribe(() => {
      forkJoin([
        this.loadHeader(true),
        this.loadFooter(true)
      ]).subscribe({
        next: ([header, footer] : string[]) => saveHeaderFooter([header, footer]),
      })
    })
  }

  public toggleMobileNav() {
    this.showMobileNav = !this.showMobileNav
  }

  //#region Internals
  private loadBodyClasses() : Observable<string> {
    return this.staticHtmlService.bodyClasses()
  }
  private loadFooter(bypassCache: boolean = false) : Observable<string> {
    return this.staticHtmlService.footer(bypassCache)
  }

  private loadHeader(bypassCache: boolean = false) : Observable<string> {
    return this.staticHtmlService.header(bypassCache)
  }

  private loadHeadHtml() : Observable<string> {
    return this.staticHtmlService.styles()
  }
  //#endregion
}
