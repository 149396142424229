<div *ngIf="filterFormGroup">
  <section class="search-filters dark-theme">
    <form *ngIf="filterFormGroup" (submit)="submitSearchForm()" [formGroup]="filterFormGroup" autocomplete="off" novalidate>
      <fieldset>
        <legend>{{'ADMIN.USER_DETAIL.FILTER_USERS'|translate}}</legend>
        <div class="p-fluid">
          <div class="grid filter-wrap">
            <div class="col-12 md:col-3 form-field" [formGroup]="filterFormGroup">
              <label for="UserRole">{{'ADMIN.USER_DETAIL.USER_ROLE'|translate}}</label>
              <p-dropdown appendTo="body"
                          id="UserRole"
                          [formGroup]="filterFormGroup"
                          [options]="userRoles"
                          optionLabel="Name"
                          optionValue="ID"
                          formControlName="UserRole">
              </p-dropdown>
            </div>
            <div class="col-12 md:col-3 form-field" [formGroup]="filterFormGroup">
              <label for="UserStatus">{{'ADMIN.USER_DETAIL.USER_STATUS'|translate}}</label>
              <p-dropdown appendTo="body"
                          id="UserRole"
                          [formGroup]="filterFormGroup"
                          [options]="userStatuses"
                          optionLabel="Name"
                          optionValue="ID"
                          formControlName="UserStatus">
              </p-dropdown>
            </div>
            <div class="col-12 md:col-4 form-field">
              <label for="SearchTerm">{{'COMMON.UI.SEARCH'|translate}}</label>
              <input type="text" pInputText formControlName="SearchTerm" id="SearchTerm" />
            </div>
            <div class="col-12 md:col-2 col-2 button-wrap">
              <p-button type="submit" label="{{'COMMON.UI.FILTER'|translate}}"></p-button>
            </div>

          </div>
        </div>

      </fieldset>
    </form>
  </section>

  <a class="form-button button-style alternate create-user" routerLink="create">{{'ADMIN.USER_DETAIL.CREATE_USER'|translate}}</a>

  <section class="container" *ngIf="userSearch.items">
    <app-pagination [currentPage]="userSearch.page ?? 1" [perPage]="userSearch.perPage ?? 20" [totalItems]="userSearch.totalItems ?? 0" (goTo)="onGoTo($event)"></app-pagination>
  </section>

  <section class="container" *ngIf="userSearch.items">
    <table class="tbl-list-user">
      <thead>
        <tr>
          <th>{{'ADMIN.USER_DETAIL.FIRST_NAME'|translate}}</th>
          <th>{{'ADMIN.USER_DETAIL.LAST_NAME'|translate}}</th>
          <th>{{'ADMIN.USER_DETAIL.EMAIL'|translate}}</th>
          <th>{{'ADMIN.USER_DETAIL.USER_ROLE'|translate}}</th>
          <th>{{'ADMIN.USER_DETAIL.USER_STATUS'|translate}}</th>
          <th>{{'ADMIN.USER_DETAIL.CREATED_DATE'|translate}}</th>
          <th>{{'ADMIN.USER_DETAIL.LOGIN_DATE'|translate}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of this.userSearch.items; index as index">
          <td [attr.data-heading-1]="'ADMIN.USER_DETAIL.FIRST_NAME'|translate">{{user.FirstName}}</td>
          <td [attr.data-heading-2]="'ADMIN.USER_DETAIL.LAST_NAME'|translate">{{user.LastName}}</td>
          <td [attr.data-heading-3]="'ADMIN.USER_DETAIL.EMAIL'|translate">
            <a *ngIf="user.Email" href="mailto:{{user.Email}}">{{user.Email}}</a>
          </td>
          <td [attr.data-heading-4]="'ADMIN.USER_DETAIL.USER_ROLE'|translate">{{user.UserRole?.Name ?? ''}}</td>
          <td [attr.data-heading-5]="'ADMIN.USER_DETAIL.USER_STATUS'|translate">{{user.UserStatus?.Name ?? ''}}</td>
          <td [attr.data-heading-6]="'ADMIN.USER_DETAIL.CREATED_DATE'|translate">{{user.CreatedDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY'}}</td>
          <td [attr.data-heading-7]="'ADMIN.USER_DETAIL.LOGIN_DATE'|translate">
            <span *ngIf="user.LoginDate">{{user.LoginDate | amLocale:authService.languageCode | amDateFormat:'MMM D, YYYY h:mm a'}}</span>
            <span *ngIf="!user.LoginDate">&nbsp;</span>
          </td>
          <td>
            <a class="edit icon-edit" *ngIf=" 'System' != user.UserRole?.Name??''" [routerLink]="['edit/', user.ID]">{{'COMMON.UI.EDIT'|translate}}</a>
            <a class="archive icon-archive" *ngIf="'System' != user.UserRole?.Name && user.UserStatus?.ID == userStatusEnum.ACTIVE" (click)="clickArchiveUser(user)">{{'ADMIN.USER_DETAIL.ARCHIVE_USER'|translate}}</a>
          </td>
        </tr>
      </tbody>
    </table>

  </section>
</div>
