import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {TranslatePipe} from "@ngx-translate/core";
import {ConfirmationService, MessageService} from "primeng/api";
import {BaseComponent} from "../../components/base/base.component";
import {
  ContentBlock,
  DashboardDistributorDto,
  DistributionClose,
  DistributionCreate,
  DistributionSearch,
  DistributorService,
  JsonResponse,
  LookupDto,
  LookupService,
  PurchaseStats,
  SurveyDto,
  ViewDistributionListDto,
  ViewDistributorLicenseSummary
} from "../../core/api";
import {AppTranslatePipe} from "../../pipes/app-translate.pipe";
import {AuthService} from "../../service/auth.service";
import {FormValidationService} from "../../service/form-validation.service";
import {ProcessManagerService} from "../../service/process-manager.service";

@Component({
  selector: 'app-dashboard-dist',
  templateUrl: './dashboard-dist.component.html',
  styleUrls: ['./dashboard-dist.component.scss']
})
export class DashboardDistComponent extends BaseComponent implements OnInit {
  //#region variables

  public createDistributionForm!: FormGroup
  private _dtoSearch!: DistributionSearch
  public welcomeScreenText!: ContentBlock;
  public purchaseStatsALL!: PurchaseStats;
  public surveyLicenseSummary: ViewDistributorLicenseSummary[] = [];
  public dashboardDto!: DashboardDistributorDto;
  public luDistributionStatuses: LookupDto[] = []
  public luValidSurveys!: { ID: number, Name: string }[]
  public distributionCreate!: DistributionCreate
  public maxParticipants: number = 0;
  public distributionList: ViewDistributionListDto[] = [];
  public surveys!: SurveyDto[]
  public listDistributions!: DistributionSearch

  //#endregion

  //#region properties

  get licensesAvailable(): number {
    /** @todo - return the actual number with the initial data */
    return this.maxParticipants
  }

  /**
   * returns true if the current distributor has any licences available for
   * *any* type of survey
   * This is used to toggle visibility of the 'Create Distribution interface'
   * @see surveyLicenseSummary
   */
  get hasLicensesAvailable(): boolean {
    let available: ViewDistributorLicenseSummary[] = this.surveyLicenseSummary.filter((obj) => {
      return ('Available' === obj.Status) && (0 < obj.Total);
    });
    return 0 < available.length;
  }

  /**
   * returns true if the current distributor has any licences available for any type of survey
   * used to toggle visibility of the 'Create Distribution interface'
   * @see surveyLicenseSummary
   */
  get showSurveyDropdown(): boolean {
    return 1 < this.validSurveys.length
  }

  /**
   * returns true if the current distributor has distributions for more than one type of survey licences available for any type of survey
   * used to toggle visibility of the 'Assessment' column in 'Manage Assessment Distributions'
   * @see distributionList
   */
  get hasMixedSurveyTypes(): boolean {
    /** @todo - we are explicitly hiding this column for the time being - implement logic below ata later date when more than one survey can be purchased */
    return false

    if (this.distributionList.length) {
      let found = {}
      for (const d of this.distributionList) {
        if (found[d.SurveyID]) {
          return true
        }
        found[d.SurveyID] = 1
      }

    }
    return false;
  }

  /**
   * Determine the surveys for which the user can create distributions
   * Returns the IDs of the Surveys for which a user (Distributor) can currently cerate surveys
   * @see surveyLicenseSummary
   */
  get validSurveys(): number[] {
    return this.surveyLicenseSummary.filter((obj) => {
      return ('Available' === obj.Status) && 0 < obj.Total;
    }).map(obj => obj.SurveyID).filter((value, index, self) => {
      return self.indexOf(value) === index
    });

  }

  //#endregion

  //#region constructor

  constructor(
    private appTranslatePipe: AppTranslatePipe,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private distributorService: DistributorService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private lookupService: LookupService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)

    this.distributionCreate = {
      CanViewResults: false,
      Name: DashboardDistComponent.generateDistributionName(),
      ParticipantCount: 0,
      SurveyID: 0
    }
  }

  //#endregion

  //#region event handlers

  public clickCloseDistribution(distribution: ViewDistributionListDto) {
    this.confirmationService.confirm({
      accept: () => {
        this.closeDistribution(distribution)
      },
      header: this.translate.transform('DISTRIBUTOR.DISTRIBUTION_LIST.CLOSE_DISTRIBUTION'),
      message: this.translate.transform('DISTRIBUTOR.DISTRIBUTION_LIST.CLOSE_DISTRIBUTION_CONFIRM_MESSAGE')
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['dashboard', 'distributions', 'distributionStatuses', 'surveys'])

    this.loadDashboard();
    this.loadDistributions();
    this.loadDistributionStatuses();
  }

  /**
   * Obtains from the API the number of licenses available for the survey
   * currently (newly) selected in the Create Distribution Form
   */
  public onChangeSurvey() {
    this.loadAvailableLicenses()
  }

  refreshMaxParticipants() {
    let participantCountControl = this.createDistributionForm.get("ParticipantCount");
    let participantCount = parseInt(participantCountControl?.value);
    if (participantCount > this.maxParticipants) {
      participantCountControl?.setValue(this.maxParticipants)
    }
  }

  //#endregion

  //#region business logic

  private calcMaxParticipants() {
    let selectedSurveyID = this.distributionCreate.SurveyID
    // Set the maxParticipants for the deault selection for the createSurvey Form
    let selectedLicenseSummary = this.surveyLicenseSummary.filter((obj) => {
      return ('Available' === obj.Status) && (selectedSurveyID === obj.SurveyID);
    });
    this.maxParticipants = selectedLicenseSummary.map(obj => obj.Total)[0];
  }

  private closeDistribution(distribution: ViewDistributionListDto) {
    let distributionClose: DistributionClose = {
      ID: distribution.ID
    }

    this.processManagerService.force()
    this.distributorService.closeDistribution(distributionClose).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.messageService.add({
          life: 10000,
          severity: 'info',
          detail: this.translate.transform('DISTRIBUTOR.DISTRIBUTION_LIST.DISTRIBUTION_CLOSED')
        })

        this.processManagerService.resetProcess('distributions')
        this.loadDistributions()
      },
      error: (error: HttpErrorResponse) => {
        this.processManagerService.unforce()
        this.handleApiException(error)
      }
    })
  }

  public createDistribution() {
    if (this.createDistributionForm.get('ParticipantCount')?.hasError('max')) {
      this.handleException(this.translate.transform('DISTRIBUTOR.DISTRIBUTION_FORM.ERROR_MAXIMUM_PARTICIPANTS', {count: this.maxParticipants}))
      return
    }

    if (this.createDistributionForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.createDistributionForm)
      return
    }

    this.populateDistribution()

    //return;
    this.processManagerService.force()
    this.distributorService.createDistribution(this.distributionCreate).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        const newRow: ViewDistributionListDto = response.data
        //this.refreshLicenseSummary()
        //this.distributionList.push(newRow)
        this.router.navigate(['distributor/distributions/edit/' + newRow.ID])
      },
      error: (error: HttpErrorResponse) => {
        this.processManagerService.unforce()
        this.handleApiException(error)
      }
    })
  }

  private static generateDistributionName() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return "Assessment Issued – " + yyyy + '-' + mm + '-' + dd;
  }

  /**
   * Prepares the Create Distribution form for the UI
   * This does not need to be done if the user has no licenses available for any survey
   * @private
   */
  private initializeForm() {
    this.createDistributionForm = this.formBuilder.group({
      SurveyID: [this.distributionCreate.SurveyID, [Validators.required]],
      //ParticipantCount: [this.distributionCreate.ParticipantCount, [Validators.required]],
      ParticipantCount: [1, [Validators.required]],
      Name: [this.distributionCreate.Name, [Validators.required]],
      CanViewResults: [this.distributionCreate.CanViewResults],
    })
    this.loadAvailableLicenses()
    /** @TODO - distributionSearch.items is defined as a set but gets populated as an array - an error is thrown when calculating the number of elements using .length*/
    //this.createDistributionForm.controls['ParticipantCount'].setValue(this.maxParticipants)
  }

  /**
   * Obtains the total number of available licenses held by a distributor for a particular survey
   * Populates this.maxParticipants
   * This does not get called when the current use has
   * @private
   */
  private loadAvailableLicenses() {
    let surveyID: number = parseInt(this.createDistributionForm.get("SurveyID")?.value)

    this.processManagerService.force()
    this.distributorService.surveyLicensesAvailable(this.authService.user.ID!, surveyID).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.maxParticipants = response.data

        this.refreshMaxParticipants()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  private loadDashboard() {
    this.distributorService.dashboardDistributor().subscribe({
      complete: () => this.processManagerService.notify('dashboard'),
      next: (response: JsonResponse) => {
        this.dashboardDto = response.data
        this.welcomeScreenText = response.data.WelcomeScreenText
        this.purchaseStatsALL = response.data.PurchaseStatsALL
        this.surveyLicenseSummary = response.data.SurveyLicenseSummary

        // Determine the surveys for which the user can create distributions
        let surveyIDs: number[] = this.surveyLicenseSummary.filter((obj) => {
          return ('Available' === obj.Status) && 0 < obj.Total;
        }).map(obj => obj.SurveyID).filter((value, index, self) => {
          return self.indexOf(value) === index
        });

        this.distributionCreate.SurveyID = surveyIDs[0]
        this.calcMaxParticipants()

        this.processManagerService.notify('dashboard')

        /**
         * We only need to prepare the Create Distribution form if the user has any available licenses for any survey
         * This can be determined by calling
         */
        if (this.hasLicensesAvailable) {
          this.initializeForm()
        }


        this.loadSurveys(surveyIDs);
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private loadDistributions() {
    const criteria: DistributionSearch = {
      DistributorID: this.authService.user.ID
    }

    this.distributorService.listDistributions(criteria).subscribe({
      complete: () => this.processManagerService.notify('distributions'),
      next: (response: JsonResponse) => {
        this._dtoSearch = response.data
        // @todo supression - TS2488: Type 'Set  | undefined' must have a '[Symbol.iterator]()' method that returns an iterator.
        // @ts-ignore
        this.distributionList = [...this._dtoSearch.items]
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  private loadDistributionStatuses() {
    this.lookupService.listDistributionStatuses().subscribe({
      complete: () => this.processManagerService.notify('distributionStatuses'),
      next: (response: JsonResponse) => {
        this.luDistributionStatuses = response.data
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  private loadSurveys(surveyIDs: number[]) {
    this.lookupService.listSurveys().subscribe({
      complete: () => this.processManagerService.notify('surveys'),
      next: (response: JsonResponse) => {
        this.surveys = Object.values(response.data)

        if (0 < surveyIDs.length) {
          this.luValidSurveys = this.surveys.filter(entity => surveyIDs.indexOf(entity.ID) != -1).map(entity => {
            return {ID: entity.ID, Name: this.appTranslatePipe.transform(entity, 'Name')};
          })
        }
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public returnSurvey(surveyID: number): SurveyDto {
    return this.surveys.find(s => s.ID == surveyID)!
  }

  private populateDistribution() {
    this.distributionCreate.SurveyID = this.createDistributionForm.get('SurveyID')?.value
    this.distributionCreate.Name = this.createDistributionForm.get('Name')?.value
    this.distributionCreate.ParticipantCount = this.createDistributionForm.get('ParticipantCount')?.value
    this.distributionCreate.CanViewResults = this.createDistributionForm.get('CanViewResults')?.value
  }

  private refreshLicenseSummary() {
    this.processManagerService.addProcess('refreshLicenseSummary')
    this.distributorService.distributorLicenseSummary().subscribe(
      {
        complete: () => this.processManagerService.notify('refreshLicenseSummary'),
        next: (response: JsonResponse) => {
          /** @todo - firstly not sure why this comes back from the API as a numerically indexed object rather than an array */
          this.surveyLicenseSummary = []
          Object.entries(response.data).forEach(
            ([key, value]) => this.surveyLicenseSummary.push(<ViewDistributorLicenseSummary>value)
          );
          // refresh the max participants
          this.calcMaxParticipants()
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )
  }

  //#endregion

}
