import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { BaseComponent } from "../../components/base/base.component";
import { Dimension, JsonResponse, LookupService, Theme } from "../../core/api";
import { SurveyEnum } from "../../enums/survey.enum";
import { AuthService } from "../../service/auth.service";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.scss']
})
export class DimensionsComponent extends BaseComponent implements OnInit {
  //#region variables

  public dimensions!: Dimension[]
  public displaySurveys!: { ID: number, Name: string }[]
  public filterFormGroup!: FormGroup

  //#endregion

  //#region constructor

  constructor(
    public authService: AuthService,
    processManagerService: ProcessManagerService,
    private lookupService: LookupService,
    private formBuilder: FormBuilder
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['dimensions', 'surveys'])
    this.processManagerService.unforce()

    this.loadSurveys();
  }

  public submitSearchForm() {
    this.processManagerService.resetProcess('dimensions')

    this.loadDimensions()
  }

  //#endregion

  //#region business logic

  private initializeForm() {
    if (this.displaySurveys) {
      this.filterFormGroup = this.formBuilder.group({
        SurveyID: [SurveyEnum.QUESTIONS_100],
      })
    }
  }

  private loadDimensions() {
    let surveyID: number = this.filterFormGroup.get('SurveyID')?.value

    this.lookupService.listDimensions(surveyID).subscribe({
      complete: () => this.processManagerService.notify('dimensions'),
      next: (response: JsonResponse) => {
        this.dimensions = Object.keys(response.data).map(k => {
          return response.data[k];
        });
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    }
    )
  }

  private loadSurveys() {
    this.processManagerService.force()
    this.lookupService.listSurveys().subscribe({
      complete: () => this.processManagerService.notify('surveys'),
        next: (response: JsonResponse) => {
          this.displaySurveys = Object.keys(response.data).map(key => {
            let entity: Theme = response.data[key]
            return {ID: entity.ID ?? 0, Name: entity.Name ?? ''};
          });

          this.initializeForm()

          this.loadDimensions()
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )
  }

  //#endregion
}
