import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslatePipe} from "@ngx-translate/core";
import {BaseComponent} from "../../components/base/base.component";
import {
  DistributionEdit,
  DistributionParticipant,
  DistributionParticipantsList,
  DistributorService,
  JsonResponse,
  LookupDto,
  LookupService,
  Theme
} from "../../core/api";
import {IIDNamePair} from "../../interfaces/IIDNamePair";
import {AuthService} from "../../service/auth.service";
import {ProcessManagerService} from "../../service/process-manager.service";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-distribution-detail',
  templateUrl: './distribution-detail.component.html',
  styleUrls: ['./distribution-detail.component.scss']
})

export class DistributionDetailComponent extends BaseComponent implements OnInit {

  //#region enums

  // noinspection LocalVariableNamingConventionJS
  private STATUS_INCOMPLETE: string = 'Incomplete'
  // noinspection LocalVariableNamingConventionJS
  private STATUS_COMPLETE: string = 'Complete'
  // noinspection LocalVariableNamingConventionJS
  private STATUS_PENDING: string = 'Pending'

  //#endregion

  //#region Properties
  public distribution!: DistributionEdit
  public filterFormGroup!: FormGroup
  public maxParticipants: number = 0;

  private distributionID!: number
  /**
   * 'emailEdited' is passed to this page on the querystring from
   * the edit email interface if the user navigated away to edit
   * the email and returned here from that page
   * @public
   */
  public emailEdited: boolean = false;

  public distributionForm!: FormGroup
  public distributionStatus: string = this.STATUS_INCOMPLETE
  public distributionParticipantsForm!: FormGroup

  // Lookups
  public luSurveys!: IIDNamePair[]
  public luDistributionStatuses!: IIDNamePair[]
  public luAssessmentStatuses!: IIDNamePair[]
  public showReview: boolean = false;

  get filteredParticipantsFormArray(): FormArray {
    const faControls = this.participantsFormArray.controls as FormGroup[]
    let filterSearchTerm = this.filterFormGroup.controls['SearchTerm'].value?.toLowerCase()
    if (!filterSearchTerm) {
      return this.participantsFormArray
    }
    let rv: FormArray = this.formBuilder.array([])
    let filteredControls = faControls.filter((formGroup, index) => {
      let fgControls = formGroup.controls
      let matchedText = false;
      let haystacks: string[] = ['FirstName', 'LastName', 'Email']
      for (let property of haystacks) {
        let haystack: string = fgControls[property].value?.toLowerCase();
        if (haystack) {
          if (-1 !== haystack.indexOf(filterSearchTerm)) {
            matchedText = true
          }
        }
      }
      return matchedText
    })
    for (let control of filteredControls) {
      rv.push(control)
    }
    return rv

  }

  get participantsFormArray(): FormArray {
    return this.distributionParticipantsForm.get('faParticipants') as FormArray
  }

  get licensesAvailable(): number {
    return this.maxParticipants
  }

  get isDistributionIncomplete(): boolean {
    return this.STATUS_INCOMPLETE === this.distribution?.DistributionStatus.Name
  }

  get isDistributionPending(): boolean {
    return this.STATUS_PENDING === this.distribution?.DistributionStatus.Name
  }

  get isDistributionComplete(): boolean {
    return this.STATUS_COMPLETE === this.distribution?.DistributionStatus.Name
  }

  /**
   * Counts the number of paricipants that have an email, first or last name
   * used to set the min value to which Participant count can be set
   * Cannot be less than '1'
   */
  get minParticipantsEntry(): number {
    let minParticipants = this.participantsFormArray.controls.filter((element, index) => {
      return element.get('FirstName')?.value
        || element.get('LastName')?.value
        || element.get('Email')?.value
    }).length;
    return (0 < minParticipants) ? minParticipants : 1;
  }

  /**
   * We consider the participants sendable if
   * 1. Distribution Status is Pending
   * 1. All emails are present
   * 2. The number of participant with emails present equals the number of participants in the distribution (the user may have just aleterd the participant count)
   * 3. The Participant form has not changed
   */
  get canBeSent(): boolean {
    let withEmail: number = this.participantsFormArray.controls.filter((element, index) => {
      return element.get('Email')?.value
    }).length
    //console.log('canBeSent: withEmail', withEmail)
    return this.isDistributionPending
      && !this.distributionParticipantsForm.dirty
      && withEmail === this.participantsFormArray.controls.length
      && withEmail === this.distributionForm.get('ParticipantCount')?.value
      ;
  }

  get maxParticipantsEntry(): number {
    return this.maxParticipants + this.distribution.ParticipantCount
  }

  //#endregion

  //#region constructor

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    processManagerService: ProcessManagerService,
    private messageService: MessageService,
    private lookupService: LookupService,
    private distributorService: DistributorService,
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers
  override ngOnInit(): void {
    super.ngOnInit()

    // load distribution ID from URL
    this.route.params.subscribe((params: Params) => {
      this.distributionID = parseInt(params['distributionID'])
      if (this.activatedRoute.snapshot.queryParams['emailEdited'] !== undefined) {
        this.emailEdited = true
      }

      this.loadDistribution()
    })
  }

  /**
   * When any Accordion control is clicked, always scroll to the top of
   * the accordion in order to present a consistent interface that
   * shows all steps
   */
  public onAccordionClick() {
    window.scrollTo(0, 0);
    return true;
  }

  /**
   * Handles submission of this.distributionForm
   */
  public submitDistributionForm() {
    if (this.distributionForm.invalid) {
      alert('There has been an error with your submission.');
      return;
    }

    this.populateDistribution()
    this.processManagerService.addProcess('saveDistribution')
    //return;
    this.distributorService.saveDistribution(this.distribution).subscribe(
      {
        complete: () => this.processManagerService.notify('saveDistribution'),
        next: (response: JsonResponse) => {

          if (200 === response.code) {
            let data: DistributionParticipantsList = response.data
            this.setDistributionStatus(data.DistributionStatus)
            this.setDistributionParticipants(data.DistributionParticipant)
            this.buildParticipantsForm()
            this.loadAvailableLicenses()

            this.messageService.add({
              life: 10000,
              severity: 'info',
              detail: this.translate.transform('DISTRIBUTOR.DISTRIBUTION_DETAIL.MESSAGE_DISTRIBUTION_SAVED')
            })
            //this.scrollIntoView('step-modify-distribution')
            //this.router.navigate(['distributor/distributions/edit/' + this.distribution.ID])
            // .then(value => this.onAccordionClick())
          } else {
            this.handleException(response.message)
          }

        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )
  }

  /**
   * Handles submission of this.distributionParticipantsForm
   */
  public submitDistributionParticipantsForm() {
    if (this.distributionParticipantsForm.invalid) {
      alert('There has been an error with your submission.');
      return;
    }

    this.populateDistributionParticipants()
    this.processManagerService.addProcess('saveDistributionParticipants')
    this.distributorService.saveDistributionParticipants(this.distribution).subscribe(
      {
        complete: () => this.processManagerService.notify('saveDistributionParticipants'),
        next: (response: JsonResponse) => {
          if (200 === response.code) {

            this.distributionParticipantsForm.markAsPristine()

            /**
             * @note - when a distribution that is 'Pending' is edited and is
             * still Pending after the save, the status has effectively not
             * changed so the UI does not automatically switch accordion panels
             *
             * We are addressing this here by switching to status 'Incomplete'
             * then updating to the actual status after a timeout
             * As the toast also depends on the same variable we are postponing the toast display too
             */
            this.setDistributionStatus({...response.data, Name: this.STATUS_INCOMPLETE})
            setTimeout(() => {
              this.setDistributionStatus(response.data)


            }, 250)

            let detail = ''
            if (this.STATUS_INCOMPLETE === response.data.Name) {
              detail = this.translate.transform('DISTRIBUTOR.DISTRIBUTION_DETAIL.MESSAGE_PARTICIPANTS_SAVED_DETAL');
            }

            this.messageService.add({
              life: 10000,
              severity: 'info',
              summary: this.translate.transform('DISTRIBUTOR.DISTRIBUTION_DETAIL.MESSAGE_PARTICIPANTS_SAVED_SUMMARY'),
              detail: this.translate.transform(detail)
            })

            /* https://caorda-git.caorda.com/clients/conflictability-ui/-/issues/211 */
            this.onAccordionClick()


          } else {
            this.handleException(response.message)
          }

        },
        error: (error: HttpErrorResponse) => {
          if (409 === error.error.code && 'API.ERROR.CONFLICT_DISTRIBUTION_DUPLICATED_EMAIL_ADDRESS' === error.error.message) {
            this.handleErrorDuplicateEmails();
          } else {
            this.handleApiException(error)
          }

        }
      }
    )
  }

  private handleErrorDuplicateEmails() {
    let x: number;
    const controls = this.participantsFormArray.controls;
    let dupes: { email: string, total: number }[] = [];
    for (x = 0; x < controls.length; x++) {
      let email: string = controls[x].get('Email')?.value
      if (email) {
        let o = dupes.find(o => email === o.email)
        if (!o) {
          o = {email: email, total: 0}
          dupes.push(o)
        }
        ++o.total
      }
    }
    let error: string = this.translate
      .transform('API.ERROR.CONFLICT_DISTRIBUTION_DUPLICATED_EMAIL_ADDRESS')
      .replace(
        '{dupes}',
        dupes
          .filter(o => 1 < o.total)
          .map(o => o.email)
          .join(', ')
      )
    this.handleException(error)
  }

  /**
   * Takes the properties of 'this.distribution' and populates
   * them into distributionForm with their current values
   * @private
   */
  private buildDistributionForm() {
    this.distributionForm = this.formBuilder.group({
      Name: [this.distribution.Name],
      SurveyID: [this.distribution.SurveyID],
      Survey: [this.getSurveyName(this.distribution.SurveyID)],
      ParticipantCount: [this.distribution.ParticipantCount],
      CanViewResults: [this.distribution.CanViewResults],
    })
  }

  /**
   * Takes the participants of 'this.distribution.DistributionParticipant'
   * and populates them into the form in the UI with their current values
   * @private
   */
  private buildParticipantsForm() {

    // -----------------------------------------------------------------
    // Create the Participants form with a 'placeholder' for the participant rows
    // Populate the participant form with the participants
    // -----------------------------------------------------------------
    this.distributionParticipantsForm = this.formBuilder.group({
      faParticipants: this.formBuilder.array([]),
    })

    let index = 0
    for (let participant of this.distribution.DistributionParticipant) {
      ++index
      const formGroup: FormGroup = this.formBuilder.group(
        {
          Index: [index],
          ID: [participant.ID],
          FirstName: [participant.FirstName],
          LastName: [participant.LastName],
          Email: [participant.Email, [Validators.email]],
          LicenseCode: [participant.License?.LicenseCode],
        },
        {updateOn: "blur"}
      );

      this.participantsFormArray.push(formGroup)
    }
  }

  /**
   * Converts all dustribution participants into users
   * Sends the email invitations to all users in the distribution
   */
  sendDistribution() {
    this.processManagerService.addProcess('sendDistribution')
    this.distributorService.sendDistribution(this.distribution).subscribe(
      {
        complete: () => this.processManagerService.notify('sendDistribution'),
        next: (response: JsonResponse) => {
          // If sendDistribution is successful, the distribution is now only available in view mode
          if (200 === response.code) {
            this.distribution = response.data
            this.messageService.add({
              life: 10000,
              severity: 'info',
              detail: this.translate.transform('DISTRIBUTOR.DISTRIBUTION_DETAIL.MESSAGE_DISTRIBUTION_SENT')
            })
            this.router.navigate(['distributor/distributions/view/' + this.distribution.ID])

          } else {
            this.handleException(response.message)
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )
  }

  //#endregion

  //#region internals
  // Lookups
  private loadSurveys() {
    this.processManagerService.addProcess('listSurveys')
    this.lookupService.listSurveys().subscribe(
      {
        complete: () => this.processManagerService.notify('listSurveys'),
        next: (response: JsonResponse) => {
          this.luSurveys = Object.keys(response.data).map(key => {
            let entity: Theme = response.data[key]
            return {ID: entity.ID ?? 0, Name: entity.Name ?? ''};
          });

          this.initializeForm()
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )
  }

  private loadAssessmentStatuses() {
    this.processManagerService.addProcess('listAssessmentStatuses')
    this.lookupService.listAssessmentStatuses().subscribe(
      (response: JsonResponse) => {
        this.processManagerService.notify('listAssessmentStatuses')
        this.luAssessmentStatuses = Object.keys(response.data).map(key => {
          /** @todo replace UserStatusDto with generic Lookup */
          let entity: LookupDto = response.data[key]
          return entity
        });
        this.luAssessmentStatuses.unshift({ID: -1, Name: 'All'})

        this.initializeForm()
      },
      (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    )
  }

  public getSurveyName(surveyID: number) {
    if (!this.luSurveys) {
      return null
    }

    return this.luSurveys.filter((obj) => {
      return (surveyID === obj.ID);
    })
      .map(obj => (
        /** @todo - Translation */
        obj.Name
      ))
  }

  private initializeForm() {
    if (this.distribution && this.luDistributionStatuses && this.luAssessmentStatuses && !this.distributionForm && !this.filterFormGroup) {

      this.buildDistributionForm();

      this.buildParticipantsForm()

      // -----------------------------------------------------------------
      // Create the Participants filter form
      // -----------------------------------------------------------------
      this.filterFormGroup = this.formBuilder.group({
        SearchTerm: [],
      })

      // -----------------------------------------------------------------
      // When the user has returned to this page from editing the email
      // we want to explicitly show the final review that is displayed
      // before sending the emails
      // -----------------------------------------------------------------
      this.showReview = this.emailEdited
    }
  }

  private setDistributionParticipants(distributionParticipants: DistributionParticipant[]) : void {
    this.distribution = { ...this.distribution, DistributionParticipant: distributionParticipants }
  }

  private setDistributionStatus(newStatus: LookupDto) {
    this.distribution = {...this.distribution, DistributionStatus: newStatus}
    this.distributionStatus = newStatus.Name
  }

  private loadDistribution() {
    this.processManagerService.addProcess('editDistribution')
    this.distributorService.editDistribution(this.distributionID).subscribe(
      {
        complete: () => this.processManagerService.notify('editDistribution'),
        next: (response: JsonResponse) => {
          this.distribution = response.data
          this.setDistributionStatus(response.data.DistributionStatus)
          this.loadDistributionStatuses()
          this.loadSurveys()
          this.loadAssessmentStatuses()
          this.loadAvailableLicenses()
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    );
  }

  private loadDistributionStatuses() {
    this.processManagerService.addProcess('loadDistributionStatuses')
    this.lookupService.listDistributionStatuses().subscribe(
      {
        complete: () => this.processManagerService.notify('loadDistributionStatuses'),
        next: (response: JsonResponse) => {
          this.luDistributionStatuses = Object.keys(response.data).map(key => {
            let entity: LookupDto = response.data[key]
            return entity;
          });

          this.initializeForm()
        },
        error: (error: HttpErrorResponse) => {
          this.handleApiException(error)
        }
      }
    )
  }

  /**
   * Takes the curent distribution form values and populates the 'this.distribution'
   * @private
   */
  private populateDistribution() {
    this.distribution.Name = this.distributionForm.get('Name')?.value;
    this.distribution.CanViewResults = this.distributionForm.get('CanViewResults')?.value;
    this.distribution.ParticipantCount = this.distributionForm.get('ParticipantCount')?.value;
  }

  private populateDistributionParticipants() {

    for (let p of this.distribution.DistributionParticipant) {
      let control = this.participantsFormArray.controls.find((o) => {
        return parseInt(o.get('ID')?.value) === p.ID
      }) as FormControl;
      p.FirstName = control.get('FirstName')?.value
      p.LastName = control.get('LastName')?.value
      p.Email = control.get('Email')?.value
    }

  }

  //#endregion

  public removeParticipantDisabled(formGroup: AbstractControl): boolean {
    return (!formGroup.get('FirstName')?.value) && (!formGroup.get('LastName')?.value) && (!formGroup.get('Email')?.value)
  }

  public removeParticipant(index: number) {
    const control = this.participantsFormArray.controls[index];
    control.get('FirstName')?.setValue('')
    control.get('LastName')?.setValue('')
    control.get('Email')?.setValue('')
  }

  private loadAvailableLicenses() {
    this.processManagerService.force()
    this.distributorService.surveyLicensesAvailable(
      this.authService.user.ID!,
      this.distribution.SurveyID
    ).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.maxParticipants = response.data
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public toggleBeforeSendAssessments(b: boolean) {
    this.showReview = b
  }

  public returnFormGroup(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup
  }
}
