/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class AssessmentAnswerDto
 */
export interface AssessmentAnswer { 
    /**
     * ID of the AssessmentAnswer
     */
    ID?: number | null;
    /**
     * ID of the Assessment Response
     */
    AssessmentResponseID?: number | null;
    CreatedDate?: string | null;
    UpdatedDate?: string | null;
    /**
     * ID of the Question
     */
    QuestionID?: number;
    /**
     * ID of the Question Answer
     */
    QuestionAnswerID?: number | null;
}

