<div>
  <h1>
    {{'DISTRIBUTOR.REMINDERS.HEADING'|translate}}
  </h1>

  <p [innerHTML]="instructionsMessage|translate" *ngIf="instructionsMessage"></p>

  <a class="form-button button-style alternate create-licence" [routerLink]="['/distributor', 'distributions', distributionID, 'reminders', 'create']">{{'DISTRIBUTOR.REMINDERS.CREATE'|translate}}</a>

  <section class="container" *ngIf="reminders && reminders.length > 0">
    <table class="tbl-list-dimension">
      <thead>
        <tr>
          <th class="reminder-date">{{'DISTRIBUTOR.REMINDERS.DATE'|translate}}</th>
          <th class="reminder-sent">{{'DISTRIBUTOR.REMINDERS.SENT'|translate}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let reminder of this.reminders; index as index">
          <td class="reminder-date">{{reminder.ReminderDate|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a'}}</td>
          <td class="reminder-sent">
            <span *ngIf="reminder.ReminderSent">{{reminder.ReminderSent|amLocale:authService.languageCode|amDateFormat:'MMM D, YYYY h:mm a'}}</span>
            <span *ngIf="!reminder.ReminderSent">{{'DISTRIBUTOR.REMINDERS.NOT_SENT'|translate}}</span>
          </td>
          <td>
            <a class="edit icon-edit" [routerLink]="['/distributor', 'distributions', distributionID, 'reminders', 'edit', reminder.ID]">{{'COMMON.UI.VIEW'|translate}}</a>
            <a class="archive icon-archive" (click)="clickDeleteReminder(reminder)">{{'DISTRIBUTOR.REMINDERS.DELETE'|translate}}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</div>
