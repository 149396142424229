import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
    selector: 'app-dynamic-styled-output',
    template: '<div *ngIf="styledHtml" [innerHTML]="styledHtml"></div>'
})
export class DynamicStyledOutputComponent implements OnInit, OnChanges {
    //#region Variables
    public styledHtml!: SafeHtml

    @Input() html!: string
    //#endregion

    //#region 
    constructor(
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.styledHtml = this.sanitizer.bypassSecurityTrustHtml(this.html)
    }

    ngOnChanges() : void {
        this.ngOnInit()
    }
}