<section class="dark-theme">
<form class="user-details-form" *ngIf="userForm" (submit)="submit()" [formGroup]="userForm" autocomplete="off" novalidate>
  <fieldset>
    <legend *ngIf="userID">{{'ADMIN.USER_DETAIL.LEGEND_DETAILS'|translate}}</legend>
    <legend *ngIf="!userID">{{'ADMIN.USER_DETAIL.LEGEND_CREATE'|translate}}</legend>
    <div class="grid">
      <div class="col-12 md:col-6 form-field" [formGroup]="userForm">
        <label for="userRole">{{'ADMIN.USER_DETAIL.USER_ROLE'|translate}}</label>
        <p-dropdown [formGroup]="userForm"
                    [options]="userRoles"
                    formControlName="userRole"
                    id="userRole"
                    optionLabel="Name"
                    optionValue="Name"
                    [placeholder]="'ADMIN.USER_DETAIL.USER_ROLE'|translate"></p-dropdown>
        <app-validation-error fieldName="userRole" [formGroup]="userForm" validatorName="invalidRoleChange">{{'API.ERROR.BAD_REQUEST_INVALID_ROLE_CHANGE'|translate}}</app-validation-error>

      </div>

      <div class="col-12 md:col-6 form-field" [formGroup]="userForm">
        <label for="userStatus">{{'ADMIN.USER_DETAIL.USER_STATUS'|translate}}</label>
        <p-dropdown id="userStatus"
                    [formGroup]="userForm"
                    [options]="userStatuses"
                    optionLabel="Name"
                    optionValue="ID"
                    formControlName="userStatus"
                    [placeholder]="'ADMIN.USER_DETAIL.USER_STATUS'|translate">
        </p-dropdown>
      </div>

      <div class="col-12 form-field">
        <label for="email">{{'ADMIN.USER_DETAIL.EMAIL'|translate}}</label>
        <input type="email" pInputText formControlName="email" id="email" />
        <app-validation-error fieldName="email" [formGroup]="userForm" validatorName="required">{{'ADMIN.USER_DETAIL.ERROR_EMAIL'|translate}}</app-validation-error>
        <app-validation-error fieldName="email" [formGroup]="userForm" validatorName="email">{{'ADMIN.USER_DETAIL.ERROR_EMAIL_FORMAT'|translate}}</app-validation-error>
        <app-validation-error fieldName="email" [formGroup]="userForm" validatorName="duplicateEmail">{{'API.ERROR.CONFLICT_EMAIL_ADDRESS_IN_USE'|translate}}</app-validation-error>
        <app-validation-error fieldName="email" [formGroup]="userForm" validatorName="invalidEmail">{{'API.ERROR.BAD_REQUEST_INVALID_EMAIL_ADDRESS'|translate}}</app-validation-error>
      </div>

      <div *ngIf="isNewUser" class="col-12 md:col-6 form-field">
        <label for="password">{{'ADMIN.USER_DETAIL.USER_PASSWORD'|translate}}</label>
        <p-password [toggleMask]="true" formControlName="password" id="password"></p-password>
        <app-validation-error fieldName="password" [formGroup]="userForm" validatorName="required">{{'ADMIN.USER_DETAIL.ERROR_PASSWORD'|translate}}</app-validation-error>
        <app-validation-error fieldName="password" [formGroup]="userForm" validatorName="invalidStrength">{{'ADMIN.USER_DETAIL.ERROR_PASSWORD_STRENGTH'|translate}}</app-validation-error>
      </div>

      <div *ngIf="isNewUser" class="col-12 md:col-6 form-field">
        <label for="passwordConfirm">{{'ADMIN.USER_DETAIL.USER_PASSWORD_CONFIRM'|translate}}</label>
        <p-password [toggleMask]="true" formControlName="passwordConfirm" id="passwordConfirm" [feedback]="false"></p-password>
        <app-validation-error fieldName="passwordConfirm" [formGroup]="userForm" validatorName="required">{{'ADMIN.USER_DETAIL.ERROR_CONFIRM_PASSWORD'|translate}}</app-validation-error>
        <app-validation-error fieldName="passwordConfirm" [formGroup]="userForm" validatorName="mismatch">{{'ADMIN.USER_DETAIL.ERROR_PASSWORD_MISMATCH'|translate}}</app-validation-error>
      </div>

      <div class="col-12 md:col-6 form-field">
        <label for="firstName">{{'ADMIN.USER_DETAIL.FIRST_NAME'|translate}}</label>
        <input pInputText formControlName="firstName" id="firstName" />
      </div>

      <div class="col-12 md:col-6 form-field">
        <label for="lastName">{{'ADMIN.USER_DETAIL.LAST_NAME'|translate}}</label>
        <input pInputText formControlName="lastName" id="lastName" />
      </div>
    </div>
    <p-button type="submit" label="{{'COMMON.UI.SAVE'|translate}}"></p-button>
    <p-button type="button" label="{{'COMMON.UI.CANCEL'|translate}}" (click)="clickCancel()"></p-button>
  </fieldset>
</form>
</section>
