import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslatePipe } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { IIDNamePair } from "src/app/interfaces/IIDNamePair";
import { PasswordStrengthValidator } from "src/app/validators/password-strength.validator";
import { BaseComponent } from "../../components/base/base.component";
import { AdminService, EnumUserRoles, JsonResponse, UserCreateDto, UserDto, UserRoleDto, UserStatusDto } from "../../core/api";
import { UserRoleEnum } from "../../enums/user-role.enum";
import { UserStatusEnum } from "../../enums/user-status.enum";
import { FormValidationService } from "../../service/form-validation.service";
import { ProcessManagerService } from "../../service/process-manager.service";
import { PasswordMatchValidator } from "../../validators/password-match.validator";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html'
})
export class UserDetailComponent extends BaseComponent implements OnInit {
  //#region variables

  private user!: UserDto | UserCreateDto
  public userForm!: FormGroup
  public userID!: number
  public userRoles!: IIDNamePair[]
  public userStatuses!: IIDNamePair[]

  //#endregion

  //#region properties

  get isNewUser() {
    return 0 == this.userID;
  }

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickCancel() {
    this.router.navigate(['/admin', 'users'])
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['user', 'userRoles', 'userStatuses'])

    this.route.params.subscribe((params: Params) => {
      this.userID = ('userID' in params) ? parseInt(params['userID']) : 0

      if (this.isNewUser) {
        this.createNewUser()
      }
      else {
        this.loadUser()
      }
    })
  }

  public submit() {
    this.submitUser()
  }

  //#endregion

  //#region business logic

  private createNewUser() {
    this.user = {
      Email: '',
      FirstName: null,
      LastName: null,
      Password: null,
      UserRole: { ID: UserRoleEnum.ADMIN, Name: EnumUserRoles.Admin },
      UserStatus: { ID: UserStatusEnum.ACTIVE, Name: 'Active' },
    }

    this.processManagerService.notify('user')
    this.initializeForm()

    this.loadUserRoles()
    this.loadUserStatuses()
  }

  private initializeForm() {
    this.userForm = this.formBuilder.group({
      email: [this.user.Email, [Validators.required, Validators.email]],
      firstName: [this.user.FirstName],
      lastName: [this.user.LastName],
      userRole: [this.user.UserRole?.Name, [Validators.required]],
      userStatus: [this.user.UserStatus?.ID, [Validators.required]],
    })

    if (this.isNewUser) {
      this.userForm.addControl('password', this.formBuilder.control(null, [Validators.required, PasswordStrengthValidator(8)]))
      this.userForm.addControl('passwordConfirm', this.formBuilder.control(null, [Validators.required]))
      this.userForm.setValidators(PasswordMatchValidator)
    }
    else {
      if (this.user.UserRole?.Name == EnumUserRoles.Admin) {
        this.userForm.get('userRole')?.disable()
      }
    }
  }

  private loadUser() {
    this.adminService.editUser(this.userID).subscribe({
      complete: () => this.processManagerService.notify('user'),
      next: (response: JsonResponse) => {
        this.user = response.data
        this.initializeForm()

        this.loadUserRoles()
        this.loadUserStatuses()
      },
      error: (error: HttpErrorResponse) => {
        this.processManagerService.notify('user')
        this.handleApiException(error)
      }
    });
  }

  private loadUserRoles() {
    this.adminService.listUserRoles().subscribe({
      complete: () => this.processManagerService.notify('userRoles'),
      next: (response: JsonResponse) => {
        this.userRoles = Object.keys(response.data).map(key => {
          let entity: UserRoleDto = response.data[key]
          return {ID: entity.ID ?? 0, Name: entity.Name};
        })

        if (!this.isNewUser) {
          if (this.user.UserRole?.Name != EnumUserRoles.Admin) {
            let index: number = this.userRoles.findIndex(r => r.Name == EnumUserRoles.Admin)
            if (index != -1) {
              this.userRoles.splice(index, 1)
            }
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        this.processManagerService.notify('userRoles')
        this.handleApiException(error)
      }
    })
  }

  private loadUserStatuses() {
    this.adminService.listUserStatus().subscribe({
      complete: () => this.processManagerService.notify('userStatuses'),
      next: (response: JsonResponse) => {
        this.userStatuses = Object.keys(response.data).map(key => {
          let entity: UserStatusDto = response.data[key]
          return { ID: entity.ID ?? 0, Name: entity.Name };
        });
      },
      error: (error: HttpErrorResponse) => {
        this.processManagerService.notify('userStatuses')
        this.handleApiException(error)
      }
    })
  }

  private populateUser() {
    this.user.Email = this.userForm.get('email')?.value
    this.user.FirstName = this.userForm.get('firstName')?.value
    this.user.LastName = this.userForm.get('lastName')?.value

    const userRole: UserRoleDto = { Name: '' }
    userRole.Name = this.userForm.get('userRole')?.value
    this.user.UserRole = userRole

    const userStatus: UserStatusDto = { Name: '' }
    userStatus.ID = this.userForm.get('userStatus')?.value
    this.user.UserStatus = userStatus

    if (this.isNewUser) {
      if ("password" in this.user) {
        (this.user as UserCreateDto).Password = this.userForm.get('password')?.value
      }
    }
  }

  private submitUser() {
    if (this.userForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.userForm)
      return
    }

    this.populateUser()

    this.processManagerService.force()
    if (this.isNewUser) {
      this.adminService.createUser(this.user).subscribe({
        complete: () => this.processManagerService.unforce(),
        next: (response: JsonResponse) => {
          const name: string = [
            this.user.FirstName,
            this.user.LastName
          ].filter(e => !!e).join(' ')

          this.messageService.add({
            life: 10000, severity: 'info', detail: this.translate.transform('ADMIN.USER_DETAIL.USER_CREATED', { name: name }) })

          this.user = response.data

          this.router.navigate(['/admin', 'users'])
        },
        error: (error: HttpErrorResponse) => {
          this.processManagerService.unforce()
          if (409 == error.error.code) {
            this.userForm.get('email')?.setErrors({ duplicateEmail: true })
          }
          else if (400 == error.error.code && 'API.ERROR.BAD_REQUEST_INVALID_EMAIL_ADDRESS' === error.error.message) {
            this.userForm.get('email')?.setErrors({ duplicateEmail: true })
          }
          else {
            this.handleApiException(error)
          }
        }
      })
    }
    else {
      this.adminService.modifyUser(this.user).subscribe({
        complete: () => this.processManagerService.unforce(),
        next: (response: JsonResponse) => {
          let name: string = this.user.FirstName + ' ' + this.user.LastName
          this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('ADMIN.USER_DETAIL.USER_MODIFIED', { name: name }) })

          this.user = response.data

          this.router.navigate(['/admin', 'users'])
        },
        error: (error: HttpErrorResponse) => {
          this.processManagerService.unforce()
          if (409 == error.error.code) {
            this.userForm.get('email')?.setErrors({ duplicateEmail: true })
          }
          else if (400 == error.error.code && 'API.ERROR.BAD_REQUEST_INVALID_EMAIL_ADDRESS' === error.error.message) {
            this.userForm.get('email')?.setErrors({ duplicateEmail: true })
          }
          //else if (400 == error.error.code && 'API.ERROR.BAD_REQUEST_INVALID_ROLE_CHANGE' === error.error.message) {
          else if (
            400 == error.error.code
            && [
              'API.ERROR.BAD_REQUEST_INVALID_ROLE_CHANGE',
              'API.ERROR.BAD_REQUEST_INVALID_ROLE_CHANGE_FROM_ADMIN',
              'API.ERROR.BAD_REQUEST_INVALID_ROLE_CHANGE_HAS_REDISTRIBUTIONS',
            ].includes(error.error.message)
          ) {
            this.userForm.get('userRole')?.setErrors({ invalidRoleChange: true })
          }
          else {
            this.handleApiException(error)
          }
        }
      })
    }
  }

  //#endregion
}
