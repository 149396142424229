/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Localization } from './localization';


/**
 * Class QuestionModifyDto
 */
export interface QuestionModify { 
    QuestionID?: number;
    DimensionID?: number;
    QuestionText?: string;
    QuestionTextLocalizations?: Array<Localization>;
    ScoringParameter?: number;
    SortOrder?: number;
}

