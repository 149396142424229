<app-logo-loader *ngIf="initialOverlay"></app-logo-loader>
<div class="layout-wrapper">
  <div class="layout-content-wrapper">
    <app-dynamic-styled-output *ngIf="header" [html]="header"></app-dynamic-styled-output>
    <div class="layout-content-outer" role="main">
      <router-outlet></router-outlet>
    </div>
    <app-dynamic-styled-output *ngIf="footer" [html]="footer"></app-dynamic-styled-output>
  </div>
</div>

<p-dialog [(visible)]="showPopup" class="popup-container" [modal]="true" [style]="{ width: '40vw' }">
  <ng-template pTemplate="header">
    {{'COMMON.UI.ERROR_DIALOG_TITLE'|translate}}
  </ng-template>
  <p [innerHTML]="popupText|translate"></p>
  <ng-template pTemplate="footer">
  </ng-template>
</p-dialog>

<div *ngIf="loading" class="overlay loading-overlay">
  <div class="spinner"></div>
</div>

<p-confirmDialog icon="pi pi-exclamation-triangle" [style]="{ width: '40vw' }"></p-confirmDialog>
<p-toast></p-toast>
