import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'appMask'
})

export class AppMaskPipe implements PipeTransform {
    public transform(input: string, mask: string = '*') : string {
        return input.split("").map(i => mask).join("")
    }
}