import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BaseComponent} from "../../components/base/base.component";
import {AdminService, Dimension, JsonResponse, LookupService, QuestionSearch, SurveyDto, Theme} from "../../core/api";
import {AppTranslatePipe} from '../../pipes/app-translate.pipe';
import {AuthService} from "../../service/auth.service";
import {ProcessManagerService} from "../../service/process-manager.service";

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent extends BaseComponent implements OnInit {
  //#region variables

  private _dtoSearch: QuestionSearch = null!
  private _listDimensions: Dimension[] = null!
  private defaultSurveyID: number = 1
  public filterFormGroup!: FormGroup
  private surveys: SurveyDto[] = [];
  public luDimensions!: any[]
  public luThemes!: any[]
  public luSurveys!: any[]

  //#endregion

  //#region properties

  get questionSearch(): QuestionSearch {
    return this._dtoSearch;
  }

  public get isFortyQuestionSurvey(): boolean {
    let selectedSurveyID = this.filterFormGroup.get('SurveyID')?.value
    let survey: any = this.surveys.find(s => selectedSurveyID === s.ID)
    return 'Sum' === survey.CalculationMethod
  }

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    private appTranslatePipe: AppTranslatePipe,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private lookupService: LookupService,
    processManagerService: ProcessManagerService,
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['dimensions', 'questions', 'surveys', 'themes'])
    this.processManagerService.force()

    this._dtoSearch = {
      perPage: 10,
      page: 1,
      totalItems: 0,
      items: undefined,
      SurveyID: this.defaultSurveyID
    }

    this.initializeForm()
    this.loadQuestions();
    this.loadDimensions();
    this.loadThemes();
    this.loadSurveys();
  }

  public onFilterSurveyChanged() {
    let surveyID = this.filterFormGroup.controls['SurveyID'].value;

    this._dtoSearch.SurveyID = surveyID
    this.loadQuestions()
    this.luDimensions = this._listDimensions.filter(d => surveyID === d.Survey?.ID).map((d) => {
      return {ID: d.ID ?? 0, Name: this.appTranslatePipe.transform(d, 'Name')}
    })
    if (0 === this.luDimensions.length) {
      this.filterFormGroup.get('DimensionID')?.disable()
    } else {
      this.filterFormGroup.get('DimensionID')?.enable()
    }
  }

  //#endregion

  //#region business logic

  private initializeForm() {
    this.filterFormGroup = this.formBuilder.group({
      SurveyID: [this._dtoSearch.SurveyID, [Validators.required]],
      SortOrder: [this._dtoSearch.SortOrder],
      DimensionID: [this._dtoSearch.DimensionID],
      ThemeID: [this._dtoSearch.ThemeID],
      QuestionID: [this._dtoSearch.QuestionID],
    })

    this.filterFormGroup.get('SurveyID')?.setValue(this.defaultSurveyID)
  }

  private loadDimensions() {
    let surveyID: number = 1
    if (null != this._dtoSearch.SurveyID) {
      surveyID = this._dtoSearch.SurveyID
    }

    this.lookupService.listDimensionsAll().subscribe({
      complete: () => this.processManagerService.notify('dimensions'),
      next: (response: JsonResponse) => {
        // Cache All Dimensions in this._listDimensions
        this._listDimensions = Object.keys(response.data).map(k => {
          return response.data[k]
        })

        // Filter All Dimensions down to those pertaining top the currently selected survey
        this.luDimensions = this._listDimensions
          .filter((d: Dimension) => d.Survey?.ID === surveyID)
          .map(entity => {
            return {ID: entity.ID ?? 0, Name: this.appTranslatePipe.transform(entity, 'Name')};
          })
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  private loadQuestions() {
    this.adminService.listQuestions(this._dtoSearch).subscribe({
      complete: () => this.processManagerService.notify('questions'),
      next: (response: JsonResponse) => {
        this._dtoSearch = response.data
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private loadSurveys() {
    this.lookupService.listSurveys().subscribe({
      complete: () => this.processManagerService.notify('surveys'),
      next: (response: JsonResponse) => {
        this.luSurveys = Object.keys(response.data).map(key => {
          let entity: SurveyDto = response.data[key]
          return {ID: entity.ID ?? 0, Name: this.appTranslatePipe.transform(entity, 'Name')};
        });
        this.surveys = Object.keys(response.data).map(key => {
          let entity: SurveyDto = response.data[key]
          return entity;
        });
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  private loadThemes() {
    this.lookupService.listThemes().subscribe({
      complete: () => this.processManagerService.notify('themes'),
      next: (response: JsonResponse) => {
        this.luThemes = Object.keys(response.data).map(key => {
          let entity: Theme = response.data[key]
          return {ID: entity.ID ?? 0, Name: this.appTranslatePipe.transform(entity, 'Name')};
        });
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public submitSearchForm() {
    let surveyID = this.filterFormGroup.controls['SurveyID'].value;
    let questionID = this.filterFormGroup.controls['QuestionID'].value;
    let themeID = this.filterFormGroup.controls['ThemeID'].value;
    let dimensionID = this.filterFormGroup.controls['DimensionID'].value;
    let sortOrder = this.filterFormGroup.controls['SortOrder'].value;

    const criteria: QuestionSearch = {
      DimensionID: dimensionID,
      QuestionID: questionID,
      SortOrder: sortOrder,
      SurveyID: surveyID,
      ThemeID: themeID
    }

    this.processManagerService.force()
    this.adminService.listQuestions(criteria).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this._dtoSearch = response.data
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  //#endregion

}
