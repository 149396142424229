/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class UserRoles
 */
export type EnumUserRoles = 'System' | 'WordPress' | 'Admin' | 'Participant' | 'Distributor';

export const EnumUserRoles = {
    System: 'System' as EnumUserRoles,
    WordPress: 'WordPress' as EnumUserRoles,
    Admin: 'Admin' as EnumUserRoles,
    Participant: 'Participant' as EnumUserRoles,
    Distributor: 'Distributor' as EnumUserRoles
};

