import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params} from "@angular/router";
import {TranslatePipe, TranslateService} from "@ngx-translate/core";
import {ConfirmationService} from "primeng/api";
import {BaseComponent} from "../../components/base/base.component";
import {
  AssessmentResponse,
  Configuration,
  ContentBlock,
  DimensionAnswer,
  DimensionSimpleDto,
  GuestService,
  JsonResponse,
  ParticipantAuthenticate,
  ParticipantDto,
  ParticipantProfile,
  ParticipantService,
  QuestionAnswerSimple,
  QuestionSimple,
  WelcomeData
} from "../../core/api";
import {UserAssessment} from "../../models/user-assessment.model";
import {UserQuestionAnswer} from "../../models/user-question-answer.model";
import {UserQuestion} from "../../models/user-question.model";
import {AppTranslatePipe} from "../../pipes/app-translate.pipe";
import {AuthService} from "../../service/auth.service";
import {FormValidationService} from "../../service/form-validation.service";
import {ProcessManagerService} from "../../service/process-manager.service";
import {UserAssessmentService} from "../../service/user-assessment.service";
import {AssessmentQuestionComponent} from "../assessment-question/assessment-question.component";

@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  //styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent extends BaseComponent implements OnInit {
  // variables

  private accessToken: string = '';
  public confirmationText: string = '';
  public displayAssessment: boolean = false
  public displayAssessmentComplete: boolean = false
  public displayMissingAccessToken: boolean = false
  public displayParticipantAuthentication: boolean = false
  public displayParticipantProfile: boolean = false
  public displaySavedForLater: boolean = false
  public participantAuthenticationForm!: FormGroup
  public participantProfileForm!: FormGroup
  private userAssessment: UserAssessment = null!
  public userQuestion: UserQuestion = null!
  public welcomeScreenText: ContentBlock = null!

  // properties

  @ViewChild('assessmentQuestionElement') assessmentQuestionElement: AssessmentQuestionComponent = null!

  // constructor

  constructor(
    private appTranslatePipe: AppTranslatePipe,
    private authService: AuthService,
    private configuration: Configuration,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private participantService: ParticipantService,
    private guestService: GuestService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private translate: TranslatePipe,
    private translateService: TranslateService,
    private userAssessmentService: UserAssessmentService
  ) {
    super(processManagerService)
  }

  // event handlers

  public clickDownloadReport() {
    this.downloadReport()
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcess('participant')
    this.processManagerService.unforce()

    /** NOTES
     * 1) How do I know if the user needs to complete his profile? Is there a field I need to be looking at?
     * 2) I think that the welcome text should always be displayed once the user is authenticated. Is there a method that allows me to do that?
     * **/
    this.route.params.subscribe((params: Params) => {
      if ('undefined' !== typeof (params['token'])) {
        this.accessToken = params['token']
      }

      if ('' === this.accessToken) {
        this.displayMissingAccessToken = true
      } else {
        const loggedIn: boolean = this.authService.isUserLoggedIn

        this.displayParticipantProfile = false

        if (!loggedIn) {
          this.loadWelcome()
        } else {
          this.loadUserAssessment()

          if (this.userAssessment) {
            if (!this.userAssessment.ProfileCompleted) {
              this.initializeParticipantProfileForm()

              this.displayParticipantProfile = true
            } else {
              this.userAssessmentService.reloadUserAssessmentProgress()

              this.displayInitialQuestion()
            }
          } else {
            const participantAuthenticate: ParticipantAuthenticate = {
              AccessToken: this.accessToken,
              Email: this.authService.user.Email,
            }
            this.saveParticipantAuthentication(participantAuthenticate)
          }
        }
      }
    })
  }

  // /**
  //  * @see AssessmentQuestionComponent.onRequestConfirmation
  //  */
  // public onRequestConfirmation() {
  //   this.requestConfirmation()
  // }

  /**
   * @see AssessmentQuestionComponent.onComplete
   */
  public onCompleteAssessment() {
    this.completeAssessment()
  }

  /**
   * @see AssessmentQuestionComponent.onNext
   */
  public onNextQuestion(questionID: number) {
    this.userAssessmentService.incrementQuestionIndex()
    this.loadNextQuestion(questionID)
  }

  /**
   * @see AssessmentQuestionComponent.onPrevious
   */
  public onPreviousQuestion(questionID: number) {
    this.userAssessmentService.decrementQuestionIndex()
    this.loadPreviousQuestion(questionID)
  }

  /**
   * @see AssessmentQuestionComponent.onSaved
   */
  public onQuestionSaved() {
    this.processManagerService.unforce()
  }

  /**
   * @see AssessmentQuestionComponent.onSaveForLater
   */
  public onQuestionSaveForLater() {
    this.displayAssessment = false
    this.displaySavedForLater = true

    this.userAssessmentService.reset()
    this.authService.unauthorize()
  }

  /**
   * @see AssessmentQuestionComponent.onSaving
   */
  public onQuestionSaving() {
    this.processManagerService.force()
  }

  public submitParticipantAuthentication() {
    if (this.participantAuthenticationForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.participantAuthenticationForm)
      return
    }

    const participantAuthenticate: ParticipantAuthenticate = {
      AccessToken: this.accessToken,
      Email: this.participantAuthenticationForm.get('email')?.value,
    }

    this.saveParticipantAuthentication(participantAuthenticate)
  }

  public submitParticipantProfile() {
    this.saveParticipantProfile()
  }

  // business logic

  // private requestConfirmation() {
  //   alert('requestConfirmation-Not-Saving')
  //   this.displayAssessmentComplete = true
  //   return
  // }

  private completeAssessment() {
    //alert('completeAssessment-Saving')
    //return
    let assessmentResponse: AssessmentResponse = {
      ID: this.userQuestion.AssessmentID
    }

    this.processManagerService.force()
    this.participantService.assessmentComplete(assessmentResponse).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.confirmationText = this.appTranslatePipe.transform(response.data.ConfirmationText, 'Content')

        this.displayAssessment = false
        this.displayAssessmentComplete = true

        this.userAssessmentService.reset()
        this.authService.unauthorize()
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        } else {
          this.handleApiException(error)
        }
      }
    })
  }

  private displayInitialQuestion() {
    this.translateService.get([
      'PARTICIPANT.WELCOME.CONTINUE_SURVEY_HEADER',
      'PARTICIPANT.WELCOME.CONTINUE_SURVEY_MESSAGE'
    ]).subscribe({
      // Note: this is JS object deconstruction, as the translate service returns an object with keys equal to the passed
      //     parameters.
      next: ({ 'PARTICIPANT.WELCOME.CONTINUE_SURVEY_HEADER': header, 'PARTICIPANT.WELCOME.CONTINUE_SURVEY_MESSAGE': message }) => {
        this.displayAssessment = true
        let question: QuestionSimple = this.userAssessment.Questions[this.userAssessmentService.progress.QuestionIndex - 1]
        this.loadNextQuestion(null!)

        if ((null != this.userAssessmentService.progress.CurrentQuestionID) && (this.userAssessmentService.progress.QuestionIndex > 1)) {
          this.confirmationService.confirm({
            accept: () => {
              this.loadQuestion(question, this.userAssessmentService.progress.QuestionIndex - 1)
            },
            header: header,
            message: message,
            reject: () => {
              this.userAssessmentService.resetQuestionIndex()
            }
          })
        }

        /**
         * Always scroll to the top of the page (as the page appears
         * to be focused on at the same positionof the previous page)
         */
        setTimeout(() => {
          window.scrollTo(0, 0);
        })

      }
    })
  }

  private downloadReport() {

    let url: string = this.configuration.basePath + '/participant/assessment-report?t=' + this.accessToken
    window.open(url)
  }

  private initializeParticipantAuthenticationForm() {
    this.participantAuthenticationForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    })
  }

  private initializeParticipantProfileForm() {
    let participant: ParticipantDto = this.userAssessment.Participant

    this.participantProfileForm = this.formBuilder.group({
      email: [participant.Email, []],
      firstName: [participant.FirstName, [Validators.required]],
      lastName: [participant.LastName, []],
      organization: [participant.Organization, []],
      position: [participant.Position, []],
      title: [participant.Title, []],
    })

    this.participantProfileForm.get('email')?.disable()
  }

  private loadNextQuestion(questionID: number) {
    let question: QuestionSimple = null!
    let index: number = 0

    if (null == questionID) {
      question = this.userAssessment.Questions[index]
    } else {
      index = this.userAssessment.Questions.findIndex(q => q.ID == questionID)
      if ((index != -1) && (index < this.userAssessment.Questions.length - 1)) {
        index += 1
        question = this.userAssessment.Questions[index]
      }
    }

    this.loadQuestion(question, index)
  }

  private loadPreviousQuestion(questionID: number) {
    let question: QuestionSimple = null!
    let index: number = 0

    if (null == questionID) {
      question = this.userAssessment.Questions[index]
    } else {
      index = this.userAssessment.Questions.findIndex(q => q.ID == questionID)
      if ((index != -1) && (index > 0)) {
        index -= 1
        question = this.userAssessment.Questions[index]
      }
    }

    this.loadQuestion(question, index)
  }

  private loadQuestion(question: QuestionSimple, index: number) {
    let dimensionID: number = question.DimensionId!
    let questionAnswerIDs: number[] = (question as any)['QuestionAnswerIDs']! as number[]
    let dimension: DimensionSimpleDto = this.userAssessment.Dimensions[dimensionID]

    let userQuestion: UserQuestion = new UserQuestion()
    questionAnswerIDs.forEach(id => {
      let questionAnswer: QuestionAnswerSimple = this.userAssessment.QuestionAnswers[id]
      let dimensionAnswer: DimensionAnswer = this.userAssessment.DimensionAnswers[questionAnswer.DimensionAnswerId!]

      let userQuestionAnswer: UserQuestionAnswer = new UserQuestionAnswer()
      userQuestionAnswer.ID = questionAnswer.ID!
      userQuestionAnswer.Localizations = dimensionAnswer.Localizations
      userQuestion.UserQuestionAnswers.push(userQuestionAnswer)
    })

    userQuestion.AssessmentID = this.userAssessment.Assessment.ID!
    userQuestion.Dimension = dimension
    userQuestion.Index = index
    userQuestion.Question = question
    userQuestion.Total = this.userAssessment.Questions.length

    this.userQuestion = userQuestion

    setTimeout(() => {
      this.assessmentQuestionElement.load()
    })
  }

  private loadUserAssessment() {
    let userAssessment: UserAssessment = this.userAssessmentService.loadUserAssessment()

    if (null != userAssessment) {
      this.welcomeScreenText = userAssessment.WelcomeScreenText
    }

    this.userAssessment = userAssessment
  }

  private loadWelcome() {
    this.processManagerService.force()
    this.guestService.welcome(this.accessToken).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        let welcomeData: WelcomeData = response.data

        if (welcomeData.Error) {
          this.handleException(welcomeData.Error)
        } else {
          this.initializeParticipantAuthenticationForm()
          this.displayParticipantAuthentication = true
        }
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        } else {
          this.handleApiException(error)
        }
      }
    })
  }

  private saveParticipantAuthentication(participantAuthenticate: ParticipantAuthenticate) {
    this.processManagerService.force()
    this.guestService.welcomeAuthenticate(participantAuthenticate).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.authService.authorize(response.data.UserLoginResponse)

        this.welcomeScreenText = response.data.WelcomeScreenText

        this.userAssessment = new UserAssessment()
        this.userAssessment.Assessment = response.data.Assessment
        this.userAssessment.DimensionAnswers = response.data.Questions.DimensionAnswers
        this.userAssessment.Dimensions = response.data.Questions.Dimensions
        this.userAssessment.Participant = response.data.Participant
        this.userAssessment.QuestionAnswers = response.data.Questions.QuestionAnswers
        this.userAssessment.Questions = Object.entries(response.data.Questions.Questions).map(o => o[1]) as QuestionSimple[]
        /**
         * edit apugh 2022-11-25
         * We are retaining this sorting, however the results returned
         * from the API are now shuffled randomly and 'REVISED'
         * question numbers are being assigned to the SortOrder property
         */
        this.userAssessment.Questions.sort((q1, q2) => {
          return q1.SortOrder! - q2.SortOrder!
        })

        this.initializeParticipantProfileForm()
        this.saveUserAssessment()

        this.displayParticipantAuthentication = false
        this.displayParticipantProfile = true
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        } else {
          this.handleApiException(error)
        }
      }
    })
  }

  private saveParticipantProfile() {
    if (this.participantProfileForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.participantProfileForm)
      return
    }

    let participantProfile: ParticipantProfile = {
      AssessmentID: this.userAssessment.Assessment!.ID,
      Email: this.participantProfileForm.get('email')?.value,
      FirstName: this.participantProfileForm.get('firstName')?.value,
      ID: this.userAssessment.Participant?.ID,
      LastName: this.participantProfileForm.get('lastName')?.value,
      Organization: this.participantProfileForm.get('organization')?.value,
      Position: this.participantProfileForm.get('position')?.value,
      Title: this.participantProfileForm.get('title')?.value
    }

    this.processManagerService.force()
    this.participantService.saveProfile(participantProfile).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.userAssessment.ProfileCompleted = true

        this.saveUserAssessment()

        this.userAssessmentService.initializeUserAssessmentProgress()
        this.userAssessmentService.reloadUserAssessmentProgress()

        this.displayParticipantProfile = false

        this.displayInitialQuestion()

      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        } else {
          this.handleApiException(error)
        }
      }
    })
  }

  private saveUserAssessment() {
    this.userAssessmentService.saveUserAssessment(this.userAssessment)
  }
}
