<div class="light-input">
  <h1>
    {{'COMPONENTS.REMINDER_EMAIL_DETAIL.HEADING'|translate}}
  </h1>
  <p [innerHTML]="'COMPONENTS.REMINDER_EMAIL_DETAIL.INSTRUCTIONS'|translate"></p>
  <section class="dark-theme">
    <app-localization-toggle (onToggled)="onLanguageToggled($event)"></app-localization-toggle>
    <form [formGroup]="emailEditForm" (submit)="submitReminderEmail()" autocomplete="off" novalidate *ngIf="emailEditForm">
      <fieldset>
        <div class="p-fluid">
          <div class="grid">
            <div class="col-12 md:col-4 form-field">
              <label for="senderName">{{'COMPONENTS.REMINDER_EMAIL_DETAIL.SENDER_NAME'|translate}}</label>
              <input pInputText formControlName="senderName" id="senderName" />
              <app-validation-error fieldName="senderName" [formGroup]="emailEditForm" validatorName="required">{{'COMPONENTS.REMINDER_EMAIL_DETAIL.ERROR_SENDER_NAME'|translate}}</app-validation-error>
            </div>
            <div class="col-12 md:col-5 form-field">
              <label for="senderEmail">{{'COMPONENTS.REMINDER_EMAIL_DETAIL.SENDER_EMAIL'|translate}}</label>
              <input type="email" pInputText formControlName="senderEmail" id="senderEmail" />
              <app-validation-error fieldName="senderEmail" [formGroup]="emailEditForm" validatorName="required">{{'COMPONENTS.REMINDER_EMAIL_DETAIL.ERROR_SENDER_EMAIL'|translate}}</app-validation-error>
              <app-validation-error fieldName="senderEmail" [formGroup]="emailEditForm" validatorName="email">{{'COMPONENTS.REMINDER_EMAIL_DETAIL.ERROR_SENDER_EMAIL_FORMAT'|translate}}</app-validation-error>
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-9 form-field">
              <app-localization-input-text controlName="subject"
                                           displayName="{{'COMPONENTS.REMINDER_EMAIL_DETAIL.SUBJECT'|translate}}"
                                           [formGroup]="emailEditForm"
                                           [maxLength]="100"
                                           [required]="true">
              </app-localization-input-text>
            </div>
          </div>
          <div class="grid">
            <div class="field col-9">
              <ng-container *ngIf="isAdmin">
                <app-localization-input-text controlName="salutation"
                                             displayName="{{'COMPONENTS.INVITATION_EMAIL_DETAIL.SALUTATION'|translate}}"
                                             [formGroup]="emailEditForm"
                                             [maxLength]="100"
                                             [required]="true">
                </app-localization-input-text>
              </ng-container>
              <ng-container *ngIf="!isAdmin">
                <label>{{'COMPONENTS.REMINDER_EMAIL_DETAIL.SALUTATION'|translate}}</label>
                <div class="read-only-block" [innerHTML]="salutationBlock|appTranslate:'Content'"></div>
              </ng-container>
            </div>
          </div>
          <div class="grid">
            <div class="field col-9">
              <app-localization-editor [configuration]="editorConfiguration"
                                       controlName="reminderIntro"
                                       displayName="{{'COMPONENTS.REMINDER_EMAIL_DETAIL.REMINDER_INTRO'|translate}}"
                                       [formGroup]="emailEditForm"
                                       [height]="200"
                                       [required]="true">
              </app-localization-editor>
            </div>
          </div>
          <div class="grid">
            <div class="field col-9">
              <ng-container *ngIf="isAdmin">
                <app-localization-input-text
                                         controlName="assessmentUrlText"
                                         displayName="{{'COMPONENTS.REMINDER_EMAIL_DETAIL.ASSESSMENT_URL_TEXT'|translate}}"
                                         [formGroup]="emailEditForm"
                                         [maxLength]="100"
                                         [required]="true">
                </app-localization-input-text>
              </ng-container>
              <ng-container *ngIf="!isAdmin">
                <label>{{'COMPONENTS.REMINDER_EMAIL_DETAIL.ASSESSMENT_URL_TEXT'|translate}}</label>
                <div class="read-only-block" [innerHTML]="assessmentUrlTextBlock|appTranslate:'Content'"></div>
              </ng-container>
            </div>
          </div>
          <div class="grid">
            <div class="field col-9">
              <ng-container *ngIf="isAdmin">
                <app-localization-editor [configuration]="editorConfiguration"
                                         controlName="assessmentVisibility0"
                                         displayName="{{'COMPONENTS.REMINDER_EMAIL_DETAIL.ASSESSMENT_VISIBILITY_0'|translate}}"
                                         [formGroup]="emailEditForm"
                                         [height]="200"
                                         [required]="true">
                </app-localization-editor>
              </ng-container>
              <ng-container *ngIf="!isAdmin">
                <label>{{'COMPONENTS.REMINDER_EMAIL_DETAIL.ASSESSMENT_VISIBILITY_0'|translate}}</label>
                <div class="read-only-block" [innerHTML]="assessmentVisibility0Block|appTranslate:'Content'"></div>
              </ng-container>
            </div>
          </div>
          <div class="grid">
            <div class="field col-9">
              <ng-container *ngIf="isAdmin">
                <app-localization-editor [configuration]="editorConfiguration"
                                         controlName="assessmentVisibility1"
                                         displayName="{{'COMPONENTS.REMINDER_EMAIL_DETAIL.ASSESSMENT_VISIBILITY_1'|translate}}"
                                         [formGroup]="emailEditForm"
                                         [height]="200"
                                         [required]="true">
                </app-localization-editor>
              </ng-container>
              <ng-container *ngIf="!isAdmin">
                <label>{{'COMPONENTS.REMINDER_EMAIL_DETAIL.ASSESSMENT_VISIBILITY_1'|translate}}</label>
                <div class="read-only-block" [innerHTML]="assessmentVisibility1Block|appTranslate:'Content'"></div>
              </ng-container>
            </div>
          </div>
          <div class="grid">
            <div class="field col-9">
              <app-localization-editor [configuration]="editorConfiguration"
                                       controlName="reminderOutro"
                                       displayName="{{'COMPONENTS.REMINDER_EMAIL_DETAIL.REMINDER_OUTRO'|translate}}"
                                       [formGroup]="emailEditForm"
                                       [height]="200"
                                       [required]="true">
              </app-localization-editor>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="buttons">
        <p-button type="submit" label="{{'COMMON.UI.SAVE'|translate}}"></p-button>
        <p-button type="button" label="{{'COMMON.UI.CANCEL'|translate}}" (click)="clickCancel()"></p-button>
      </div>
    </form>
  </section>
</div>
