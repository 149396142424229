import { Injectable } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Language, Localization } from '../core/api'

@Injectable()
export class LocalizationService {
  // constructors

  constructor(private formBuilder: FormBuilder) {
  }

  // business logic

  public returnLocalizationValue(languageID: number, item: string, formGroup: FormGroup): string {
    let itemFormGroup: FormGroup = formGroup.get(item) as FormGroup
    let localizations: FormArray = itemFormGroup.get('localizations') as FormArray
    var value: string = ''

    if (itemFormGroup.get('languageID')?.value == languageID) {
      value = itemFormGroup.get('value')?.value
    }
    else {
      let localization: any = localizations.controls.find(l => l.get('languageID')?.value == languageID)
      if (undefined != localization) {
        value = (localization as FormGroup).get('value')?.value
      }
    }

    return value
  }

  public returnLocalizationFormItem(languageID: number): FormGroup {
    return this.formBuilder.group({
      languageID: [languageID, []],
      value: [null, [Validators.required]],
    })
  }

  public returnLocalizationFormGroup(languageID: number, item: string, required: boolean, maxLength: number, languages: Language[], localizations: Localization[]): FormGroup {
    let formArrayLocalizations: FormArray = this.formBuilder.array([])
    var currentValue: string = null!
    let validators: any = []

    if (required) {
      validators.push(Validators.required)
    }
    if (maxLength) {
      validators.push(Validators.maxLength(maxLength))
    }
    
    for (var i: number = 0; i < languages.length; i++) {
      let language: Language = languages[i]
      let localization: any = localizations.find(l => l.LanguageId == language.ID)
      var value: string = null!

      if (undefined != localization) {
        value = (localization as any)[item]
        if (language.ID == languageID) {
          currentValue = value
        }
      }

      let formGroup: FormGroup = this.formBuilder.group({
        languageID: [language.ID, []],
        value: [value, []],
      })

      if (validators.length > 0) {
        formGroup.get('value')?.setValidators(validators)
      }

      formArrayLocalizations.push(formGroup)
    }

    let formGroup: FormGroup = this.formBuilder.group({
      languageID: [languageID, []],
      localizations: formArrayLocalizations,
      value: [currentValue, []],
    })

    if (validators.length > 0) {
      formGroup.get('value')?.setValidators(validators)
    }

    return formGroup
  }

  public updateLocalizationFormGroupValidators(formGroup: FormGroup, required: boolean, maxLength: number) {
    let formArrayLocalizations: FormArray = formGroup.get('localizations') as FormArray
    let validators: any = []

    if (required) {
      validators.push(Validators.required)
    }
    if (maxLength) {
      validators.push(Validators.maxLength(maxLength))
    }

    for (var i: number = 0; i < formArrayLocalizations.controls.length; i++) {
      formArrayLocalizations.controls[i].get('value')?.setValidators(validators)
      formArrayLocalizations.controls[i].get('value')?.markAsPristine()
      formArrayLocalizations.controls[i].get('value')?.markAsUntouched()
      formArrayLocalizations.controls[i].get('value')?.updateValueAndValidity()
    }

    formGroup.get('value')?.setValidators(validators)
    formGroup.get('value')?.markAsPristine()
    formGroup.get('value')?.markAsUntouched()
    formGroup.get('value')?.updateValueAndValidity()
  }
}
