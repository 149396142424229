import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { wpConfiguration } from '../app.module';
import { BaseComponent } from '../components/base/base.component';
import { EnumUserRoles } from "../core/api";
import { AuthService } from '../service/auth.service';
import { FormValidationService } from '../service/form-validation.service';
import { ProcessManagerService } from '../service/process-manager.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends BaseComponent implements OnInit {
  //#region variables

  public loginForm!: FormGroup;

  //#endregion

  //#region constructor

  constructor(
    private authService: AuthService,
    public configuration: wpConfiguration,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    processManagerService: ProcessManagerService,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  override ngOnInit() {
    this.processManagerService.addProcesses(['login'])
    this.processManagerService.unforce()

    if ('/logout' === this.router.url) {
      this.authService.logout();
      this.router.navigate(['/login'])
    } else if (this.authService.isUserLoggedIn) {
      this.redirectLoggedInUser()
    } else {
      this.initializeForm()
    }
  }

  public submit() {
    this.login()
  }

  //#endregion

  //#region business logic

  private initializeForm() {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  private login() {
    if (this.loginForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.loginForm)
      return
    }

    const userName: string = this.loginForm.get('email')?.value;
    const password: string = this.loginForm.get('password')?.value;

    this.processManagerService.force()
    this.authService.login(userName, password).subscribe({
      next: () => this.redirectLoggedInUser(),
      error: (error: string) => this.handleException(error),
      complete: () => this.processManagerService.unforce()
    })
  }

  private redirectLoggedInUser() : void {
    if (this.authService.isUserLoggedIn) {
      const roleName = this.authService.user.UserRole?.Name;
      switch (roleName) {
        case EnumUserRoles.Admin:
          this.router.navigate(['/admin/dashboard']);
          break
        case EnumUserRoles.Distributor:
          this.router.navigate(['/distributor/dashboard']);
          break
        case EnumUserRoles.Participant:
          this.router.navigate(['/participant']);
          break
        default:
          throw Error('Invalid User Role')
      }
    }
  }

  //#endregion
}
