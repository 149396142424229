<div class="layout-content-inner gradient">
  <div class="wrapper">
    <form *ngIf="recoverAssessmentLinkForm" [formGroup]="recoverAssessmentLinkForm" (ngSubmit)="submit()" class="recover-assessment-link-form dark-theme transparent">
      <p class="form-heading">
        {{'GUEST.LOGIN.PLEASE_ENTER_YOUR_EMAIL_ASSESSMENT_RECOVERY'|translate}}
      </p>
      <div class="p-fluid">
        <div class="grid">
          <div class="form-field col-12">
            <label for="inputEmail" class="sr-only">{{'ADMIN.USER_DETAIL.EMAIL'|translate}}</label>
            <input type="text" pInputText id="inputEmail" formControlName="email" placeholder="{{'ADMIN.USER_DETAIL.EMAIL'|translate}}" required autofocus>
            <app-validation-error fieldName="email" [formGroup]="recoverAssessmentLinkForm" validatorName="required">{{'GUEST.LOGIN.ERROR_EMAIL'|translate}}</app-validation-error>
            <app-validation-error fieldName="email" [formGroup]="recoverAssessmentLinkForm" validatorName="email">{{'GUEST.LOGIN.ERROR_EMAIL_FORMAT'|translate}}</app-validation-error>
          </div>
        </div>
        <div class="grid">
          <div class="form-field col-12">
            <p-button type="submit" label="{{'GUEST.LOGIN.RECOVER_ASSESSMENT_LINK'|translate}}"></p-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
