/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ViewDistributorParticipantsDto } from './viewDistributorParticipantsDto';


export interface ParticipantSearchAllOf { 
    /**
     * ID of the Assessment Status to filter by
     */
    AssessmentStatusID?: number | null;
    /**
     * ID of the Distribution to filter by
     */
    DistributionID?: number | null;
    /**
     * ID of the Distributor to filter by
     */
    DistributorID?: number | null;
    /**
     * Search terms
     */
    SearchTerm?: string | null;
    /**
     * $items *_/
     */
    items?: Set<ViewDistributorParticipantsDto>;
}

