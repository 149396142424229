<!-- Page Content -->
<div class="layout-content-inner gradient">
  <div class="wrapper">
    <form *ngIf="forgotPasswordForm" [formGroup]="forgotPasswordForm" (ngSubmit)="submitForgotPassword()" class="forgot-password-form dark-theme transparent">
      <p class="form-heading" [innerHTML]="'GUEST.LOGIN.INSTRUCTIONS_FORGOT_PASSWORD'|translate"></p>
      <div class="p-fluid">
        <div class="grid">
          <div class="form-field col-12">
            <label for="inputEmail" class="sr-only">{{'GUEST.LOGIN.EMAIL'|translate}}</label>
            <input type="text" pInputText id="inputEmail" formControlName="email" placeholder="{{'GUEST.LOGIN.EMAIL'|translate}}" required autofocus>
            <app-validation-error fieldName="email" [formGroup]="forgotPasswordForm" validatorName="required">{{'GUEST.LOGIN.ERROR_EMAIL'|translate}}</app-validation-error>
          </div>
        </div>
        <div class="grid">
          <div class="form-field col-12">
            <p-button type="submit" label="{{'GUEST.LOGIN.RESET_PASSWORD'|translate}}"></p-button>
          </div>
        </div>
      </div>
    </form>
    <form *ngIf="resetPasswordForm" [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPassword()" class="reset-password-form dark-theme transparent">
      <p class="form-heading">{{'GUEST.LOGIN.RESET_YOUR_PASSWORD'|translate}}</p>
      <div class="p-fluid">
        <div class="grid">
          <div class="form-field col-12">
            <label for="inputEmail" class="sr-only">{{'GUEST.LOGIN.CHANGE_PASSWORD'|translate}}</label>
            <p-password [toggleMask]="true" formControlName="password" id="inputPassword" placeholder="{{'GUEST.LOGIN.CHANGE_PASSWORD'|translate}}" required type="password"></p-password>
            <app-validation-error fieldName="password" [formGroup]="resetPasswordForm" validatorName="required">{{'GUEST.LOGIN.ERROR_PASSWORD'|translate}}</app-validation-error>
            <app-validation-error fieldName="password" [formGroup]="resetPasswordForm" validatorName="invalidStrength">{{'GUEST.LOGIN.ERROR_PASSWORD_STRENGTH'|translate}}</app-validation-error>
          </div>
          <div class="form-field col-12">
            <label for="inputEmail" class="sr-only">{{'GUEST.LOGIN.CONFIRM_PASSWORD'|translate}}</label>
            <p-password [feedback]="false" [toggleMask]="true" formControlName="passwordConfirm" id="inputPasswordConfirm" placeholder="{{'GUEST.LOGIN.CONFIRM_PASSWORD'|translate}}" required type="password"></p-password>
            <app-validation-error fieldName="passwordConfirm" [formGroup]="resetPasswordForm" validatorName="required">{{'GUEST.LOGIN.ERROR_CONFIRM_PASSWORD'|translate}}</app-validation-error>
            <app-validation-error fieldName="passwordConfirm" [formGroup]="resetPasswordForm" validatorName="mismatch">{{'GUEST.LOGIN.ERROR_PASSWORD_MISMATCH'|translate}}</app-validation-error>
          </div>
        </div>
        <div class="grid">
          <div class="form-field col-12">
            <p-button type="submit" label="{{'GUEST.LOGIN.CHANGE_PASSWORD'|translate}}"></p-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
