import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BaseComponent } from "../../components/base/base.component";
import { LocalizationInputBaseComponent } from '../../components/controls/localization-input-base/localization-input-base.component';
import { AdminService, DistributorService, EmailBlock, EmailEdit, JsonResponse, Language, Localization } from '../../core/api';
import { EditorDisplayOptions } from '../../models/editor-display-options.model';
import { AuthService } from '../../service/auth.service';
import { FormValidationService } from '../../service/form-validation.service';
import { LanguagesService } from '../../service/languages.service';
import { LocalizationService } from '../../service/localization.service';
import { ProcessManagerService } from "../../service/process-manager.service";
import {ILocalizable} from "../../pipes/app-translate.pipe";

@Component({
  selector: 'app-reminder-email-detail',
  templateUrl: './reminder-email-detail.component.html',
  styleUrls: ['./reminder-email-detail.component.scss']
})
export class ReminderEmailDetailComponent extends BaseComponent implements OnInit {
  //#region variables

  public assessmentUrlTextBlock: EmailBlock = null!
  public assessmentVisibility0Block: EmailBlock = null!
  public assessmentVisibility1Block: EmailBlock = null!
  public editorConfiguration: any
  public emailEdit: EmailEdit = null!
  public emailEditForm: FormGroup = null!
  public isAdmin: boolean = false
  public languages: Language[] = null!
  public salutationBlock: EmailBlock = null!

  //#endregion

  //#region constants

  private static BLOCK_ASSESSMENT_URL_TEXT: string = 'AssessmentUrlText'
  private static BLOCK_ASSESSMENT_VISIBILITY_0: string = 'AssessmentVisibility:0'
  private static BLOCK_ASSESSMENT_VISIBILITY_1: string = 'AssessmentVisibility:1'
  private static BLOCK_REMINDER_INTRO: string = 'ReminderIntro'
  private static BLOCK_REMINDER_OUTRO: string = 'ReminderOutro'
  private static BLOCK_SALUTATION: string = 'Salutation'

  //#endregion

  //#region properties

  @ViewChildren(LocalizationInputBaseComponent) localizationInputElements: QueryList<LocalizationInputBaseComponent> = null!

  //#endregion

  //#region constructor

  constructor(
    private activatedRoute: ActivatedRoute,
    private adminService: AdminService,
    private authService: AuthService,
    private distributorService: DistributorService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private languagesService: LanguagesService,
    private localizationService: LocalizationService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickCancel() {
    if (this.isAdmin) {
      this.router.navigate(['/admin', 'content'])
    }
    else {
      this.router.navigate(['/distributor', 'dashboard'])
    }
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['contentBlock'])
    this.processManagerService.unforce()

    this.languages = this.languagesService.returnLanguages()

    this.editorConfiguration = EditorDisplayOptions.returnConfiguration()

    this.activatedRoute.params.subscribe(params => {
      if (this.activatedRoute.snapshot.data && (this.activatedRoute.snapshot.data['isAdmin'] !== undefined)) {
        this.isAdmin = this.activatedRoute.snapshot.data['isAdmin']
      }

      if (!this.isAdmin) {
        this.loadDistributorEmail()
      }
      else {
        this.loadAdministrationEmail()
      }
    })
  }

  public onLanguageToggled(languageID: number) {
    this.localizationInputElements.forEach(c => c.toggle(languageID))
  }

  public submitReminderEmail() {
    if (!this.isAdmin) {
      this.saveDistributorEmail()
    }
    else {
      this.saveAdministrationEmail()
    }
  }

  //#endregion

  //#region business logic

  private initializeForm() {
    let blocks: EmailBlock[] = Object.entries(this.emailEdit.Blocks!).map(o => o[1]) as EmailBlock[]
    let assessmentUrlTextBlock: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_ASSESSMENT_URL_TEXT)!
    let assessmentVisibility0Block: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_ASSESSMENT_VISIBILITY_0)!
    let assessmentVisibility1Block: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_ASSESSMENT_VISIBILITY_1)!
    let reminderIntroBlock: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_REMINDER_INTRO)!
    let reminderOutroBlock: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_REMINDER_OUTRO)!
    let salutationBlock: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_SALUTATION)!
    let messageLocalizations: Localization[] = this.returnLocalizations(this.emailEdit.Message)

    this.assessmentUrlTextBlock = assessmentUrlTextBlock
    this.assessmentVisibility0Block = assessmentVisibility0Block
    this.assessmentVisibility1Block = assessmentVisibility1Block
    this.salutationBlock = salutationBlock

    this.emailEditForm = this.formBuilder.group({
      assessmentUrlText: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Content', true, null!, this.languages, this.returnLocalizations(assessmentUrlTextBlock)),
      assessmentVisibility0: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Content', true, null!, this.languages, this.returnLocalizations(assessmentVisibility0Block)),
      assessmentVisibility1: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Content', true, null!, this.languages, this.returnLocalizations(assessmentVisibility1Block)),
      reminderIntro: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Content', true, null!, this.languages, this.returnLocalizations(reminderIntroBlock)),
      reminderOutro: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Content', true, null!, this.languages, this.returnLocalizations(reminderOutroBlock)),
      salutation: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Content', true, null!, this.languages, this.returnLocalizations(salutationBlock)),
      senderEmail: [this.emailEdit.Message?.SenderEmail, [Validators.required, Validators.email]],
      senderName: [this.emailEdit.Message?.SenderName, [Validators.required]],
      subject: this.localizationService.returnLocalizationFormGroup(this.authService.languageID, 'Subject', true, 100, this.languages, messageLocalizations),
    })

    if (!this.isAdmin) {
      this.emailEditForm.get('assessmentUrlText')?.disable()
      this.emailEditForm.get('assessmentVisibility0')?.disable()
      this.emailEditForm.get('assessmentVisibility1')?.disable()
      this.emailEditForm.get('salutationBlock')?.disable()
    }
  }

  private loadAdministrationEmail() {
    this.processManagerService.force()
    this.adminService.editReminderEmail().subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.emailEdit = response.data

        this.initializeForm()
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        }
        else {
          this.handleApiException(error)
        }
      }
    })
  }

  private loadDistributorEmail() {
    this.processManagerService.force()
    this.distributorService.editReminderEmail().subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.emailEdit = response.data

        this.initializeForm()
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        }
        else {
          this.handleApiException(error)
        }
      }
    })
  }

  private returnLocalizations(content: ILocalizable): Localization[] {
    return Object.entries(content.Localizations!).map(o => o[1]) as Localization[]
  }

  private saveAdministrationEmail() {
    this.localizationInputElements.forEach(c => c.update())

    if (this.emailEditForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.emailEditForm)
      return
    }

    this.updateReminderEmail()

    this.processManagerService.force()
    this.adminService.modifyReminderEmail(this.emailEdit).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('COMPONENTS.REMINDER_EMAIL_DETAIL.SAVED') })
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        }
        else {
          this.handleApiException(error)
        }
      }
    })
  }

  private saveDistributorEmail() {
    this.localizationInputElements.forEach(c => c.update())

    if (this.emailEditForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.emailEditForm)
      return
    }

    this.updateReminderEmail()

    this.processManagerService.force()
    this.distributorService.modifyReminderEmail(this.emailEdit).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('COMPONENTS.REMINDER_EMAIL_DETAIL.SAVED') })
      },
      error: (error: HttpErrorResponse) => {
        if (401 == error.status) {
          this.handleException(error.error.message)
        }
        else {
          this.handleApiException(error)
        }
      }
    })
  }

  private updateReminderEmail() {
    this.emailEdit.Message!.SenderEmail = this.emailEditForm.get('senderEmail')?.value
    this.emailEdit.Message!.SenderName = this.emailEditForm.get('senderName')?.value

    let localizations: Localization[] = Object.entries(this.emailEdit.Message!.Localizations!).map(o => o[1]) as Localization[]
    for (var i: number = 0; i < localizations.length; i++) {
      localizations[i].Subject = this.localizationService.returnLocalizationValue(localizations[i].LanguageId!, 'subject', this.emailEditForm)
    }

    let blocks: EmailBlock[] = Object.entries(this.emailEdit.Blocks!).map(o => o[1]) as EmailBlock[]
    let assessmentUrlTextBlock: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_ASSESSMENT_URL_TEXT)!
    let assessmentVisibility0Block: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_ASSESSMENT_VISIBILITY_0)!
    let assessmentVisibility1Block: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_ASSESSMENT_VISIBILITY_1)!
    let reminderIntroBlock: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_REMINDER_INTRO)!
    let reminderOutroBlock: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_REMINDER_OUTRO)!
    let salutationBlock: EmailBlock = blocks.find(b => b.Name == ReminderEmailDetailComponent.BLOCK_SALUTATION)!

    this.updateLocalizedContent(assessmentUrlTextBlock, 'assessmentUrlText')
    this.updateLocalizedContent(assessmentVisibility0Block, 'assessmentVisibility0')
    this.updateLocalizedContent(assessmentVisibility1Block, 'assessmentVisibility1')
    this.updateLocalizedContent(reminderIntroBlock, 'reminderIntro')
    this.updateLocalizedContent(reminderOutroBlock, 'reminderOutro')
    this.updateLocalizedContent(salutationBlock, 'salutation')
  }

  private updateLocalizedContent(emailBlock: EmailBlock, key: string) {
    let localizations: Localization[] = this.returnLocalizations(emailBlock)
    for (var i: number = 0; i < localizations.length; i++) {
      localizations[i].Content = this.localizationService.returnLocalizationValue(localizations[i].LanguageId!, key, this.emailEditForm)
    }
  }

  //#endregion
}
