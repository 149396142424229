import {Pipe, PipeTransform} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Localization} from "../core/api";
import {AuthService} from "../service/auth.service";

enum LanguageEnum {
  ENGLISH = 'EN',
  FRENCH = 'FR',
  DEFAULT_LANGUAGE = 'EN'
}

export interface ILocalizable {
  Localizations?: Localization[]
    | Set<Localization>
    | Array<Localization>
}

@Pipe({
  name: 'appTranslate'
})
export class AppTranslatePipe implements PipeTransform {

// properties
  get defaultLanguageCode(): string {
    return this.translateService.currentLang.toLocaleUpperCase();
  }

  // constructors
  constructor(
    private translateService: TranslateService,
  ) {
  }

  // business logic

  transform(input: ILocalizable|undefined, field: string = 'Name'): string {
    let output: string = ''
    if (!input || !input.Localizations) {
      return output
    }
    for (let localization of input.Localizations) {
      if (
        localization.hasOwnProperty(field)
        //&& (!output || localization.Language?.Code === this.defaultLanguageCode)
        && (!output || localization.LanguageCode === this.defaultLanguageCode)
      ) {
        output = Object.entries(localization).reduce((prev: string, curr: Array<string>) => (field === curr[0]) ? curr[1] : prev, '')
      }
    }

    return output
  }

}
