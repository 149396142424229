<div [formGroup]="formGroup" class="localization-control editor">
  <div [formGroupName]="controlName">
    <div>
      <label>{{displayName}}</label>
      <ul class="language-toggle" *ngIf="displayLanguageToggle">
        <li *ngFor="let item of orderedLanguages">
          <button pButton type="button" (click)="clickToggleLanguage(item.ID)" [disabled]="isCurrentLanguage(item) || isDisabled()" tabindex="-1" [ngClass]="returnCssClasses(item)" label="{{item.Code}}"></button>
        </li>
      </ul>
    </div>
    <div>
      <ckeditor #editorElement [editor]="customEditor" formControlName="value" [config]="configuration" (ready)="onEditorReady($event)"></ckeditor>
    </div>
    <app-validation-error [fieldName]="fieldName" [formGroup]="formGroup" validatorName="required">{{requiredErrorMessage}}</app-validation-error>
    <app-validation-error [fieldName]="fieldName" [formGroup]="formGroup" validatorName="maxlength">{{maxLengthErrorMessage}}</app-validation-error>
  </div>
</div>
