<div>
  <div class="dashboard-navigation-wrapper">
    <div class="wrapper">
      <div class="menu-toggle" [ngClass]="{'tab-menu-active': this.menuActive}">
        <button class="mobile-nav-toggle" (click)="toggleTabMenu()">
          {{'LAYOUT.NAV.MENU'|translate}}
          <span>X</span>
        </button>
      </div>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" (click)="toggleTabMenu()" routerLink="dashboard" routerLinkActive="activebutton">{{'LAYOUT.NAV.DASHBOARD'|translate}}</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle d-none d-md-block" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" routerLinkActive="activebutton" [attr.data-back-label]="'LAYOUT.NAV.BACK' | translate">
            {{'DISTRIBUTOR.DASHBOARD.MENU_MANAGE_ASSESSMENT_MANAGEMENT'|translate}}
          </a>
          <ul class="dropdown-menu">
            <li class="dropdown-item"><a class="nav-link" (click)="toggleTabMenu()" routerLink="content/invitation" routerLinkActive="activebutton">{{'DISTRIBUTOR.DASHBOARD.MENU_MANAGE_INVITATION_EMAIL'|translate}}</a></li>
            <li class="dropdown-item"><a class="nav-link"  (click)="toggleTabMenu()" routerLink="content/reminder" routerLinkActive="activebutton">{{'DISTRIBUTOR.DASHBOARD.MENU_MANAGE_REMINDER_EMAIL'|translate}}</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/logout']">{{'LAYOUT.NAV.LOG_OUT' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
