/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Score } from './score';
import { SurveyDto } from './surveyDto';


/**
 * Class ScoringMatrixDto
 */
export interface ScoringMatrix { 
    /**
     * *_/
     */
    PositiveScores: Array<Score>;
    /**
     * *_/
     */
    NegativeScores: Array<Score>;
    Survey: SurveyDto;
}

