import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { BaseComponent } from "../../components/base/base.component";
import { AdminService, JsonResponse, LicenseSearch, UserStatusDto } from "../../core/api";
import { IIDNamePair } from "../../interfaces/IIDNamePair";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  // styleUrls: ['./licenses.component.css']
})
export class LicensesComponent extends BaseComponent implements OnInit {
  //#region variables

  private _uLicenseSearch!: LicenseSearch
  public currentPage: number = 1
  public filterFormGroup!: FormGroup
  private licenseesPerPage: number = 20
  public totalItems: number = 0
  public userStatuses!: IIDNamePair[]

  //#endregion

  //#region properties

  get uLicenseSearch(): LicenseSearch {
    return this._uLicenseSearch;
  }

  //#endregion

  //#region constructor

  constructor(
    processManagerService: ProcessManagerService,
    private formBuilder: FormBuilder,
    private adminService: AdminService
  ) {
    super(processManagerService)
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['users', 'userStatuses'])

    this._uLicenseSearch = {
      AvailableOnly: true,
      perPage: this.licenseesPerPage,
      page: 1,
      totalItems: 0,
      items: undefined
    }

    this.initializeForm()
    this.loadLicenses();
    this.loadUserStatuses();
  }

  public onGoTo(page: number): void {
    this.submitSearchForm(page)
  }

  //#endregion

  //#region business logic

  private initializeForm() {
    this.filterFormGroup = this.formBuilder.group({
      UserStatus: [this._uLicenseSearch.UserStatus],
      SearchTerm: [this._uLicenseSearch.SearchTerm],
      AvailableOnly: [this._uLicenseSearch.AvailableOnly],
    })

    this.filterFormGroup.controls['UserStatus'].setValue(1)
  }

  public loadLicenses() {
    const criteria: LicenseSearch = {
      AvailableOnly: this.filterFormGroup.controls['AvailableOnly'].value,
      UserStatus: this.filterFormGroup.controls['UserStatus'].value == -1 ? null : this.filterFormGroup.controls['UserStatus'].value,
      SearchTerm: this.filterFormGroup.controls['SearchTerm'].value,
      perPage: this.licenseesPerPage,
      page: this.currentPage
    }

    this.adminService.searchLicenseUsers(criteria).subscribe({
      complete: () => this.processManagerService.notify('users'),
      next: (response: JsonResponse) => {
        this._uLicenseSearch = response.data
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private loadUserStatuses() {
    this.adminService.listUserStatus().subscribe({
      complete: () => this.processManagerService.notify('userStatuses'),
      next: (response: JsonResponse) => {
        this.processManagerService.notify('userStatuses')

        this.userStatuses = Object.keys(response.data).map(key => {
          /** @todo replace UserStatusDto with generic Lookup */
          let entity: UserStatusDto = response.data[key]
          return {ID: entity.ID ?? 0, Name: entity.Name};
        });
        this.userStatuses.unshift({ID: -1, Name: 'All'})
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  public submitSearchForm(page: number = 1) {
    this.currentPage = page

    this.processManagerService.resetProcess('users')
    this.loadLicenses()
  }

  //#endregion
}
