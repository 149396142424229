<div [formGroup]="formGroup" class="localization-control input-text">
  <div [formGroupName]="controlName">
    <div>
      <label [for]="uniqueControlName">{{displayName}}</label>
      <ul class="language-toggle" *ngIf="displayLanguageToggle">
        <li *ngFor="let item of orderedLanguages">
          <button pButton type="button" (click)="clickToggleLanguage(item.ID)" [disabled]="isCurrentLanguage(item) || isDisabled()" tabindex="-1" [ngClass]="returnCssClasses(item)" label="{{item.Code}}"></button>
        </li>
      </ul>
    </div>
    <div>
      <textarea [id]="uniqueControlName" pInputTextarea formControlName="value" [rows]="rows" #inputElement (blur)="blurUpdateValue()"></textarea>
    </div>
    <app-validation-error [fieldName]="fieldName" [formGroup]="formGroup" validatorName="required">{{requiredErrorMessage}}</app-validation-error>
    <app-validation-error [fieldName]="fieldName" [formGroup]="formGroup" validatorName="maxlength">{{maxLengthErrorMessage}}</app-validation-error>
  </div>
</div>
