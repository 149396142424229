/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurchaseStats } from './purchaseStats';
import { ViewDistributorLicenseSummary } from './viewDistributorLicenseSummary';
import { ContentBlock } from './contentBlock';


/**
 * Class DashboardDistributorDto
 */
export interface DashboardDistributorDto { 
    WelcomeScreenText?: ContentBlock;
    PurchaseStatsALL?: PurchaseStats;
    /**
     * Collection of 
     */
    SurveyLicenseSummary?: Array<ViewDistributorLicenseSummary>;
}

