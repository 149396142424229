import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { TranslatePipe } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { BaseComponent } from "../../components/base/base.component";
import { AdminService, ComplimentaryLicenseCreate, JsonResponse, LookupService, SurveyDto } from "../../core/api";
import { AppTranslatePipe } from "../../pipes/app-translate.pipe";
import { FormValidationService } from "../../service/form-validation.service";
import { ProcessManagerService } from "../../service/process-manager.service";

@Component({
  selector: 'app-complimentary-license-detail',
  templateUrl: './complimentary-license-detail.component.html'
})
export class ComplimentaryLicenseDetailComponent extends BaseComponent implements OnInit {
  //#region variables

  private complimentaryLicenseCreate!: ComplimentaryLicenseCreate
  public complimentaryLicenseForm!: FormGroup
  public luSurveys!: any[]

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    private appTranslatePipe: AppTranslatePipe,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private lookupService: LookupService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickCancel() {
    this.router.navigate(['/admin', 'licenses', 'complimentary'])
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['complimentaryLicense', 'surveys'])
    this.processManagerService.force()

    this.createNewUser()

    this.loadSurveys()
  }

  public submit() {
    this.submitComplimentaryLicense()
  }

  //#endregion

  //#region business logic

  private createNewUser() {
    this.complimentaryLicenseCreate = {
      Email: '',
      FirstName: null!,
      SurveyID: null!
    }

    this.processManagerService.notify('complimentaryLicense')
    this.initializeForm()
  }

  private initializeForm() {
    this.complimentaryLicenseForm = this.formBuilder.group({
      email: [this.complimentaryLicenseCreate.Email, [Validators.required, Validators.email]],
      firstName: [this.complimentaryLicenseCreate.FirstName, [Validators.required]],
      surveyID: [this.complimentaryLicenseCreate.SurveyID, [Validators.required]],
    })
  }

  private loadSurveys() {
    this.lookupService.listSurveys().subscribe({
      complete: () => this.processManagerService.notify('surveys'),
      next: (response: JsonResponse) => {
        this.luSurveys = Object.keys(response.data).map(key => {
          let entity: SurveyDto = response.data[key]
          return { ID: entity.ID ?? 0, Name: this.appTranslatePipe.transform(entity, 'Name') };
        });

        setTimeout(() => {
          this.complimentaryLicenseForm.markAsPristine();
          this.complimentaryLicenseForm.markAsUntouched();
          this.complimentaryLicenseForm.updateValueAndValidity();
        })
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    })
  }

  private populateUser() {
    this.complimentaryLicenseCreate.Email = this.complimentaryLicenseForm.get('email')?.value
    this.complimentaryLicenseCreate.FirstName = this.complimentaryLicenseForm.get('firstName')?.value
    this.complimentaryLicenseCreate.SurveyID = this.complimentaryLicenseForm.get('surveyID')?.value
  }

  private submitComplimentaryLicense() {
    if (this.complimentaryLicenseForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.complimentaryLicenseForm)
      return
    }

    this.populateUser()

    this.processManagerService.force()
    this.adminService.issueComplimentaryLicense(this.complimentaryLicenseCreate).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.messageService.add({
          life: 10000, severity: 'info', detail: this.translate.transform('ADMIN.COMPLIMENTARY_LICENSE_DETAIL.COMPLIMENTARY_LICENSE_CREATED')
        })

        this.complimentaryLicenseCreate = response.data

        this.router.navigate(['/admin', 'licenses', 'complimentary'])
      },
      error: (error: HttpErrorResponse) => {
        this.processManagerService.unforce()
        this.handleApiException(error)
      }
    })
  }

  //#endregion
}
