import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BaseComponent } from '../../components/base/base.component';
import { AdminService, JsonResponse, LicenseSearch, ModifyLicenseCount, UserLicenseSummary } from '../../core/api';
import { SurveyEnum } from '../../enums/survey.enum';
import { FormValidationService } from '../../service/form-validation.service';
import { ProcessManagerService } from '../../service/process-manager.service';

@Component({
  selector: 'app-license-adjust-count',
  templateUrl: './license-adjust-count.component.html',
})
export class LicenseAdjustCountComponent extends BaseComponent implements OnInit {
  //#region variables

  public issuedCount: number = null!
  private userID: number = null!
  public userLicenseForm: FormGroup = null!
  public userLicenseSummary: UserLicenseSummary = null!

  //#endregion

  //#region constructor

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private messageService: MessageService,
    processManagerService: ProcessManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslatePipe
  ) {
    super(processManagerService)
  }

  //#endregion

  //#region event handlers

  public clickCancel() {
    this.router.navigate(['/admin', 'licenses'])
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.processManagerService.addProcesses(['license'])

    this.route.params.subscribe((params: Params) => {
      this.userID = parseInt(params['userID'])
      this.loadLicenseUserDetail()
    })
  }

  public submit() {
    this.submitLicenseUser()
  }

  //#endregion

  //#region business logic

  private initializeForm() {
    let availableCount: number = this.userLicenseSummary.Available!
    this.issuedCount = this.userLicenseSummary.Issued!

    this.userLicenseForm = this.formBuilder.group({
      purchasedCount: [availableCount, [Validators.required, Validators.min(this.issuedCount)]],
    })
  }

  private loadLicenseUserDetail() {
    const criteria: LicenseSearch = {
      AvailableOnly: false,
      UserID: this.userID
    }

    this.adminService.searchLicenseUsers(criteria).subscribe({
      complete: () => this.processManagerService.notify('license'),
      next: (response: JsonResponse) => {
        this.userLicenseSummary = response.data.items[0]

        this.initializeForm()
      },
      error: (error: HttpErrorResponse) => {
        this.handleApiException(error)
      }
    });
  }

  private submitLicenseUser() {
    if (this.userLicenseForm.invalid) {
      this.handleException(this.translate.transform('COMMON.UI.FORM_ERROR'))
      this.formValidationService.update(this.userLicenseForm)
      return
    }

    //@todo - corresponds with the ID of the 100 question survey, but why is this requireD?
    let modifyLicenseCount: ModifyLicenseCount = {
      SurveyID: SurveyEnum.QUESTIONS_100,
      Total: this.userLicenseForm.get('purchasedCount')?.value,
      UserID: this.userID
    }

    this.processManagerService.force()
    this.adminService.modifyLicenseCount(modifyLicenseCount).subscribe({
      complete: () => this.processManagerService.unforce(),
      next: (response: JsonResponse) => {
        this.messageService.add({ life: 10000, severity: 'info', detail: this.translate.transform('ADMIN.LICENSE_DETAIL.LICENSE_COUNT_MODIFIED') })

        this.router.navigate(['/admin', 'licenses'])
      },
      error: (error: HttpErrorResponse) => {
        this.processManagerService.unforce()
        this.handleApiException(error)
      }
    })
  }

  //#endregion

}
