<div [formGroup]="formGroup" class="localization-control input-text">
  <div [formGroupName]="controlName">
    <div>
      <label [for]="uniqueControlName">{{displayName}}</label>
      <ul class="language-toggle" *ngIf="displayLanguageToggle">
        <li *ngFor="let item of orderedLanguages">
          <button pButton type="button" (click)="clickToggleLanguage(item.ID)" [disabled]="isCurrentLanguage(item) || isDisabled()" tabindex="-1" [ngClass]="returnCssClasses(item)" label="{{item.Code}}"></button>
        </li>
      </ul>
    </div>
    <div>
      <input type="text" [id]="uniqueControlName" pInputText formControlName="value" #inputElement [maxlength]="maxLength" (blur)="blurUpdateValue()" />
    </div>
    <app-validation-error [fieldName]="fieldName" [formGroup]="formGroup" validatorName="required">{{requiredErrorMessage}}</app-validation-error>
  </div>
</div>
