/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserMinimalDto } from './userMinimalDto';
import { DimensionAnswer } from './dimensionAnswer';
import { Theme } from './theme';


/**
 * Class QuestionAnswerDto
 */
export interface QuestionAnswer { 
    /**
     * The Read-Only ID
     */
    ID: number;
    Theme: Theme;
    UpdatedBy?: UserMinimalDto | null;
    UpdatedDate?: string | null;
    DimensionAnswer: DimensionAnswer;
}

