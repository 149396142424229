import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AuthService} from "../service/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  /**
   * Intercepts outbound requests from the app to the API and modifies
   * the http headers to include the JSON-Web-Token (JWT) with each
   * request for authenticated users
   *
   * @param {HttpRequest} req
   * @param {HttpHandler} next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    if (null !== authToken && undefined !== authToken) {
      req = req.clone({
        setHeaders: {
          Authorization: "Bearer " + authToken,
        },
        withCredentials: true
      });
    }
    console.log("Req: " + req.urlWithParams)
    return next.handle(req);
  }
}
