import { Component } from '@angular/core'
import { LanguagesService } from '../../../service/languages.service'
import { LocalizationInputBaseComponent } from '../localization-input-base/localization-input-base.component'

@Component({
  providers: [{ provide: LocalizationInputBaseComponent, useExisting: LocalizationInputTextComponent }],
  selector: 'app-localization-input-text',
  templateUrl: './localization-input-text.component.html'
})
export class LocalizationInputTextComponent extends LocalizationInputBaseComponent {
  // constructors

  constructor(protected override languagesService: LanguagesService) {
    super(languagesService)
  }
}
