<section class="dark-theme search-filters">
  <form class="user-details-form" *ngIf="complimentaryLicenseForm" (submit)="submit()"
    [formGroup]="complimentaryLicenseForm" autocomplete="off" novalidate>
    <fieldset>
      <legend>{{'ADMIN.COMPLIMENTARY_LICENSE_DETAIL.LEGEND'|translate}}</legend>
      <div class="grid">
        <div class="col-12 md:col-4 form-field">
          <label for="surveyID">{{'ADMIN.COMPLIMENTARY_LICENSE_DETAIL.SURVEY'|translate}}</label>
          <p-dropdown id="surveyID" [options]="luSurveys" optionLabel="Name" optionValue="ID" formControlName="surveyID"
            appendTo="body" placeholder="{{'ADMIN.COMPLIMENTARY_LICENSE_DETAIL.SURVEY'|translate}}">
          </p-dropdown>
          <app-validation-error fieldName="surveyID" [formGroup]="complimentaryLicenseForm" validatorName="required">
            {{'ADMIN.COMPLIMENTARY_LICENSE_DETAIL.ERROR_SURVEY'|translate}}</app-validation-error>
        </div>

        <div class="col-12 md:col-4 form-field">
          <label for="email">{{'ADMIN.COMPLIMENTARY_LICENSE_DETAIL.EMAIL'|translate}}</label>
          <input type="email" pInputText formControlName="email" id="email" />
          <app-validation-error fieldName="email" [formGroup]="complimentaryLicenseForm" validatorName="required">
            {{'ADMIN.COMPLIMENTARY_LICENSE_DETAIL.ERROR_EMAIL'|translate}}</app-validation-error>
          <app-validation-error fieldName="email" [formGroup]="complimentaryLicenseForm" validatorName="email">
            {{'ADMIN.COMPLIMENTARY_LICENSE_DETAIL.ERROR_EMAIL_FORMAT'|translate}}</app-validation-error>
        </div>

        <div class="col-12 md:col-4 form-field">
          <label for="firstName">{{'ADMIN.COMPLIMENTARY_LICENSE_DETAIL.FIRST_NAME'|translate}}</label>
          <input pInputText formControlName="firstName" id="firstName" />
          <app-validation-error fieldName="firstName" [formGroup]="complimentaryLicenseForm" validatorName="required">
            {{'ADMIN.COMPLIMENTARY_LICENSE_DETAIL.ERROR_FIRST_NAME'|translate}}</app-validation-error>
        </div>
      </div>
      <div class="buttons">
        <p-button type="submit" label="{{'COMMON.UI.SAVE'|translate}}"></p-button>
        <p-button type="button" label="{{'COMMON.UI.CANCEL'|translate}}" (click)="clickCancel()"></p-button>
      </div>
    </fieldset>
  </form>
</section>
