import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})

export class UserAuthGuard implements CanActivate {
  // constructor

  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(r: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.isUserLoggedIn) {
      this.router.navigate(['/login']);

      return false;
    }

    if (!r.data['role']) {
      this.router.navigate(['/login']);

      return false;
    }

    if (r.data['role'] !== this.auth.user.UserRole?.Name) {
      this.router.navigate(['/login']);

      return false;
    }

    return true;
  }
}
