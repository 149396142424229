/**
 * Conflictability API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Class UserLicenseSummaryDto
 */
export interface UserLicenseSummary { 
    UserID?: number;
    Email?: string;
    FirstName?: string;
    LastName?: string | null;
    UserStatus?: string;
    UserStatusID?: number;
    Purchased?: number;
    Complimentary?: number;
    Removed?: number;
    Issued?: number;
    Available?: number;
}

