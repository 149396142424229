import { Injectable } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms'

@Injectable()
export class FormValidationService {
  // business logic

  public update(formGroup: FormGroup) {
    this.updateFormValidation(formGroup)
  }

  private updateFormValidation(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key)
      if (control instanceof FormControl) {
        if (control.invalid) {
          control.markAsDirty()
        }
      }
      else if (control instanceof FormGroup) {
        this.updateFormValidation(control)
      }
      else if (control instanceof FormArray) {
        let controls: AbstractControl[] = (control as FormArray).controls
        for (var i: number = 0; i < controls.length; i++) {
          this.updateFormValidation(controls[i] as FormGroup)
        }
        if (control.invalid) {
          control.markAsDirty()
        }
      }
    })
  }
}
